/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useMemo } from 'react';
import EventResults from '../EventResults';

const RaceResults = props => {
	const { localData, cars, onFieldUpdate } = props;

	const candidates = useMemo(() => {
		if (localData?.carCount && cars?.length) {
			const stack = [];
			for (let i = 1; i <= localData.carCount; i += 1) {
				const myCar = cars.find(C => C._id === localData[`slot_${i}_car`]);
				if (myCar) {
					stack.push(myCar);
				}
			}
			return stack;
		}
		return null;
	}, [cars, localData]);

	return (
		<EventResults
			eventData={localData}
			slotCount={localData?.carCount}
			candidates={candidates}
			onFieldUpdate={onFieldUpdate}
			placeFieldnameGenerator={i => `slot_${i}_place`}
			placeMeasureFieldnameGenerator={i => `slot_${i}_time`}
			candidateLabel='car'
			placeMeasureLabel='time'
			lockHandler={eventData => {
				let lockable = 0;
				if (eventData?.carCount) {
					for (let i = 1; i <= eventData.carCount; i += 1) {
						if (eventData?.[`slot_${i}_car`] && eventData?.[`slot_${i}_driver`]) {
							lockable += 1;
						}
					}
				}
				return lockable;
			}}
			notLockableHandler={(lockable, eventData) => {
				if (
					!lockable ||
					!eventData?.carCount ||
					lockable !== parseInt(eventData.carCount)
				) {
					return <p>Add cars and drivers to every slot to complete the setup.</p>;
				}
				return null;
			}}
		/>
	);
};

export default RaceResults;
