import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	BRANDS_ADD_ERROR,
	BRANDS_LOADED,
	BRANDS_LOAD_ERROR,
	BRANDS_REQUEST_END,
	BRANDS_REQUEST_START,
	BRANDS_UPDATE_ERROR,
	BRANDS_ADD_LOCAL_RECORD,
	BRANDS_UPDATE_LOCAL_RECORD,
	BRANDS_DROP_LOCAL_RECORD,
	BRAND_DELETED,
	BRAND_DELETE_ERROR,
	BRAND_DELETE_RESULT_CLEAR,
	BRAND_SAVED,
	BRAND_SAVE_ERROR,
	BRAND_SAVE_RESULT_CLEAR,
	SET_SELECTED_BRAND_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';
import { PROFESSIONAL_ENTITY_TYPES } from '../../constants';

/**
 * Brand Actions
 */

export const getBrands = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().brands;
		if (state.isRequesting) {
			// console.log('getBrands: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getBrands(ids, options);
			};
			dispatch(queueDeferredAction('brands', deferred));
			return;
		}
		dispatch(brandsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/list?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.BRAND
			);
		} catch (err) {
			error = err;
		}

		dispatch(brandsRequestEnd());

		if (error) {
			return dispatch(brandsLoadError(error));
		}

		dispatch(brandsLoaded({ brands: response?.data }));
	};
};

export const loadBrand = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadBrand, no id!');
			return dispatch(brandsLoadError({ message: 'no id found to load brand with' }));
		}
		console.log('loadBrand loadBrand loadBrand loadBrand ');
		console.log(id);
		const state = getState().brands;
		if (state.isRequesting) {
			// console.log('getBrands: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadBrand(id);
			};
			dispatch(queueDeferredAction('brands', deferred));
			return;
		}
		dispatch(brandsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/get?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.BRAND + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(brandsRequestEnd());

		if (error) {
			return dispatch(brandsLoadError(error));
		}

		console.log('loadBrand loadBrand loadBrand loadBrand ');
		console.log(response);

		dispatch(brandsLoaded({ brands: [response?.data] }));
	};
};

export const setSelectedBrandId = id => ({
	type: SET_SELECTED_BRAND_ID,
	payload: { id }
});

export const addBrand = data => {
	return async (dispatch, getState) => {
		const state = getState().brands;
		if (state.isRequesting) {
			const deferred = () => {
				addBrand(data);
			};
			dispatch(queueDeferredAction('brands', deferred));
			return;
		}
		dispatch(brandsRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.BRAND;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities', { data });
		} catch (err) {
			error = err;
		}

		console.log('addBrand, response');
		console.log(response);

		dispatch(brandsRequestEnd());

		if (error) {
			dispatch(brandSaveError(responses.BRAND_SAVE_ERROR));
			return dispatch(brandsAddError(error));
		}

		dispatch(brandSaved(responses.BRAND_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addBrandLocalRecord(data));
		}
	};
};

export const updateBrand = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateBrand, no id!');
			return dispatch(
				brandsUpdateError({
					message: 'no id found to update brand with'
				})
			);
		}
		const state = getState().brands;
		if (state.isRequesting) {
			const deferred = () => {
				updateBrand(id, data);
			};
			dispatch(queueDeferredAction('brands', deferred));
			return;
		}
		dispatch(brandsRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.BRAND;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(brandsRequestEnd());

		console.log('updateBrand ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(brandSaveError(responses.BRAND_SAVE_ERROR));
			return dispatch(brandsUpdateError(error));
		}

		dispatch(brandSaved(responses.BRAND_SAVED));
		dispatch(updateBrandLocalRecord(id, data));
	};
};

export const deleteBrand = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteBrand, no id!');
			return false; // dispatch(brandsUpdateError({ message: 'no id found to update brand with' }));
		}
		const state = getState().brands;
		if (state.isRequesting) {
			const deferred = () => {
				deleteBrand(id);
			};
			dispatch(queueDeferredAction('brands', deferred));
			return;
		}
		dispatch(brandsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete(
				'/api/delete?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.BRAND + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(brandsRequestEnd());

		console.log('deleteBrand ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(brandDeleteError(responses.BRAND_DELETE_ERROR));
			// return dispatch(brandsUpdateError(error));
		}

		dispatch(brandDeleted(responses.BRAND_DELETED));
		dispatch(dropBrandLocalRecord(id));
	};
};

export const addBrandLocalRecord = data => ({
	type: BRANDS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateBrandLocalRecord = (id, data) => ({
	type: BRANDS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const brandsLoaded = ({ brands }) => ({
	type: BRANDS_LOADED,
	payload: { brands }
});

export const brandSaved = responseMsg => ({
	type: BRAND_SAVED,
	payload: responseMsg
});

export const brandSaveError = responseMsg => ({
	type: BRAND_SAVE_ERROR,
	payload: responseMsg
});

export const dropBrandLocalRecord = id => ({
	type: BRANDS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const brandDeleted = responseMsg => ({
	type: BRAND_DELETED,
	payload: responseMsg
});

export const brandDeleteError = responseMsg => ({
	type: BRAND_DELETE_ERROR,
	payload: responseMsg
});

export const brandClearSaveResult = () => ({
	type: BRAND_SAVE_RESULT_CLEAR
});

export const brandClearDeleteResult = () => ({
	type: BRAND_DELETE_RESULT_CLEAR
});

export const brandsLoadError = error => ({
	type: BRANDS_LOAD_ERROR,
	payload: { error }
});

export const brandsAddError = error => ({
	type: BRANDS_ADD_ERROR,
	payload: { error }
});

export const brandsUpdateError = error => ({
	type: BRANDS_UPDATE_ERROR,
	payload: { error }
});

export const brandsRequestEnd = () => ({
	type: BRANDS_REQUEST_END,
	payload: false
});

export const brandsRequestStart = () => ({
	type: BRANDS_REQUEST_START,
	payload: true
});
