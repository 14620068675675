/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useMemo, useState } from 'react';

const UiStatusMessageDisplay = props => {
	const { message, consumed } = props;
	const { theme } = useThemeUI();
	// const [isConsumed, setIsConsumed] = useState(false);
	// testing:
	// message.message = 'show a message';
	// message.status = false;

	const uiStatusMessageDisplay = useMemo(() => {
		if (message.status !== null) {
			const flag = message.status === true ? 'Success' : 'Error';
			// if (!isConsumed) {
			consumed();
			// setIsConsumed(true);
			// }
			return (
				<p
					sx={{
						color: message.status ? theme.colors.uiSuccess : theme.colors.uiFail
					}}>
					{flag}: {message.message}
				</p>
			);
		}
		return null;
	}, [message, consumed, theme.colors.uiFail, theme.colors.uiSuccess]);

	return uiStatusMessageDisplay;
};

export default UiStatusMessageDisplay;
