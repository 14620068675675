import {
	USER_ACTION_REQUEST_END,
	USER_ACTION_REQUEST_START,
	USER_ACTION_ERROR,
	USER_LOADED,
	USER_LOGOUT,
	USER_REFRESH,
	USER_LOAD_ERROR,
	USER_REQUEST_END,
	USER_REQUEST_START
} from '../actions/actionTypes';

const initialState = {
	isAuthorized: false,
	isRequesting: false,
	isActionRequesting: false,
	isRefreshed: false,
	actionError: null,
	loadError: null,
	user: null
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { user, error = null } = action?.payload || {};
	switch (action.type) {
		case USER_LOADED:
			if (user && user._id) {
				return {
					..._state,
					isAuthorized: true,
					user
				};
			}
			return {
				...state,
				isAuthorized: false,
				isRefreshed: false,
				user: null
			};

		case USER_REFRESH:
			if (user && user._id) {
				return {
					..._state,
					isAuthorized: true,
					isRefreshed: true,
					user
				};
			}
			return {
				...state,
				isAuthorized: false,
				isRefreshed: false,
				user: null
			};

		case USER_LOGOUT:
			return {
				...state,
				isAuthorized: false,
				isRefreshed: false,
				user: null
			};

		case USER_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case USER_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case USER_LOAD_ERROR:
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(USER_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case USER_ACTION_REQUEST_START:
			return {
				...state,
				isActionRequesting: true
			};

		case USER_ACTION_REQUEST_END:
			return {
				...state,
				isActionRequesting: false
			};

		case USER_ACTION_ERROR:
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(USER_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				actionError: error
			};
		default:
			return state;
	}
}
