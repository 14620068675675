import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	ADMINS_ADD_ERROR,
	ADMINS_LOADED,
	ADMINS_LOAD_ERROR,
	ADMINS_REQUEST_END,
	ADMINS_REQUEST_START,
	ADMINS_UPDATE_ERROR,
	ADMINS_ADD_LOCAL_RECORD,
	ADMINS_UPDATE_LOCAL_RECORD,
	ADMINS_DROP_LOCAL_RECORD,
	ADMIN_DELETED,
	ADMIN_DELETE_ERROR,
	ADMIN_DELETE_RESULT_CLEAR,
	ADMIN_SAVED,
	ADMIN_SAVE_ERROR,
	ADMIN_SAVE_RESULT_CLEAR,
	SET_SELECTED_ADMIN_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Admin Actions
 */

export const getAdmins = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().admins;
		if (state.isRequesting) {
			// console.log('getAdmins: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getAdmins(ids, options);
			};
			dispatch(queueDeferredAction('admins', deferred));
			return;
		}
		dispatch(adminsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=admins');
		} catch (err) {
			error = err;
		}

		dispatch(adminsRequestEnd());

		if (error) {
			return dispatch(adminsLoadError(error));
		}

		dispatch(adminsLoaded({ admins: response?.data }));
	};
};

export const loadAdmin = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadAdmin, no id!');
			return dispatch(
				adminsLoadError({
					message: 'no id found to load admin with'
				})
			);
		}
		console.log('loadAdmin loadAdmin loadAdmin loadAdmin ');
		console.log(id);
		const state = getState().admins;
		if (state.isRequesting) {
			// console.log('getAdmins: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadAdmin(id);
			};
			dispatch(queueDeferredAction('admins', deferred));
			return;
		}
		dispatch(adminsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=admins&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(adminsRequestEnd());

		if (error) {
			return dispatch(adminsLoadError(error));
		}

		console.log('loadAdmin loadAdmin loadAdmin loadAdmin ');
		console.log(response);

		dispatch(adminsLoaded({ admins: [response?.data] }));
	};
};

export const setSelectedAdminId = id => ({
	type: SET_SELECTED_ADMIN_ID,
	payload: { id }
});

export const addAdmin = data => {
	return async (dispatch, getState) => {
		const state = getState().admins;
		if (state.isRequesting) {
			const deferred = () => {
				addAdmin(data);
			};
			dispatch(queueDeferredAction('admins', deferred));
			return;
		}
		dispatch(adminsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=admins', { data });
		} catch (err) {
			error = err;
		}

		console.log('addAdmin, response');
		console.log(response);

		dispatch(adminsRequestEnd());

		if (error) {
			dispatch(adminSaveError(responses.ADMIN_SAVE_ERROR));
			return dispatch(adminsAddError(error));
		}

		dispatch(adminSaved(responses.ADMIN_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addAdminLocalRecord(data));
		}
	};
};

export const updateAdmin = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateAdmin, no id!');
			return dispatch(
				adminsUpdateError({
					message: 'no id found to update admin with'
				})
			);
		}
		const state = getState().admins;
		if (state.isRequesting) {
			const deferred = () => {
				updateAdmin(id, data);
			};
			dispatch(queueDeferredAction('admins', deferred));
			return;
		}
		dispatch(adminsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=admins&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(adminsRequestEnd());

		console.log('updateAdmin ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(adminSaveError(responses.ADMIN_SAVE_ERROR));
			return dispatch(adminsUpdateError(error));
		}

		dispatch(adminSaved(responses.ADMIN_SAVED));
		dispatch(updateAdminLocalRecord(id, data));
	};
};

export const deleteAdmin = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteAdmin, no id!');
			return false; // dispatch(adminsUpdateError({ message: 'no id found to update admin with' }));
		}
		const state = getState().admins;
		if (state.isRequesting) {
			const deferred = () => {
				deleteAdmin(id);
			};
			dispatch(queueDeferredAction('admins', deferred));
			return;
		}
		dispatch(adminsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=admins&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(adminsRequestEnd());

		console.log('deleteAdmin ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(adminDeleteError(responses.ADMIN_DELETE_ERROR));
			// return dispatch(adminsUpdateError(error));
		}

		dispatch(adminDeleted(responses.ADMIN_DELETED));
		dispatch(dropAdminLocalRecord(id));
	};
};

export const addAdminLocalRecord = data => ({
	type: ADMINS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateAdminLocalRecord = (id, data) => ({
	type: ADMINS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const adminsLoaded = ({ admins }) => ({
	type: ADMINS_LOADED,
	payload: { admins }
});

export const adminSaved = responseMsg => ({
	type: ADMIN_SAVED,
	payload: responseMsg
});

export const adminSaveError = responseMsg => ({
	type: ADMIN_SAVE_ERROR,
	payload: responseMsg
});

export const dropAdminLocalRecord = id => ({
	type: ADMINS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const adminDeleted = responseMsg => ({
	type: ADMIN_DELETED,
	payload: responseMsg
});

export const adminDeleteError = responseMsg => ({
	type: ADMIN_DELETE_ERROR,
	payload: responseMsg
});

export const adminClearSaveResult = () => ({
	type: ADMIN_SAVE_RESULT_CLEAR
});

export const adminClearDeleteResult = () => ({
	type: ADMIN_DELETE_RESULT_CLEAR
});

export const adminsLoadError = error => ({
	type: ADMINS_LOAD_ERROR,
	payload: { error }
});

export const adminsAddError = error => ({
	type: ADMINS_ADD_ERROR,
	payload: { error }
});

export const adminsUpdateError = error => ({
	type: ADMINS_UPDATE_ERROR,
	payload: { error }
});

export const adminsRequestEnd = () => ({
	type: ADMINS_REQUEST_END,
	payload: false
});

export const adminsRequestStart = () => ({
	type: ADMINS_REQUEST_START,
	payload: true
});
