import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner, Textarea } from 'theme-ui';
import useSponsors from '../../../hooks/useSponsors';
import AddressFields from '../../segments/AddressFields';
import EditorSaveButton from '../../segments/EditorSaveButton';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { Devnote, EditorContainer, EditorButtonContainer } from '../../ThemeUiStyled';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const SponsorsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveSponsor,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useSponsors();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveSponsor(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Sponsor:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Sponsor</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='website'>Website</Label>
							<Input
								name='website'
								mb={3}
								value={localData?.website || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='contact'>Contact Person</Label>
							<Input
								name='contact'
								mb={3}
								value={localData?.contact || ''}
								onChange={onFieldUpdate}
							/>
							<Devnote>this will eventually link to an internal user</Devnote>
							<AddressFields onFieldUpdate={onFieldUpdate} data={localData} />
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Sponsor'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='videoEmbed'>Video Embed</Label>
							<Textarea
								rows={4}
								name='videoEmbed'
								mb={3}
								value={localData?.videoEmbed || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='description'>Description</Label>
							<Textarea
								rows={4}
								name='description'
								mb={3}
								value={localData?.description || ''}
								onChange={onFieldUpdate}
							/>
							<h3>Sponsor Logo:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default SponsorsAdd;
