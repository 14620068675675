import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	CHARITIES_ADD_ERROR,
	CHARITIES_LOADED,
	CHARITIES_LOAD_ERROR,
	CHARITIES_REQUEST_END,
	CHARITIES_REQUEST_START,
	CHARITIES_UPDATE_ERROR,
	CHARITIES_ADD_LOCAL_RECORD,
	CHARITIES_UPDATE_LOCAL_RECORD,
	CHARITIES_DROP_LOCAL_RECORD,
	CHARITY_DELETED,
	CHARITY_DELETE_ERROR,
	CHARITY_DELETE_RESULT_CLEAR,
	CHARITY_SAVED,
	CHARITY_SAVE_ERROR,
	CHARITY_SAVE_RESULT_CLEAR,
	SET_SELECTED_CHARITY_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';
import { PROFESSIONAL_ENTITY_TYPES } from '../../constants';

/**
 * Charity Actions
 */

export const getCharities = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().charities;
		if (state.isRequesting) {
			// console.log('getCharities: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getCharities(ids, options);
			};
			dispatch(queueDeferredAction('charities', deferred));
			return;
		}
		dispatch(charitiesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/list?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.CHARITY
			);
		} catch (err) {
			error = err;
		}

		dispatch(charitiesRequestEnd());

		if (error) {
			return dispatch(charitiesLoadError(error));
		}

		dispatch(charitiesLoaded({ charities: response?.data }));
	};
};

export const loadCharity = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadCharity, no id!');
			return dispatch(
				charitiesLoadError({
					message: 'no id found to load charity with'
				})
			);
		}
		console.log('loadCharity loadCharity loadCharity loadCharity ');
		console.log(id);
		const state = getState().charities;
		if (state.isRequesting) {
			// console.log('getCharities: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadCharity(id);
			};
			dispatch(queueDeferredAction('charities', deferred));
			return;
		}
		dispatch(charitiesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/get?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.CHARITY + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(charitiesRequestEnd());

		if (error) {
			return dispatch(charitiesLoadError(error));
		}

		console.log('loadCharity loadCharity loadCharity loadCharity ');
		console.log(response);

		dispatch(charitiesLoaded({ charities: [response?.data] }));
	};
};

export const setSelectedCharityId = id => ({
	type: SET_SELECTED_CHARITY_ID,
	payload: { id }
});

export const addCharity = data => {
	return async (dispatch, getState) => {
		const state = getState().charities;
		if (state.isRequesting) {
			const deferred = () => {
				addCharity(data);
			};
			dispatch(queueDeferredAction('charities', deferred));
			return;
		}
		dispatch(charitiesRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.CHARITY;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities', { data });
		} catch (err) {
			error = err;
		}

		console.log('addCharity, response');
		console.log(response);

		dispatch(charitiesRequestEnd());

		if (error) {
			dispatch(charitySaveError(responses.CHARITY_SAVE_ERROR));
			return dispatch(charitiesAddError(error));
		}

		dispatch(charitySaved(responses.CHARITY_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addCharityLocalRecord(response.data?.doc || data));
		}
	};
};

export const updateCharity = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateCharity, no id!');
			return dispatch(
				charitiesUpdateError({
					message: 'no id found to update charity with'
				})
			);
		}
		const state = getState().charities;
		if (state.isRequesting) {
			const deferred = () => {
				updateCharity(id, data);
			};
			dispatch(queueDeferredAction('charities', deferred));
			return;
		}
		dispatch(charitiesRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.CHARITY;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(charitiesRequestEnd());

		console.log('updateCharity ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(charitySaveError(responses.CHARITY_SAVE_ERROR));
			return dispatch(charitiesUpdateError(error));
		}

		dispatch(charitySaved(responses.CHARITY_SAVED));
		dispatch(updateCharityLocalRecord(id, response.data?.doc || data));
	};
};

export const deleteCharity = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteCharity, no id!');
			return false; // dispatch(charitiesUpdateError({ message: 'no id found to update charity with' }));
		}
		const state = getState().charities;
		if (state.isRequesting) {
			const deferred = () => {
				deleteCharity(id);
			};
			dispatch(queueDeferredAction('charities', deferred));
			return;
		}
		dispatch(charitiesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete(
				'/api/delete?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.CHARITY + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(charitiesRequestEnd());

		console.log('deleteCharity ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(charityDeleteError(responses.CHARITY_DELETE_ERROR));
			// return dispatch(charitiesUpdateError(error));
		}

		dispatch(charityDeleted(responses.CHARITY_DELETED));
		dispatch(dropCharityLocalRecord(id));
	};
};

export const addCharityLocalRecord = data => ({
	type: CHARITIES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateCharityLocalRecord = (id, data) => ({
	type: CHARITIES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const charitiesLoaded = ({ charities }) => ({
	type: CHARITIES_LOADED,
	payload: { charities }
});

export const charitySaved = responseMsg => ({
	type: CHARITY_SAVED,
	payload: responseMsg
});

export const charitySaveError = responseMsg => ({
	type: CHARITY_SAVE_ERROR,
	payload: responseMsg
});

export const dropCharityLocalRecord = id => ({
	type: CHARITIES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const charityDeleted = responseMsg => ({
	type: CHARITY_DELETED,
	payload: responseMsg
});

export const charityDeleteError = responseMsg => ({
	type: CHARITY_DELETE_ERROR,
	payload: responseMsg
});

export const charityClearSaveResult = () => ({
	type: CHARITY_SAVE_RESULT_CLEAR
});

export const charityClearDeleteResult = () => ({
	type: CHARITY_DELETE_RESULT_CLEAR
});

export const charitiesLoadError = error => ({
	type: CHARITIES_LOAD_ERROR,
	payload: { error }
});

export const charitiesAddError = error => ({
	type: CHARITIES_ADD_ERROR,
	payload: { error }
});

export const charitiesUpdateError = error => ({
	type: CHARITIES_UPDATE_ERROR,
	payload: { error }
});

export const charitiesRequestEnd = () => ({
	type: CHARITIES_REQUEST_END,
	payload: false
});

export const charitiesRequestStart = () => ({
	type: CHARITIES_REQUEST_START,
	payload: true
});
