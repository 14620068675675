import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getEmailTemplates as _getEmailTemplates,
	setSelectedEmailTemplateId,
	loadEmailTemplate,
	addEmailTemplate,
	updateEmailTemplate,
	deleteEmailTemplate as _deleteEmailTemplate,
	emailTemplateSaveError,
	emailTemplateClearSaveResult,
	emailTemplateClearDeleteResult,
	sendCustomEmailMessage as _sendCustomEmailMessage,
	sendStaticTemplateEmailMessage as _sendStaticTemplateEmailMessage
} from '../redux/actions/comms';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useComms = () => {
	const users = useSelector(state => state.comms?.list, shallowEqual);
	const currentEmailTemplate = useSelector(state => state.comms?.currentEmailTemplate);
	const selectedEmailTemplateId = useSelector(state => state.comms?.selectedEmailTemplateId);
	const error = useSelector(state => state.comms?.loadError);
	const saveResult = useSelector(state => state.comms?.saveResult);
	const deleteResult = useSelector(state => state.comms?.deleteResult);
	const isProcessing = useSelector(state => state.comms?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);

	const emailMessageSendResponse = useSelector(state => state.comms?.emailMessageSendResponse);
	const emailMessageSendResponseError = useSelector(
		state => state.comms?.emailMessageSendResponseError
	);

	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.user && data.car;
	};

	const getEmailTemplates = useCallback(
		(ids = [], options = {}) => {
			dispatch(_getEmailTemplates(ids, options));
		},
		[dispatch]
	);

	const getEmailTemplate = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedEmailTemplateId(id));
				dispatch(loadEmailTemplate({ id }));
			}
		},
		[dispatch]
	);

	const selectEmailTemplate = useCallback(
		id => {
			dispatch(setSelectedEmailTemplateId(id));
		},
		[dispatch]
	);

	const deleteEmailTemplate = useCallback(
		id => {
			dispatch(_deleteEmailTemplate(id));
		},
		[dispatch]
	);

	const saveEmailTemplate = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateEmailTemplate(id, data));
				}
				return dispatch(addEmailTemplate(data));
			}
			dispatch(emailTemplateSaveError('invalid data'));
		},
		[dispatch]
	);

	/***
	 *
	 * error fade bug out there....
	 *
	 * THIS IS the correct way to do the timeout functions
	 * (and uiStatusDeleteMessageConsumed below)
	 *
	 * come back later and refactor the other hooks
	 */
	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(emailTemplateClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(emailTemplateClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const sendCustomEmailMessage = (user, message, subject) => {
		if (user && message) {
			dispatch(_sendCustomEmailMessage(user, message, subject));
		}
	};

	const sendStaticTemplateEmailMessage = (user, staticTemplate, templateData) => {
		if (user && staticTemplate) {
			dispatch(_sendStaticTemplateEmailMessage(user, staticTemplate, templateData));
		}
	};

	return {
		getEmailTemplates,
		getEmailTemplate,
		saveEmailTemplate,
		selectEmailTemplate,
		deleteEmailTemplate,
		isProcessing,
		currentEmailTemplate,
		selectedEmailTemplateId,
		users,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error,

		sendCustomEmailMessage,
		sendStaticTemplateEmailMessage,
		emailMessageSendResponse,
		emailMessageSendResponseError
	};
};

export default useComms;
