/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, Spinner } from 'theme-ui';
import { greenActionButtonSx } from './PickWinners';

const pickDelay = 2000;
const pickRandom = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const PickRandomBall = props => {
	const { place, pickedSlots, maxSlots, setPick, size } = props;

	const [isPicking, setIsPicking] = useState(false);

	const trimmedPickList = useMemo(() => {
		if (pickedSlots) {
			const stack = [];
			for (let i = 1; i <= maxSlots; i += 1) {
				if (pickedSlots.indexOf(i) === -1) {
					stack.push(i);
				}
			}
			window.KF_TRIMMED = stack;
			return stack;
		}
		return null;
	}, [maxSlots, pickedSlots]);

	const spinner = useMemo(() => {
		if (isPicking) {
			return <Spinner size='100%' />;
		}
		return null;
	}, [isPicking]);

	const pickButton = useMemo(() => {
		const startPick = e => {
			e.preventDefault();
			setIsPicking(true);
			setTimeout(() => {
				console.log('doing random pick, range 1-' + trimmedPickList.length);
				const randomPick = pickRandom(0, trimmedPickList.length - 1);
				console.log('PICKED: ' + randomPick);
				setIsPicking(false);
				setPick({ place, pick: trimmedPickList[randomPick] });
			}, pickDelay);
		};

		if (trimmedPickList && !isPicking) {
			return (
				<Button
					onClick={startPick}
					sx={{
						...greenActionButtonSx,
						fontSize: '16px',
						margin: `${size * 0.35}px auto 0`,
						display: 'block',
						cursor: 'pointer'
					}}>
					Pick {place}
				</Button>
			);
		}
		return null;
	}, [trimmedPickList, isPicking, setPick, place, size]);

	return (
		<Box>
			{spinner}
			{pickButton}
		</Box>
	);
};

export default PickRandomBall;
