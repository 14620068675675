import React from 'react';
// import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { Grid, Box } from 'grommet';
// import UserAuth from './UserAuth';
// import BasicProfile from './BasicProfile';
// import DonationHistory from './DonationHistory';
// import JackpotPicks from './JackpotPicks';
// import RaffleTickets from './RaffleTickets';
import './UserAccount.scss';

const AccountContainer = styled.div`
	margin: 0 auto;
	width: 85%;
	@media (max-width: 600px) {
		width: 95%;
	}
`;

const Home = props => {
	return (
		<AccountContainer>
			<h1>user account home</h1>
			{/*<Grid
                        columns={['flex', '30%']}
                        rows={['small', 'flex', 'flex']}
                        gap="medium"
                        responsive={true}
                        fill={true}
                        areas={[
                            { name: 'jackpot-picks', start: [0, 0], end: [0, 2] },
                            { name: 'basic-profile', start: [1, 0], end: [1, 0] },
                            { name: 'raffle-tickets', start: [1, 1], end: [1, 1] },
                            { name: 'donation-history', start: [1, 2], end: [1, 2] },
                        ]}
                    >
                        <Box gridArea="jackpot-picks">
                            <div className="account-section left">
                                <JackpotPicks />
                            </div>
                        </Box>
                        <Box gridArea="basic-profile">
                            <div className="account-section right">
                                <BasicProfile />
                            </div>
                        </Box>
                        <Box gridArea="donation-history">
                            <div className="account-section right">
                                <DonationHistory />
                            </div>

                        </Box>
                        <Box gridArea="raffle-tickets">
                            <div className="account-section right">
                                <RaffleTickets />
                            </div>

                        </Box>
                    </Grid>*/}
		</AccountContainer>
	);
};

export default Home;
