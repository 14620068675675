/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useRafflePrizes from '../../../hooks/useRafflePrizes';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const RafflePrizes = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const {
		getRafflePrizes,
		rafflePrizes,
		currentRafflePrize,
		selectedRafflePrizeId,
		selectRafflePrize,
		error
	} = useRafflePrizes();

	const [hasRequestedRafflePrizes, setHasRequestedRafflePrizes] = useState(false);
	const [myRafflePrizeId, setMyRafflePrizeId] = useState(selectedRafflePrizeId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedRafflePrizes) {
			setHasRequestedRafflePrizes(true);
			getRafflePrizes();
		}
	}, [method, hasRequestedRafflePrizes, getRafflePrizes]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentRafflePrize, method: ' + method);
		if (
			['view', 'edit'].indexOf(method) > -1 &&
			(!currentRafflePrize || currentRafflePrize._id !== _id)
		) {
			console.log('set it! ' + _id);
			selectRafflePrize(_id);
			setMyRafflePrizeId(_id);
		}
	}, [selectedRafflePrizeId, _id, currentRafflePrize, method, selectRafflePrize]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage RafflePrizes
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={rafflePrizes} error={error} />}
			{method === 'view' && <View data={currentRafflePrize} error={error} />}
			{method === 'edit' && <Edit data={currentRafflePrize} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default RafflePrizes;
