import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getRaces as _getRaces,
	setSelectedRaceId,
	loadRace,
	addRace,
	updateRace,
	deleteRace as _deleteRace,
	raceSaveError,
	raceClearSaveResult,
	raceClearDeleteResult
} from '../redux/actions/races';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useRaces = () => {
	const races = useSelector(state => state.races?.list, shallowEqual);
	const currentRace = useSelector(state => state.races?.currentRace);
	const selectedRaceId = useSelector(state => state.races?.selectedRaceId);
	const error = useSelector(state => state.races?.loadError);
	const saveResult = useSelector(state => state.races?.saveResult);
	const deleteResult = useSelector(state => state.races?.deleteResult);
	const isProcessing = useSelector(state => state.races?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);

	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.race && data.car;
	};

	const getRaces = useCallback(() => {
		const options = {
			sort: { raceDate: '1' }
		};
		dispatch(_getRaces([], options));
	}, [dispatch]);

	const getRace = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedRaceId(id));
				dispatch(loadRace({ id }));
			}
		},
		[dispatch]
	);

	const selectRace = useCallback(
		id => {
			dispatch(setSelectedRaceId(id));
		},
		[dispatch]
	);

	const deleteRace = useCallback(
		id => {
			dispatch(_deleteRace(id));
		},
		[dispatch]
	);

	const saveRace = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateRace(id, data));
				}
				return dispatch(addRace(data));
			}
			dispatch(raceSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(raceClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(raceClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getRaces,
		getRace,
		saveRace,
		selectRace,
		deleteRace,
		isProcessing,
		currentRace,
		selectedRaceId,
		races,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useRaces;
