/** @jsx jsx */
import { jsx, Label } from 'theme-ui';
import React, { useMemo } from 'react';
import EventSetup from '../EventSetup';
import SelectOne from 'components/segments/SelectOne';

const RaceSetup = props => {
	const { localData, cars, drivers, onFieldUpdate } = props;

	const onRaceSetupUpdate = e => {
		const { name, value } = e?.target || {};
		let extra;
		if (name.indexOf('car') && value) {
			const slotMatch = name.match(/(\d+)/);
			const slotNumber = slotMatch.length && slotMatch[0];
			const selectedDriver = slotNumber && localData[`slot_${slotNumber}_driver`];
			if (!selectedDriver) {
				const myCar = cars.find(C => C._id === value);
				if (myCar?.driver) {
					extra = {
						[`slot_${slotNumber}_driver`]: myCar.driver
					};
				}
			}
		}
		onFieldUpdate(e, extra);
	};

	const setupData = useMemo(() => {
		if (cars?.length && drivers?.length) {
			const stack = [];
			stack.push({
				data: cars,
				label: 'car',
				fieldnameGenerator: i => `slot_${i}_car`
			});
			stack.push({
				data: drivers,
				label: 'driver',
				fieldnameGenerator: i => `slot_${i}_driver`
			});
			return stack;
		}
		return null;
	}, [cars, drivers]);

	const rosterLockedRow = (stack, i, locked, eventData) => {
		if (locked) {
			const myCar = cars.find(C => C._id === eventData[`slot_${i}_car`]) || {
				name: 'unknown car'
			};
			const myDriver = drivers.find(C => C._id === eventData[`slot_${i}_driver`]) || {
				name: 'unknown driver'
			};
			stack.push(
				<p key={i} sx={{ m: 0 }}>
					<strong>Slot {i}:</strong> {myCar.name}, {myDriver.name}
				</p>
			);
		}
	};

	return (
		<>
			{!localData?.lockRoster && (
				<Label htmlFor='carCount'>
					How many cars in the race?
					<SelectOne
						range={[1, 36]}
						value={localData?.carCount || 0}
						name='carCount'
						onFieldUpdate={onFieldUpdate}
						_sx={{
							width: '100px',
							display: 'inline',
							marginLeft: '20px',
							height: '40px'
						}}
					/>
				</Label>
			)}
			<EventSetup
				eventData={localData}
				setupData={setupData}
				slotCount={localData?.carCount}
				setupHeaderLabel='Cars + Drivers:'
				onSetupUpdate={onRaceSetupUpdate}
				onFieldUpdate={onFieldUpdate}
				rosterLockedRow={rosterLockedRow}
			/>
		</>
	);
};

export default RaceSetup;
