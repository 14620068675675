import _ from 'lodash';

import {
	DRIVERS_LOADED,
	DRIVERS_LOAD_ERROR,
	DRIVERS_REQUEST_END,
	DRIVERS_REQUEST_START,
	DRIVERS_ADD_LOCAL_RECORD,
	DRIVERS_UPDATE_LOCAL_RECORD,
	DRIVER_DELETED,
	DRIVER_DELETE_ERROR,
	DRIVER_DELETE_RESULT_CLEAR,
	DRIVERS_DROP_LOCAL_RECORD,
	DRIVER_SAVED,
	DRIVER_SAVE_ERROR,
	DRIVER_SAVE_RESULT_CLEAR,
	SET_SELECTED_DRIVER_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedDriverId: null,
	currentDriver: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

const drivers = (state = initialState, action) => {
	const _state = { ...state };
	const { drivers, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case DRIVERS_LOADED:
			// console.warn(DRIVERS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { drivers } = action.payload;
			const currentDrivers = [..._state.list];
			if (drivers?.length) {
				drivers.forEach(T => {
					if (!currentDrivers.find(CT => CT._id === T._id)) {
						currentDrivers.push(T);
					}
				});
			}
			// console.warn('currentDrivers');
			// console.log(currentDrivers);
			let currentDriver;
			if (state.selectedDriverId) {
				const selectedDriver = currentDrivers.find(D => D._id === state.selectedDriverId);
				if (selectedDriver) {
					currentDriver = selectedDriver;
				}
			}
			return {
				...state,
				loadError: null,
				currentDriver,
				list: currentDrivers
			};

		case DRIVERS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(DRIVERS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_DRIVER_ID:
			return {
				...state,
				selectedDriverId: action.payload?.id,
				currentDriver:
					state.currentDriver?._id === action.payload?.id ? state.currentDriver : null
			};

		case DRIVERS_UPDATE_LOCAL_RECORD:
			const oldDriver = state.list.find(D => D._id === id) || {};
			const newDriver = _.merge(oldDriver, data);
			return {
				...state,
				currentDriver: state.currentDriver?._id === id ? newDriver : state.currentDriver,
				list: state.list.map(D => {
					if (D._id === id) {
						return newDriver;
					}
					return D;
				})
			};

		case DRIVERS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case DRIVERS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentDriver: state.currentDriver?._id === id ? null : state.currentDriver,
				list: state.list.filter(D => D._id !== id)
			};

		case DRIVERS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case DRIVERS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case DRIVER_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case DRIVER_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case DRIVER_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case DRIVER_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case DRIVER_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case DRIVER_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
};

export default drivers;