/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { Box, Button, Flex, Input, Label, Spinner, useThemeUI } from 'theme-ui';
import useDonationMatrixes from '../../../hooks/useDonationMatrixes';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import DatePicker from '../../segments/DatePicker';
import SelectOne from '../../segments/SelectOne';
import { RemoveItemButton } from '../../segments/SelectOneList';
import { MATRIX_TYPES } from '../../../constants';
import { objectSort } from '../../../utils';
import { getOnFieldUpdate } from '../../../utils/datasource';

const DonationMatrixesEdit = props => {
	const { data, error } = props;
	const {
		selectedDonationMatrixId,
		getDonationMatrix,
		saveDonationMatrix,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useDonationMatrixes();

	const [localData, setLocalData] = useState(data);
	const [currentMatrix, setCurrentMatrix] = useState({});

	useEffect(() => {
		if (!data && selectedDonationMatrixId) {
			console.log('getDonationMatrix!!!!' + selectedDonationMatrixId);
			getDonationMatrix(selectedDonationMatrixId);
		} else if (data?._id !== selectedDonationMatrixId) {
			console.log('getDonationMatrix!!!!' + selectedDonationMatrixId);
			getDonationMatrix(selectedDonationMatrixId);
		}
	}, [data, selectedDonationMatrixId, getDonationMatrix]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const addMatrix = e => {
		e.preventDefault();
		if (currentMatrix) {
			if (
				currentMatrix.amount &&
				(currentMatrix.jackpotCredits || currentMatrix.voteTokens) &&
				(currentMatrix.raffleTickets || [MATRIX_TYPES.JACKPOT, MATRIX_TYPES.VOTE_CONTEST].indexOf(localData.type) > -1)
			) {
				const matrixes = localData?.matrixes?.slice(0) || [];
				currentMatrix.amount = parseInt(currentMatrix.amount);
				currentMatrix.raffleTickets = parseInt(currentMatrix.raffleTickets);
				currentMatrix.jackpotCredits = parseInt(currentMatrix.jackpotCredits);
				currentMatrix.voteTokens = parseInt(currentMatrix.voteTokens);
				matrixes.push(currentMatrix);
				const myLocalData = {
					...localData,
					matrixes
				};
				setLocalData(myLocalData);
				setCurrentMatrix({});
			}
		}
	};

	const handleForm = e => {
		e.preventDefault();
		if (
			localData &&
			localData.name &&
			localData.startDate /* && localData.endDate
            && localData.startDate > localData.endDate*/ &&
			localData.matrixes?.length
		) {
			const saveData = {
				...localData,
				matrixes: localData.matrixes.sort(objectSort('amount'))
			};
			saveDonationMatrix(selectedDonationMatrixId, saveData);
		}
	};

	const removeMatrixes = useMemo(() => {
		const matrixes = localData?.matrixes;
		if (!matrixes?.length) {
			return <h4>nothing in the matrix, add a row</h4>;
		}

		const removeItem = (e, item) => {
			e.preventDefault();
			const newMatrix = localData.matrixes.filter(
				M => parseInt(M.amount) !== parseInt(item.amount)
			);
			console.log('removeItem');
			console.log(newMatrix);
			onFieldUpdate({ target: { name: 'matrixes', value: newMatrix } });
		};
	
		return sortBy(matrixes, ['amount']).map((item, idx) => {
			let rowData;

			switch (localData.type) {
				case MATRIX_TYPES.JACKPOT:
					rowData = `purchase ${item.amount} Jackpot Credits, get ${item.jackpotCredits || 0} free`;
					break;
				case MATRIX_TYPES.VOTE_CONTEST:
					rowData = `purchase ${item.amount} Vote Credits, get ${item.voteTokens || 0} free`;
					break;
				case MATRIX_TYPES.DONATION:
				default:
					rowData = `$${item.amount}: ${item.jackpotCredits || 0} Jackpot Credits, ${item.voteTokens || 0} Vote Credits, ${item.raffleTickets || 0} Raffles`;
					break;
			}

			return (
				<Flex key={idx}>
					<Box p={2} sx={{ flex: '1 1 auto' }}>
						<div>{rowData}</div>
					</Box>
					<Box p={2}>
						<RemoveItemButton onClick={e => removeItem(e, item)}>x</RemoveItemButton>
					</Box>
				</Flex>
			);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localData?.matrixes, localData?.type, onFieldUpdate]);

	const matrixSetupInputs = useMemo(() => {
		if (!localData?.type) {
			return <p>Select a type to add to the matrix</p>;
		}

		let amtLabel;
		let creditLabel;
		let creditName;

		switch (localData.type) {
			case MATRIX_TYPES.JACKPOT:
				amtLabel = 'Jackpot Credits';
				creditLabel = 'Extra Credits with Purchase';
				creditName = 'jackpotCredits';
				break;
			case MATRIX_TYPES.VOTE_CONTEST:
				amtLabel = 'Vote Credits';
				creditLabel = 'Extra Credits with Purchase';
				creditName = 'voteTokens';
				break;
			case MATRIX_TYPES.DONATION:
			default:
				amtLabel = 'Min. Amount';
				creditLabel = 'Jackpot Credits';
				creditName = 'jackpotCredits';
				break;
		}

		const onFieldUpdateMatrixBuilder = e => {
			const myCurrentMatrix = {
				...currentMatrix,
				[e.target.name]: e.target.value
			};
			setCurrentMatrix(myCurrentMatrix);
		};
	
		return (
			<Flex>
				<Box mr={3}>
					<Label htmlFor='amount'>{amtLabel}</Label>
					<Input
						name='amount'
						mb={3}
						value={currentMatrix?.amount || ''}
						onChange={onFieldUpdateMatrixBuilder}
					/>
				</Box>
				<Box mr={3}>
					<Label htmlFor={creditName}>{creditLabel}</Label>
					<Input
						name={creditName}
						mb={3}
						value={currentMatrix?.[creditName] || ''}
						onChange={onFieldUpdateMatrixBuilder}
					/>
				</Box>
				{localData.type === MATRIX_TYPES.DONATION && (
					<Box mr={3}>
						<Label htmlFor='voteTokens'>Vote Credits</Label>
						<Input
							name='voteTokens'
							mb={3}
							value={currentMatrix?.voteTokens || ''}
							onChange={onFieldUpdateMatrixBuilder}
						/>
					</Box>
				)}
				{localData.type === MATRIX_TYPES.DONATION && (
					<Box>
						<Label htmlFor='raffleTickets'>Raffle Tickets</Label>
						<Input
							name='raffleTickets'
							mb={3}
							value={currentMatrix?.raffleTickets || ''}
							onChange={onFieldUpdateMatrixBuilder}
						/>
					</Box>
				)}
			</Flex>
		);
	}, [localData, currentMatrix]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading donationMatrix....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit DonationMatrix:</h2>
			<EditorContainer>
				<Box as='form' onSubmit={handleForm}>
					<Label htmlFor='name'>Name</Label>
					<Input
						name='name'
						mb={3}
						value={localData?.name || ''}
						onChange={onFieldUpdate}
					/>

					<Label htmlFor='startDate'>Start Date</Label>
					<DatePicker
						value={localData?.startDate}
						name='startDate'
						onFieldUpdate={onFieldUpdate}
					/>

					<Label htmlFor='type'>Matrix Type</Label>
					<SelectOne
						data={Object.keys(MATRIX_TYPES)}
						value={localData?.type}
						name='type'
						onFieldUpdate={onFieldUpdate}
					/>

					{/*
                    <Label htmlFor="endDate">End Date</Label>
                    <DatePicker
                        value={localData?.endDate}
                        name="endDate"
                        onFieldUpdate={onFieldUpdate}
                    />
                    */}

					<Label htmlFor='matrix'>Matrix:</Label>
					{matrixSetupInputs}
					{localData?.type && <Button onClick={addMatrix}>+ Add to matrix</Button>}
					{removeMatrixes}

					<EditorSaveButton isProcessing={isProcessing} label='Donation Matrix' />
					<UiStatusMessageDisplay
						message={uiStatusMessageSaveResult}
						consumed={uiStatusSaveMessageConsumed}
					/>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default DonationMatrixesEdit;
