/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, Input, Label, Spinner, Textarea } from 'theme-ui';
import usePrizeDraws from '../../../hooks/usePrizeDraws';
import useRafflePrizes from '../../../hooks/useRafflePrizes';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import SelectOne from '../../segments/SelectOne';
import EditorSaveButton from '../../segments/EditorSaveButton';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';

const PrizeDrawsEdit = props => {
	const { data, error } = props;
	const {
		selectedPrizeDrawId,
		getPrizeDraw,
		savePrizeDraw,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = usePrizeDraws();

	const {
		getRafflePrizes,
		rafflePrizes,
		error: rafflePrizeError,
		isProcessing: isProcessingRafflePrizes
	} = useRafflePrizes();

	const [localData, setLocalData] = useState(data);
	const [hasRequestedPrizes, setHasRequestedPrizes] = useState(false);

	useEffect(() => {
		if (!hasRequestedPrizes) {
			setHasRequestedPrizes(true);
			getRafflePrizes({ isGrandPrize: true });
		}
	}, [hasRequestedPrizes, getRafflePrizes, setHasRequestedPrizes]);

	useEffect(() => {
		if (!data && selectedPrizeDrawId) {
			console.log('getPrizeDraw!!!!' + selectedPrizeDrawId);
			getPrizeDraw(selectedPrizeDrawId);
		} else if (data?._id !== selectedPrizeDrawId) {
			console.log('getPrizeDraw!!!!' + selectedPrizeDrawId);
			getPrizeDraw(selectedPrizeDrawId);
		}
	}, [data, selectedPrizeDrawId, getPrizeDraw]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		savePrizeDraw(selectedPrizeDrawId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading prizeDraw....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Grand Prize Draw:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Label htmlFor='name'>PrizeDraw Name</Label>
					<Input
						name='name'
						mb={3}
						value={localData?.name || ''}
						onChange={onFieldUpdate}
					/>
					<Label htmlFor='rafflePrize'>Grand Prize to give away</Label>
					<SelectOne
						data={rafflePrizes}
						value={localData?.rafflePrize}
						name='rafflePrize'
						onFieldUpdate={onFieldUpdate}
						noDataMsg='No prizes found to choose from.'
					/>
					<Label htmlFor='description'>Description</Label>
					<Textarea
						rows={4}
						name='description'
						mb={3}
						value={localData?.description || ''}
						onChange={onFieldUpdate}
					/>
					<Label htmlFor='prizeDrawDate'>Prize Draw Date</Label>
					<DatePicker
						value={localData?.prizeDrawDate}
						name='prizeDrawDate'
						onFieldUpdate={onFieldUpdate}
					/>
					<EditorSaveButton isProcessing={isProcessing} label='Prize Draw' />
					<UiStatusMessageDisplay
						message={uiStatusMessageSaveResult}
						consumed={uiStatusSaveMessageConsumed}
					/>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default PrizeDrawsEdit;
