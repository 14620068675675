/** @jsx jsx */
import { jsx, Label, Box } from 'theme-ui';
import React, { useMemo } from 'react';
import EventSetup from '../EventSetup';
import SelectOne from 'components/segments/SelectOne';
import {voteContestCategoryTypes} from './VoteContestSetup';

const VoteContestCandidateCategoriesSetup = props => {
	const { localData, voteContests, onFieldUpdate } = props;

	const myContest = useMemo(() => {
		if (localData?.event && voteContests?.length) {
			return voteContests.find(VC => VC._id === localData.event);
		}
		return null;
	}, [localData, voteContests]);

	const allTypeCategories = useMemo(() => {
		if (myContest?.categoryCount) {
			const stack = [];
			for (let i = 1; i <= myContest?.categoryCount; i += 1) {
				const typeprop = `category_${i}_type`;
				const nameprop = `category_${i}_name`;
				if (myContest[nameprop] && myContest[typeprop] === voteContestCategoryTypes.ALL) {
					stack.push({ position: i, name: myContest[nameprop]});
				}
			}
			return stack;
		}
		return [];
	}, [myContest]);

	const selectTypeCategories = useMemo(() => {
		if (myContest?.categoryCount) {
			const stack = [];
			for (let i = 1; i <= myContest?.categoryCount; i += 1) {
				const typeprop = `category_${i}_type`;
				const nameprop = `category_${i}_name`;
				if (myContest[nameprop] && myContest[typeprop] === voteContestCategoryTypes.SELECTED) {
					stack.push({ position: i, name: myContest[nameprop]});
				}
			}
			return stack;
		}
		return [];
	}, [myContest]);

	// const onRaceSetupUpdate = e => {
	// 	const { name, value } = e?.target || {};
	// 	let extra;
	// 	if (name.indexOf('car') && value) {
	// 		const slotMatch = name.match(/(\d+)/);
	// 		const slotNumber = slotMatch.length && slotMatch[0];
	// 		const selectedDriver = slotNumber && localData[`slot_${slotNumber}_driver`];
	// 		if (!selectedDriver) {
	// 			const myCar = cars.find(C => C._id === value);
	// 			if (myCar?.driver) {
	// 				extra = {
	// 					[`slot_${slotNumber}_driver`]: myCar.driver
	// 				};
	// 			}
	// 		}
	// 	}
	// 	onFieldUpdate(e, extra);
	// };

	// const setupData = useMemo(() => {
	// 	if (cars?.length && drivers?.length) {
	// 		const stack = [];
	// 		stack.push({
	// 			data: cars,
	// 			name: 'car',
	// 			fieldnameGenerator: i => `slot_${i}_car`
	// 		});
	// 		stack.push({
	// 			data: drivers,
	// 			name: 'driver',
	// 			fieldnameGenerator: i => `slot_${i}_driver`
	// 		});
	// 		return stack;
	// 	}
	// 	return null;
	// }, [cars, drivers]);

	// const rosterLockedRow = (stack, i, locked, eventData) => {
	// 	if (locked) {
	// 		const myCar = cars.find(C => C._id === eventData[`slot_${i}_car`]) || {
	// 			name: 'unknown car'
	// 		};
	// 		const myDriver = drivers.find(C => C._id === eventData[`slot_${i}_driver`]) || {
	// 			name: 'unknown driver'
	// 		};
	// 		stack.push(
	// 			<p key={i} sx={{ m: 0 }}>
	// 				<strong>Slot {i}:</strong> {myCar.name}, {myDriver.name}
	// 			</p>
	// 		);
	// 	}
	// };

	return (
		<Box>
			<h3 sx={{m: 0}}>Select Eligible Categories:</h3>
			{selectTypeCategories.length ? (
				<>
				{selectTypeCategories.map((SC, idx) => {
					const iname = `selectEligibleCategory-${SC.position}`;
					return (
						<Label htmlFor={iname} key={idx}>
						{/*
						<Checkbox
							name="isGrandPrize"
							value={localData?.isGrandPrize}
							checked={localData?.isGrandPrize}
							onChange={() => alert('changed')}
						/>
						*/}
						<input
							type='checkbox'
							onChange={onFieldUpdate}
							name={iname}
							value={localData?.[iname] || false}
							checked={localData?.[iname] || false}
						/>
						{SC.name}
					</Label>
					);
				})}
				</>
			) : <p><em>No Custom Categories found for this event</em></p>}

			<h3 sx={{m: 0}}>Open Categories (All Eligible):</h3>
			{allTypeCategories.length ? (
				<>
				{allTypeCategories.map((AC, idx) => {
					return (
						<span key={idx}>{AC.name}<br /></span>
					);
				})}
				</>
			) : <p><em>No Open Categories found for this event</em></p>}
			</Box>
	);

	// return (
	// 	<>
	// 		{!localData?.lockRoster && (
	// 			<Label htmlFor='carCount'>
	// 				How many cars in the race?
	// 				<SelectOne
	// 					range={[1, 36]}
	// 					value={localData?.carCount || 0}
	// 					name='carCount'
	// 					onFieldUpdate={onFieldUpdate}
	// 					_sx={{
	// 						width: '100px',
	// 						display: 'inline',
	// 						marginLeft: '20px',
	// 						height: '40px'
	// 					}}
	// 				/>
	// 			</Label>
	// 		)}
	// 		<EventSetup
	// 			eventData={localData}
	// 			setupData={setupData}
	// 			slotCount={localData?.carCount}
	// 			setupHeaderLabel='Cars + Drivers:'
	// 			onSetupUpdate={onRaceSetupUpdate}
	// 			onFieldUpdate={onFieldUpdate}
	// 			rosterLockedRow={rosterLockedRow}
	// 		/>
	// 	</>
	// );
};

export default VoteContestCandidateCategoriesSetup;
