/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Text } from 'theme-ui'

import useEntries from '../../../hooks/useEntries';

const ActionButtons = props => {
	const { method } = props;
	const { theme } = useThemeUI();

	const {
		// entries,
		// currentEntry,
		selectedEntryId,
		error
	} = useEntries({ event: 'dress-a-like' });

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<div
			sx={{
				mb: '1.5em'
			}}>
			{method !== 'list' && (
				<Link sx={linkSx} to='/data/dress-a-like-entries'>
					Back to Entries List
				</Link>
			)}
			{/* {['add', 'edit', 'delete'].indexOf(method) === -1 && (
				<Link sx={linkSx} to='/data/dress-a-like-entries/add'>
					+ Add a Entry
				</Link>
			)} */}
			{method === 'view' && selectedEntryId && (
				<>
					{/* <Link
						sx={{
							...linkSx,
							color: theme.colors.text,
							fontWeight: 'bold'
						}}
						to={'/data/dress-a-like-entries/edit/' + selectedEntryId}>
						Edit this Entry
					</Link> */}
					<Link sx={linkSx} to={'/data/dress-a-like-entries/delete/' + selectedEntryId}>
						-- DELETE Entry --
					</Link>
				</>
			)}
		</div>
	);
};

export default ActionButtons;
