import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	JACKPOTRESULTS_ADD_ERROR,
	JACKPOTRESULTS_LOADED,
	JACKPOTRESULTS_LOAD_ERROR,
	JACKPOTRESULTS_REQUEST_END,
	JACKPOTRESULTS_REQUEST_START,
	JACKPOTRESULTS_UPDATE_ERROR,
	JACKPOTRESULTS_ADD_LOCAL_RECORD,
	JACKPOTRESULTS_UPDATE_LOCAL_RECORD,
	JACKPOTRESULTS_DROP_LOCAL_RECORD,
	JACKPOTRESULT_DELETED,
	JACKPOTRESULT_DELETE_ERROR,
	JACKPOTRESULT_DELETE_RESULT_CLEAR,
	JACKPOTRESULT_SAVED,
	JACKPOTRESULT_SAVE_ERROR,
	JACKPOTRESULT_SAVE_RESULT_CLEAR,
	SET_SELECTED_JACKPOTRESULT_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * JackpotResult Actions
 */

export const getJackpotResults = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().jackpotResults;
		if (state.isRequesting) {
			// console.log('getJackpotResults: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getJackpotResults(ids, options);
			};
			dispatch(queueDeferredAction('jackpotResults', deferred));
			return;
		}
		dispatch(jackpotResultsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=jackpot-results');
		} catch (err) {
			error = err;
		}

		dispatch(jackpotResultsRequestEnd());

		if (error) {
			return dispatch(jackpotResultsLoadError(error));
		}

		dispatch(jackpotResultsLoaded({ jackpotResults: response?.data }));
	};
};

export const loadJackpotResult = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadJackpotResult, no id!');
			return dispatch(
				jackpotResultsLoadError({ message: 'no id found to load jackpotResult with' })
			);
		}
		console.log('loadJackpotResult loadJackpotResult loadJackpotResult loadJackpotResult ');
		console.log(id);
		const state = getState().jackpotResults;
		if (state.isRequesting) {
			// console.log('getJackpotResults: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadJackpotResult(id);
			};
			dispatch(queueDeferredAction('jackpotResults', deferred));
			return;
		}
		dispatch(jackpotResultsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=jackpot-results&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(jackpotResultsRequestEnd());

		if (error) {
			return dispatch(jackpotResultsLoadError(error));
		}

		console.log('loadJackpotResult loadJackpotResult loadJackpotResult loadJackpotResult ');
		console.log(response);

		dispatch(jackpotResultsLoaded({ jackpotResults: [response?.data] }));
	};
};

export const setSelectedJackpotResultId = id => ({
	type: SET_SELECTED_JACKPOTRESULT_ID,
	payload: { id }
});

export const addJackpotResult = data => {
	return async (dispatch, getState) => {
		const state = getState().jackpotResults;
		if (state.isRequesting) {
			const deferred = () => {
				addJackpotResult(data);
			};
			dispatch(queueDeferredAction('jackpotResults', deferred));
			return;
		}
		dispatch(jackpotResultsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=jackpot-results', { data });
		} catch (err) {
			error = err;
		}

		console.log('addJackpotResult, response');
		console.log(response);

		dispatch(jackpotResultsRequestEnd());

		if (error) {
			dispatch(jackpotResultSaveError(responses.JACKPOTRESULT_SAVE_ERROR));
			return dispatch(jackpotResultsAddError(error));
		}

		dispatch(jackpotResultSaved(responses.JACKPOTRESULT_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addJackpotResultLocalRecord(data));
		}
	};
};

export const updateJackpotResult = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateJackpotResult, no id!');
			return dispatch(
				jackpotResultsUpdateError({
					message: 'no id found to update jackpotResult with'
				})
			);
		}
		const state = getState().jackpotResults;
		if (state.isRequesting) {
			const deferred = () => {
				updateJackpotResult(id, data);
			};
			dispatch(queueDeferredAction('jackpotResults', deferred));
			return;
		}
		dispatch(jackpotResultsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=jackpot-results&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(jackpotResultsRequestEnd());

		console.log('updateJackpotResult ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(jackpotResultSaveError(responses.JACKPOTRESULT_SAVE_ERROR));
			return dispatch(jackpotResultsUpdateError(error));
		}

		dispatch(jackpotResultSaved(responses.JACKPOTRESULT_SAVED));
		dispatch(updateJackpotResultLocalRecord(id, data));
	};
};

export const deleteJackpotResult = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteJackpotResult, no id!');
			return false; // dispatch(jackpotResultsUpdateError({ message: 'no id found to update jackpotResult with' }));
		}
		const state = getState().jackpotResults;
		if (state.isRequesting) {
			const deferred = () => {
				deleteJackpotResult(id);
			};
			dispatch(queueDeferredAction('jackpotResults', deferred));
			return;
		}
		dispatch(jackpotResultsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=jackpot-results&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(jackpotResultsRequestEnd());

		console.log('deleteJackpotResult ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(jackpotResultDeleteError(responses.JACKPOTRESULT_DELETE_ERROR));
			// return dispatch(jackpotResultsUpdateError(error));
		}

		dispatch(jackpotResultDeleted(responses.JACKPOTRESULT_DELETED));
		dispatch(dropJackpotResultLocalRecord(id));
	};
};

export const addJackpotResultLocalRecord = data => ({
	type: JACKPOTRESULTS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateJackpotResultLocalRecord = (id, data) => ({
	type: JACKPOTRESULTS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const jackpotResultsLoaded = ({ jackpotResults }) => ({
	type: JACKPOTRESULTS_LOADED,
	payload: { jackpotResults }
});

export const jackpotResultSaved = responseMsg => ({
	type: JACKPOTRESULT_SAVED,
	payload: responseMsg
});

export const jackpotResultSaveError = responseMsg => ({
	type: JACKPOTRESULT_SAVE_ERROR,
	payload: responseMsg
});

export const dropJackpotResultLocalRecord = id => ({
	type: JACKPOTRESULTS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const jackpotResultDeleted = responseMsg => ({
	type: JACKPOTRESULT_DELETED,
	payload: responseMsg
});

export const jackpotResultDeleteError = responseMsg => ({
	type: JACKPOTRESULT_DELETE_ERROR,
	payload: responseMsg
});

export const jackpotResultClearSaveResult = () => ({
	type: JACKPOTRESULT_SAVE_RESULT_CLEAR
});

export const jackpotResultClearDeleteResult = () => ({
	type: JACKPOTRESULT_DELETE_RESULT_CLEAR
});

export const jackpotResultsLoadError = error => ({
	type: JACKPOTRESULTS_LOAD_ERROR,
	payload: { error }
});

export const jackpotResultsAddError = error => ({
	type: JACKPOTRESULTS_ADD_ERROR,
	payload: { error }
});

export const jackpotResultsUpdateError = error => ({
	type: JACKPOTRESULTS_UPDATE_ERROR,
	payload: { error }
});

export const jackpotResultsRequestEnd = () => ({
	type: JACKPOTRESULTS_REQUEST_END,
	payload: false
});

export const jackpotResultsRequestStart = () => ({
	type: JACKPOTRESULTS_REQUEST_START,
	payload: true
});
