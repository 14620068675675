/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useMemo } from 'react';
import { Select } from 'theme-ui';

const SelectOne = props => {
	const {
		data,
		range,
		value,
		name,
		_sx,
		dataLabelProp,
		dataValueProp,
		onFieldUpdate,
		useEmptySelector,
		emptySelctorMsg,
		noDataMsg
	} = props;

	const options = useMemo(() => {
		if (data) {
			return data.map((S, idx) => {
				if (typeof S === 'string') {
					return (
						<option key={idx} value={S}>
							{S}
						</option>
					);
				}
				return (
					<option key={idx} value={S[dataValueProp]}>
						{S[dataLabelProp]}
					</option>
				);
			});
		}
		if (range) {
			const stack = [];
			for (let i = range[0]; i <= range[1]; i += 1) {
				stack.push(
					<option key={i} value={i}>
						{i}
					</option>
				);
			}
			return stack;
		}
		return null;
	}, [data, dataLabelProp, dataValueProp, range]);

	if ((!data || !data.length) && !(range?.length === 2)) {
		return <h4>{noDataMsg}</h4>;
	}

	return (
		<Select sx={_sx} onChange={onFieldUpdate} value={value} name={name}>
			{useEmptySelector && <option value=''>{emptySelctorMsg}</option>}
			{options}
		</Select>
	);
};

SelectOne.defaultProps = {
	useEmptySelector: true,
	dataLabelProp: 'name',
	dataValueProp: '_id',
	emptySelctorMsg: 'select one',
	noDataMsg: 'no options'
};

export default SelectOne;
