/** @jsx jsx */
import { jsx, Box, Button, Input } from 'theme-ui';
import { useMemo } from 'react';
import SelectOne from '../../segments/SelectOne';

const EventResults = props => {
	const {
		eventData,
		slotCount,
		candidates,
		onFieldUpdate,
		candidateLabel,
		placeMeasureLabel,
		candidateFilter,
		placeFieldnameGenerator,
		placeMeasureFieldnameGenerator,
		lockHandler,
		notLockableHandler
	} = props;

	// filter candidate list down to what is not selected yet:
	// can't do this yet, shortening the options will remove the display label
	// from the select list...
	// select won't find the value in the list
	// need a different way to represent selected and shortened list
	// const candidatesLeft = useMemo(() => {
	// 	const stack = [];
	// 	if (slotCount) {
	// 		for (let i = 1; i <= slotCount; i += 1) {
	// 			const myCandidate = candidates
	// 		}
	// 	}
	// 	return stack;
	// }, [candidateFilter, eventData, slotCount]);

	if (eventData?.lockRoster && slotCount) {
		const stack = [];
		for (let i = 1; i <= slotCount; i += 1) {
			stack.push(
				<tr key={i}>
					<td sx={{ lineHeight: '0.9em' }}>
						<span>{i}</span>
					</td>
					<td
						sx={{
							lineHeight: '0.9em',
							paddingLeft: '8px'
						}}>
						<SelectOne
							data={candidates}
							value={eventData[placeFieldnameGenerator(i)]}
							name={placeFieldnameGenerator(i)}
							emptySelctorMsg={`select ${candidateLabel}`}
							onFieldUpdate={onFieldUpdate}
							noDataMsg={`No ${candidateLabel}s found to choose from.`}
						/>
					</td>
					<td
						sx={{
							lineHeight: '0.9em',
							paddingLeft: '8px'
						}}>
						<Input
							placeholder='time 00:00'
							value={eventData[placeMeasureFieldnameGenerator(i)] || ''}
							name={placeMeasureFieldnameGenerator(i)}
							onChange={onFieldUpdate}
						/>
					</td>
				</tr>
			);
		}
		return (
			<Box>
				<table>
					<thead>
						<tr>
							<th>Place</th>
							<th>{candidateLabel}</th>
							<th>{placeMeasureLabel}</th>
						</tr>
					</thead>
					<tbody>{stack}</tbody>
					<colgroup>
						<col width='15%' />
						<col width='50%' />
						<col width='35%' />
					</colgroup>
				</table>
			</Box>
		);
	}
	const lockable = lockHandler(eventData);
	const notLockable = notLockableHandler(lockable, eventData);

	if (notLockable) {
		return notLockable;
	}

	return (
		<Box>
			<p>Lock the roster and then you can set the results</p>
			<Button onClick={() => onFieldUpdate({ target: { name: 'lockRoster', value: true } })}>
				Lock Roster
			</Button>
		</Box>
	);
};

export default EventResults;
