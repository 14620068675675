/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useEntries from '../../../hooks/useEntries';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const Entries = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const { getEntries, entries, currentEntry, selectedEntryId, selectEntry, error } = useEntries({
		event: 'dress-a-like'
	});

	const [hasRequestedEntries, setHasRequestedEntries] = useState(false);
	const [myEntryId, setMyEntryId] = useState(selectedEntryId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedEntries) {
			setHasRequestedEntries(true);
			getEntries();
		}
	}, [method, hasRequestedEntries, getEntries]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentEntry, method: ' + method);
		if (['view', 'edit'].indexOf(method) > -1 && (!currentEntry || currentEntry._id !== _id)) {
			console.log('set it! ' + _id);
			selectEntry(_id);
			setMyEntryId(_id);
		}
	}, [selectedEntryId, _id, currentEntry, method, selectEntry]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage Entries
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={entries} error={error} />}
			{method === 'view' && <View data={currentEntry} error={error} />}
			{method === 'edit' && <Edit data={currentEntry} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default Entries;
