import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Flex, Input, Label, Textarea } from 'theme-ui';
import useCandidates from '../../../hooks/useCandidates';
import useVoteContests from '../../../hooks/useVoteContests';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import DatePicker from '../../segments/DatePicker';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import ImageUploader from '../../segments/ImageUploader';
import VoteContestCandidateCategoriesSetup from '../../tools/EventBuilders/SetupBuilders/VoteContestCandidateCategoriesSetup';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import {RegencyCandidateGroups} from './Edit';

const CandidatesAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveCandidate,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useCandidates();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	const {
		getVoteContests,
		voteContests,
		error: voteContestError,
		isProcessing: isProcessingVoteContests
	} = useVoteContests();
	const [hasRequestedVoteContests, setHasRequestedVoteContests] = useState(false);

	useEffect(() => {
		if (!hasRequestedVoteContests) {
			setHasRequestedVoteContests(true);
			getVoteContests();
		}
	}, [hasRequestedVoteContests, getVoteContests, setHasRequestedVoteContests]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveCandidate(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Candidate:</h2>
			<p>dev note: will be able to link these to real user accounts eventually.</p>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Candidate Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='birthday'>Birthday</Label>
							<DatePicker
								value={localData?.birthday}
								name='birthday'
								onFieldUpdate={onFieldUpdate}
							/>

							<Label htmlFor='location'>General Location</Label>
							<Input
								name='location'
								mb={3}
								value={localData?.location || ''}
								onChange={onFieldUpdate}
							/>

							<Label htmlFor='title'>Title</Label>
							<Input
								name='title'
								mb={3}
								value={localData?.title || ''}
								onChange={onFieldUpdate}
							/>
							{/*
							<Label htmlFor='email'>Email</Label>
							<Input
								name='email'
								mb={3}
								value={localData?.email || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='city'>City</Label>
							<Input
								name='city'
								mb={3}
								value={localData?.city || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='state'>State / Province</Label>
							<Input
								name='state'
								mb={3}
								value={localData?.state || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='country'>Country</Label>
							<Input
								name='country'
								mb={3}
								value={localData?.country || ''}
								onChange={onFieldUpdate}
							/>
							*/}

							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Candidate'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Candidate Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
							<Label htmlFor='event'>What Contest?</Label>
							<SelectOne
								data={voteContests}
								value={localData?.event}
								name='event'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No contests found to choose from.'
							/>
							<h3>Select what categories this contestant is eligible for:</h3>
							{localData?.event && voteContests.length ? (
								<>
								<Label htmlFor='candidateGroup'>What Group / Segment?</Label>
								<SelectOne
									data={RegencyCandidateGroups}
									value={localData?.candidateGroup}
									name='candidateGroup'
									onFieldUpdate={onFieldUpdate}
									noDataMsg='No groups found to choose from.'
								/>

								<VoteContestCandidateCategoriesSetup
									voteContests={voteContests}
									localData={localData}
									onFieldUpdate={onFieldUpdate}
								/>
							</>
						) : (
								<p>Please select an event to see available categories</p>
							)}
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
			<pre>{JSON.stringify(localData, null, 4)}</pre>
		</div>
	);
};

export default CandidatesAdd;
