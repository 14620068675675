import _ from 'lodash';

import {
	COMMS_LOADED,
	COMMS_LOAD_ERROR,
	COMMS_REQUEST_END,
	COMMS_REQUEST_START,
	COMMS_ADD_LOCAL_RECORD,
	COMMS_UPDATE_LOCAL_RECORD,
	EMAIL_TEMPLATE_DELETED,
	EMAIL_TEMPLATE_DELETE_ERROR,
	EMAIL_TEMPLATE_DELETE_RESULT_CLEAR,
	COMMS_DROP_LOCAL_RECORD,
	EMAIL_TEMPLATE_SAVED,
	EMAIL_TEMPLATE_SAVE_ERROR,
	EMAIL_TEMPLATE_SAVE_RESULT_CLEAR,
	SET_SELECTED_EMAIL_TEMPLATE_ID,
	SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE,
	SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedEmailTemplateId: null,
	currentEmailTemplate: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	},
	emailMessageSendResponse: null,
	emailMessageSendResponseError: null
};

const commsReducer = function (state = initialState, action) {
	const _state = { ...state };
	const { templates, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case COMMS_LOADED:
			// console.warn(COMMS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { templates } = action.payload;
			const currentEmailTemplates = [..._state.list];
			if (templates?.length) {
				templates.forEach(T => {
					if (!currentEmailTemplates.find(CT => CT._id === T._id)) {
						currentEmailTemplates.push(T);
					}
				});
			}
			// console.warn('currentEmailTemplates');
			// console.log(currentEmailTemplates);
			let currentEmailTemplate;
			if (state.selectedEmailTemplateId) {
				const selectedEmailTemplate = currentEmailTemplates.find(
					D => D._id === state.selectedEmailTemplateId
				);
				if (selectedEmailTemplate) {
					currentEmailTemplate = selectedEmailTemplate;
				}
			}
			return {
				...state,
				loadError: null,
				currentEmailTemplate,
				list: currentEmailTemplates
			};

		case COMMS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(COMMS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_EMAIL_TEMPLATE_ID:
			return {
				...state,
				selectedEmailTemplateId: action.payload?.id,
				currentEmailTemplate:
					state.currentEmailTemplate?._id === action.payload?.id
						? state.currentEmailTemplate
						: null
			};

		case COMMS_UPDATE_LOCAL_RECORD:
			const oldEmailTemplate = state.list.find(D => D._id === id) || {};
			const newEmailTemplate = _.merge(oldEmailTemplate, data);
			return {
				...state,
				currentEmailTemplate:
					state.currentEmailTemplate?._id === id
						? newEmailTemplate
						: state.currentEmailTemplate,
				list: state.list.map(D => {
					if (D._id === id) {
						return newEmailTemplate;
					}
					return D;
				})
			};

		case COMMS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case COMMS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentEmailTemplate:
					state.currentEmailTemplate?._id === id ? null : state.currentEmailTemplate,
				list: state.list.filter(D => D._id !== id)
			};

		case COMMS_REQUEST_START:
			return {
				...state,
				isRequesting: true,
				emailMessageSendResponse: null,
				emailMessageSendResponseError: null
			};

		case COMMS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case EMAIL_TEMPLATE_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case EMAIL_TEMPLATE_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case EMAIL_TEMPLATE_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case EMAIL_TEMPLATE_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case EMAIL_TEMPLATE_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case EMAIL_TEMPLATE_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		case SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE:
			return {
				...state,
				isRequesting: false,
				emailMessageSendResponse: action.payload,
				emailMessageSendResponseError: null
			};

		case SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR:
			return {
				...state,
				isRequesting: false,
				emailMessageSendResponse: null,
				emailMessageSendResponseError: action.payload
			};

		default:
			return state;
	}
};

export default commsReducer;
