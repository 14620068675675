/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Box, Button, Input, Label, Spinner, Textarea, useThemeUI } from 'theme-ui';
import useArticles from '../../../hooks/useArticles';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import EditorSaveButton from '../../segments/EditorSaveButton';
// import { getArticles } from '../../../redux/actions/articles';
import { EditorContainer } from '../../ThemeUiStyled';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import RichTextEditor from '../../segments/RichTextEditor';
import DatePicker from '../../segments/DatePicker';
import SelectOne from '../../segments/SelectOne';
import { ARTICLETYPES, ARTICLETYPES_LABELS } from '../../../constants';

const ArticlesEdit = props => {
	const { data, error } = props;
	const {
		selectedArticleId,
		getArticle,
		saveArticle,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useArticles();
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedArticleId) {
			getArticle(selectedArticleId);
		} else if (data?._id !== selectedArticleId) {
			getArticle(selectedArticleId);
		}
	}, [data, selectedArticleId, getArticle]);

	const articleTypeOptions = useMemo(() => {
		const types = Object.keys(ARTICLETYPES).map(type => {
			return {
				name: ARTICLETYPES_LABELS[type],
				value: ARTICLETYPES[type]
			};
		});
		console.log('articleTypeOptions');
		console.log(types);
		return types;
	}, []);

	const updateLocalEditorState = content => {
		onFieldUpdate({
			target: {
				name: 'body',
				value: content
			}
		});
	};

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveArticle(selectedArticleId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading article....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Article:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								// flex: '1 1 auto',
								width: '30%',
								p: 0
							}}>
							<Label htmlFor='article'>Title</Label>
							<Input
								name='title'
								mb={3}
								value={localData?.title || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='seo_description'>SEO Description</Label>
							<Textarea
								rows={4}
								name='seo_description'
								mb={3}
								value={localData?.seo_description || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='startDate'>Publish Date</Label>
							<DatePicker
								value={localData?.publishDate}
								name='publishDate'
								onFieldUpdate={onFieldUpdate}
							/>
							<Label htmlFor='staleDate'>Stale Date</Label>
							<DatePicker
								value={localData?.staleDate}
								name='staleDate'
								onFieldUpdate={onFieldUpdate}
							/>
							<Label htmlFor='articleType'>Type</Label>
							<SelectOne
								data={articleTypeOptions}
								value={localData?.articleType}
								dataValueProp='value'
								name='articleType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No article types found to choose from.'
							/>
							<Label htmlFor='blurb'>Short blurb</Label>
							<Textarea
								rows={4}
								name='blurb'
								mb={3}
								value={localData?.blurb || ''}
								onChange={onFieldUpdate}
							/>
							<h3>Article Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
							<EditorSaveButton isProcessing={isProcessing} label='Article' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								// __flex: '1 1 auto',
								maxWidth: '70%',
								p: '10px'
							}}>
							<Label htmlFor='body'>Article Body</Label>
							{localData && (
								<RichTextEditor
									html={localData.body}
									setRichTextContent={updateLocalEditorState}
								/>
							)}
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default ArticlesEdit;
