import _ from 'lodash';

import {
	ARTICLES_LOADED,
	ARTICLES_LOAD_ERROR,
	ARTICLES_REQUEST_END,
	ARTICLES_REQUEST_START,
	ARTICLES_ADD_LOCAL_RECORD,
	ARTICLES_UPDATE_LOCAL_RECORD,
	ARTICLE_DELETED,
	ARTICLE_DELETE_ERROR,
	ARTICLE_DELETE_RESULT_CLEAR,
	ARTICLES_DROP_LOCAL_RECORD,
	ARTICLE_SAVED,
	ARTICLE_SAVE_ERROR,
	ARTICLE_SAVE_RESULT_CLEAR,
	SET_SELECTED_ARTICLE_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedArticleId: null,
	currentArticle: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { articles, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case ARTICLES_LOADED:
			// console.warn(ARTICLES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { articles } = action.payload;
			const currentArticles = [..._state.list];
			if (articles?.length) {
				articles.forEach(T => {
					if (!currentArticles.find(CT => CT._id === T._id)) {
						currentArticles.push(T);
					}
				});
			}
			// console.warn('currentArticles');
			// console.log(currentArticles);
			let currentArticle;
			if (state.selectedArticleId) {
				const selectedArticle = currentArticles.find(
					D => D._id === state.selectedArticleId
				);
				if (selectedArticle) {
					currentArticle = selectedArticle;
				}
			}
			return {
				...state,
				loadError: null,
				currentArticle,
				list: currentArticles
			};

		case ARTICLES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(ARTICLES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_ARTICLE_ID:
			return {
				...state,
				selectedArticleId: action.payload?.id,
				currentArticle:
					state.currentArticle?._id === action.payload?.id ? state.currentArticle : null
			};

		case ARTICLES_UPDATE_LOCAL_RECORD:
			const oldArticle = state.list.find(D => D._id === id) || {};
			const newArticle = _.merge(oldArticle, data);
			return {
				...state,
				currentArticle:
					state.currentArticle?._id === id ? newArticle : state.currentArticle,
				list: state.list.map(D => {
					if (D._id === id) {
						return newArticle;
					}
					return D;
				})
			};

		case ARTICLES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case ARTICLES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentArticle: state.currentArticle?._id === id ? null : state.currentArticle,
				list: state.list.filter(D => D._id !== id)
			};

		case ARTICLES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case ARTICLES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case ARTICLE_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case ARTICLE_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case ARTICLE_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case ARTICLE_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case ARTICLE_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case ARTICLE_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
