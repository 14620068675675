/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { Button } from 'theme-ui';

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 10px solid #fff;
`;

const StyledModal = Modal.styled`
    width: 20rem;
    height: 20rem;
    position: fixed;
    top: calc(100vh - 40rem);
    left: calc(100vw - 50% - 10rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eee;
    border-radius: 12px;
    box-shadow: 0 0 16px rgba(0,0,0,0.3);
`;

const buttonSx = theme => ({
	ml: '0.5em',
	my: '0.5em',
	p: '5px 8px'
	// backgroundColor: theme.colors.highlight,
	// color: theme.colors.text
});

const DeleteModal = props => {
	const { show, objName, confirm, reject } = props;
	const { theme } = useThemeUI();
	const [isOpen, setIsOpen] = useState(false);

	const keyListenersMap = new Map([
		// [27, onModalClose],
		[13, confirm]
	]);

	useEffect(() => {
		function keyListener(e) {
			const { keyCode } = e;
			// console.log('keyListener');
			// console.log(Object.keys(e));
			switch (keyCode) {
				case 13:
					console.log('13 - enter - confirm');
					return confirm(e);
				case 190:
					console.log('190 - period - reject');
					return reject(e);
				default:
					// console.log('not supported key');
					// console.log(keyCode);
					return;
			}
			// const listener = keyListenersMap.get(e.keyCode);
			// return listener && listener(e);
		}
		document.addEventListener('keydown', keyListener);
		return () => document.removeEventListener('keydown', keyListener);
	});

	useEffect(() => {
		setIsOpen(show);
	}, [show]);

	if (!show) {
		return null;
	}

	return (
		<StyledModal
			theme={theme}
			isOpen={isOpen}
			onBackgroundClick={() => false}
			onEscapeKeydown={() => false}>
			<h2>Delete this {objName}?</h2>
			<div>
				<Button sx={buttonSx(theme)} tabIndex='0' onClick={confirm}>
					OK
				</Button>
				<Button sx={buttonSx(theme)} tabIndex='1' onClick={reject}>
					Cancel
				</Button>
			</div>
		</StyledModal>
	);
};

export default DeleteModal;
