/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Text } from 'theme-ui'

import useDonationMatrixes from '../../../hooks/useDonationMatrixes';

const ActionButtons = props => {
	const { method } = props;
	const { theme } = useThemeUI();

	const {
		// donationMatrixes,
		// currentDonationMatrix,
		selectedDonationMatrixId,
		error
	} = useDonationMatrixes();

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<div
			sx={{
				mb: '1.5em'
			}}>
			{method !== 'list' && (
				<Link sx={linkSx} to='/data/donation-matrixes'>
					Back to Donation Matrixes List
				</Link>
			)}
			{['add', 'edit', 'delete'].indexOf(method) === -1 && (
				<Link sx={linkSx} to='/data/donation-matrixes/add'>
					+ Add a Donation Matrix
				</Link>
			)}
			{method === 'view' && selectedDonationMatrixId && (
				<>
					<Link
						sx={{ ...linkSx, color: theme.colors.text, fontWeight: 'bold' }}
						to={'/data/donation-matrixes/edit/' + selectedDonationMatrixId}>
						Edit this DonationMatrix
					</Link>
					<Link
						sx={linkSx}
						to={'/data/donation-matrixes/delete/' + selectedDonationMatrixId}>
						-- DELETE DonationMatrix --
					</Link>
				</>
			)}
		</div>
	);
};

export default ActionButtons;
