import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	PRIZEDRAWS_ADD_ERROR,
	PRIZEDRAWS_LOADED,
	PRIZEDRAWS_LOAD_ERROR,
	PRIZEDRAWS_REQUEST_END,
	PRIZEDRAWS_REQUEST_START,
	PRIZEDRAWS_UPDATE_ERROR,
	PRIZEDRAWS_ADD_LOCAL_RECORD,
	PRIZEDRAWS_UPDATE_LOCAL_RECORD,
	PRIZEDRAWS_DROP_LOCAL_RECORD,
	PRIZEDRAW_DELETED,
	PRIZEDRAW_DELETE_ERROR,
	PRIZEDRAW_DELETE_RESULT_CLEAR,
	PRIZEDRAW_SAVED,
	PRIZEDRAW_SAVE_ERROR,
	PRIZEDRAW_SAVE_RESULT_CLEAR,
	SET_SELECTED_PRIZEDRAW_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * PrizeDraw Actions
 */

export const getPrizeDraws = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().prizeDraws;
		if (state.isRequesting) {
			// console.log('getPrizeDraws: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getPrizeDraws(ids, options);
			};
			dispatch(queueDeferredAction('prizeDraws', deferred));
			return;
		}
		dispatch(prizeDrawsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=prize-draws');
		} catch (err) {
			error = err;
		}

		dispatch(prizeDrawsRequestEnd());

		if (error) {
			return dispatch(prizeDrawsLoadError(error));
		}

		dispatch(prizeDrawsLoaded({ prizeDraws: response?.data }));
	};
};

export const loadPrizeDraw = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadPrizeDraw, no id!');
			return dispatch(
				prizeDrawsLoadError({
					message: 'no id found to load prizeDraw with'
				})
			);
		}
		console.log('loadPrizeDraw loadPrizeDraw loadPrizeDraw loadPrizeDraw ');
		console.log(id);
		const state = getState().prizeDraws;
		if (state.isRequesting) {
			// console.log('getPrizeDraws: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadPrizeDraw(id);
			};
			dispatch(queueDeferredAction('prizeDraws', deferred));
			return;
		}
		dispatch(prizeDrawsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=prize-draws&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(prizeDrawsRequestEnd());

		if (error) {
			return dispatch(prizeDrawsLoadError(error));
		}

		console.log('loadPrizeDraw loadPrizeDraw loadPrizeDraw loadPrizeDraw ');
		console.log(response);

		dispatch(prizeDrawsLoaded({ prizeDraws: [response?.data] }));
	};
};

export const setSelectedPrizeDrawId = id => ({
	type: SET_SELECTED_PRIZEDRAW_ID,
	payload: { id }
});

export const addPrizeDraw = data => {
	return async (dispatch, getState) => {
		const state = getState().prizeDraws;
		if (state.isRequesting) {
			const deferred = () => {
				addPrizeDraw(data);
			};
			dispatch(queueDeferredAction('prizeDraws', deferred));
			return;
		}
		dispatch(prizeDrawsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=prize-draws', {
				data
			});
		} catch (err) {
			error = err;
		}

		console.log('addPrizeDraw, response');
		console.log(response);

		dispatch(prizeDrawsRequestEnd());

		if (error) {
			dispatch(prizeDrawSaveError(responses.PRIZEDRAW_SAVE_ERROR));
			return dispatch(prizeDrawsAddError(error));
		}

		dispatch(prizeDrawSaved(responses.PRIZEDRAW_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addPrizeDrawLocalRecord(data));
		}
	};
};

export const updatePrizeDraw = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updatePrizeDraw, no id!');
			return dispatch(
				prizeDrawsUpdateError({
					message: 'no id found to update prizeDraw with'
				})
			);
		}
		const state = getState().prizeDraws;
		if (state.isRequesting) {
			const deferred = () => {
				updatePrizeDraw(id, data);
			};
			dispatch(queueDeferredAction('prizeDraws', deferred));
			return;
		}
		dispatch(prizeDrawsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=prize-draws&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(prizeDrawsRequestEnd());

		console.log('updatePrizeDraw ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(prizeDrawSaveError(responses.PRIZEDRAW_SAVE_ERROR));
			return dispatch(prizeDrawsUpdateError(error));
		}

		dispatch(prizeDrawSaved(responses.PRIZEDRAW_SAVED));
		dispatch(updatePrizeDrawLocalRecord(id, data));
	};
};

export const deletePrizeDraw = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deletePrizeDraw, no id!');
			return false; // dispatch(prizeDrawsUpdateError({ message: 'no id found to update prizeDraw with' }));
		}
		const state = getState().prizeDraws;
		if (state.isRequesting) {
			const deferred = () => {
				deletePrizeDraw(id);
			};
			dispatch(queueDeferredAction('prizeDraws', deferred));
			return;
		}
		dispatch(prizeDrawsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=prize-draws&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(prizeDrawsRequestEnd());

		console.log('deletePrizeDraw ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(prizeDrawDeleteError(responses.PRIZEDRAW_DELETE_ERROR));
			// return dispatch(prizeDrawsUpdateError(error));
		}

		dispatch(prizeDrawDeleted(responses.PRIZEDRAW_DELETED));
		dispatch(dropPrizeDrawLocalRecord(id));
	};
};

export const addPrizeDrawLocalRecord = data => ({
	type: PRIZEDRAWS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updatePrizeDrawLocalRecord = (id, data) => ({
	type: PRIZEDRAWS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const prizeDrawsLoaded = ({ prizeDraws }) => ({
	type: PRIZEDRAWS_LOADED,
	payload: { prizeDraws }
});

export const prizeDrawSaved = responseMsg => ({
	type: PRIZEDRAW_SAVED,
	payload: responseMsg
});

export const prizeDrawSaveError = responseMsg => ({
	type: PRIZEDRAW_SAVE_ERROR,
	payload: responseMsg
});

export const dropPrizeDrawLocalRecord = id => ({
	type: PRIZEDRAWS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const prizeDrawDeleted = responseMsg => ({
	type: PRIZEDRAW_DELETED,
	payload: responseMsg
});

export const prizeDrawDeleteError = responseMsg => ({
	type: PRIZEDRAW_DELETE_ERROR,
	payload: responseMsg
});

export const prizeDrawClearSaveResult = () => ({
	type: PRIZEDRAW_SAVE_RESULT_CLEAR
});

export const prizeDrawClearDeleteResult = () => ({
	type: PRIZEDRAW_DELETE_RESULT_CLEAR
});

export const prizeDrawsLoadError = error => ({
	type: PRIZEDRAWS_LOAD_ERROR,
	payload: { error }
});

export const prizeDrawsAddError = error => ({
	type: PRIZEDRAWS_ADD_ERROR,
	payload: { error }
});

export const prizeDrawsUpdateError = error => ({
	type: PRIZEDRAWS_UPDATE_ERROR,
	payload: { error }
});

export const prizeDrawsRequestEnd = () => ({
	type: PRIZEDRAWS_REQUEST_END,
	payload: false
});

export const prizeDrawsRequestStart = () => ({
	type: PRIZEDRAWS_REQUEST_START,
	payload: true
});
