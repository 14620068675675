/** @jsx jsx */
import { jsx, Flex, Box, Button, Input } from 'theme-ui';

import SelectOne from '../../segments/SelectOne';

const EventSetup = props => {
	const {
		eventData,
		setupData,
		slotCount,
		setupHeaderLabel,
		onSetupUpdate,
		onFieldUpdate,
		rosterLockedRow,
		lockable,
		slotTypeLabel,
		showSlotTypeLabel
	} = props;

	if (setupData?.length && slotCount) {
		const stack = [];
		if (eventData.lockRoster) {
			stack.push(
				<p key='setupTop' sx={{ m: 0 }}>
					<strong>{setupHeaderLabel}</strong> {slotCount}
				</p>
			);
		}

		for (let i = 1; i <= slotCount; i += 1) {
			if (lockable && eventData.lockRoster) {
				rosterLockedRow(stack, i, eventData.lockRoster, eventData);
			} else {
				const slotLabel = showSlotTypeLabel
					? `${slotTypeLabel} ${i}`
					: i;
				stack.push(
					<Flex key={i}>
						<Box>{slotLabel}</Box>
						{setupData.map((setup, idx) => {
							let selectorNode;
							switch (setup.type) {
								case 'string-input':
									selectorNode = (
										<Input
											value={eventData?.[setup.fieldnameGenerator(i)] || ''}
											name={setup.fieldnameGenerator(i)}
											onChange={onSetupUpdate}
										/>
									);
									break;

								case 'select-one':
								case 'select':
								default:
									selectorNode = (
										<SelectOne
											data={setup.data}
											value={eventData?.[setup.fieldnameGenerator(i)]}
											name={setup.fieldnameGenerator(i)}
											onFieldUpdate={onSetupUpdate}
											noDataMsg={`No ${setup.label} found to choose from.`}
										/>
									);
									break;
							}
							return (
								<Box
									key={idx}
									sx={{
										flex: '0.5',
										paddingLeft: '8px'
									}}>
									{selectorNode}
								</Box>
							);
						})}
					</Flex>
				);
			}
		}
		if (lockable && eventData.lockRoster) {
			stack.push(
				<Box key='unlockRoster'>
					<Button
						onClick={() =>
							onFieldUpdate({ target: { name: 'lockRoster', value: false } })
						}>
						Unlock Roster
					</Button>
				</Box>
			);
		}
		return stack;
	}
	return slotCount
		? 'Loading setup data, one moment please...'
		: 'Please set how many slots are in this event.';
};

EventSetup.defaultProps = {
	lockable: true,
	showSlotTypeLabel: true,
	slotTypeLabel: 'Slot'
};

export default EventSetup;
