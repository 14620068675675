import _ from 'lodash';

import {
	ADMINS_ADD_ERROR,
	ADMINS_LOADED,
	ADMINS_LOAD_ERROR,
	ADMINS_REQUEST_END,
	ADMINS_REQUEST_START,
	ADMINS_ADD_LOCAL_RECORD,
	ADMINS_UPDATE_LOCAL_RECORD,
	ADMIN_DELETED,
	ADMIN_DELETE_ERROR,
	ADMIN_DELETE_RESULT_CLEAR,
	ADMINS_DROP_LOCAL_RECORD,
	ADMIN_SAVED,
	ADMIN_SAVE_ERROR,
	ADMIN_SAVE_RESULT_CLEAR,
	SET_SELECTED_ADMIN_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedAdminId: null,
	currentAdmin: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { admins, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case ADMINS_LOADED:
			// console.warn(ADMINS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { admins } = action.payload;
			const currentAdmins = [..._state.list];
			if (admins?.length) {
				admins.forEach(T => {
					if (!currentAdmins.find(CT => CT._id === T._id)) {
						currentAdmins.push(T);
					}
				});
			}
			// console.warn('currentAdmins');
			// console.log(currentAdmins);
			let currentAdmin;
			if (state.selectedAdminId) {
				const selectedAdmin = currentAdmins.find(D => D._id === state.selectedAdminId);
				if (selectedAdmin) {
					currentAdmin = selectedAdmin;
				}
			}
			return {
				...state,
				loadError: null,
				currentAdmin,
				list: currentAdmins
			};

		case ADMINS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(ADMINS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_ADMIN_ID:
			return {
				...state,
				selectedAdminId: action.payload?.id,
				currentAdmin:
					state.currentAdmin?._id === action.payload?.id ? state.currentAdmin : null
			};

		case ADMINS_UPDATE_LOCAL_RECORD:
			const oldAdmin = state.list.find(D => D._id === id) || {};
			const newAdmin = _.merge(oldAdmin, data);
			return {
				...state,
				currentAdmin: state.currentAdmin?._id === id ? newAdmin : state.currentAdmin,
				list: state.list.map(D => {
					if (D._id === id) {
						return newAdmin;
					}
					return D;
				})
			};

		case ADMINS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case ADMINS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentAdmin: state.currentAdmin?._id === id ? null : state.currentAdmin,
				list: state.list.filter(D => D._id !== id)
			};

		case ADMINS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case ADMINS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case ADMIN_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case ADMIN_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case ADMIN_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case ADMIN_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case ADMIN_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case ADMIN_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
