import { combineReducers } from 'redux';

import admins from './admins';
import articles from './articles';
import brands from './brands';
import candidates from './candidates';
import cars from './cars';
import charities from './charities';
import comms from './comms';
import donationHistory from './donationHistory';
import donationMatrixes from './donationMatrixes';
import drivers from './drivers';
import entries from './entries';
import locations from './locations';
import jackpotPicks from './jackpotPicks';
import jackpotResults from './jackpotResults';
// import jackpotTickets from './jackpotTickets';
import jackpotUi from './jackpotUi';
import prizeDraws from './prizeDraws';
import raceResults from './raceResults';
import races from './races';
import rafflePrizes from './rafflePrizes';
import raffleTickets from './raffleTickets';
import sponsors from './sponsors';
import tournaments from './tournaments';
import voteContests from './voteContests';
import user from './user';
import users from './users';

export default combineReducers({
	admins,
	articles,
	brands,
	candidates,
	cars,
	charities,
	comms,
	donationHistory,
	donationMatrixes,
	drivers,
	entries,
	jackpotPicks,
	jackpotResults,
	// jackpotTickets,
	jackpotUi,
	locations,
	prizeDraws,
	raceResults,
	races,
	rafflePrizes,
	raffleTickets,
	sponsors,
	voteContests,
	users,
	user
});
