import _ from 'lodash';

import {
	USERS_LOADED,
	USERS_LOAD_ERROR,
	USERS_REQUEST_END,
	USERS_REQUEST_START,
	USERS_ADD_LOCAL_RECORD,
	USERS_UPDATE_LOCAL_RECORD,
	USER_DELETED,
	USER_DELETE_ERROR,
	USER_DELETE_RESULT_CLEAR,
	USERS_DROP_LOCAL_RECORD,
	USER_SAVED,
	USER_SAVE_ERROR,
	USER_SAVE_RESULT_CLEAR,
	SET_SELECTED_USER_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedUserId: null,
	currentUser: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

const usersReducer = function (state = initialState, action) {
	const _state = { ...state };
	const { users, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case USERS_LOADED:
			// console.warn(USERS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { users } = action.payload;
			const currentUsers = [..._state.list];
			if (users?.length) {
				users.forEach(T => {
					if (!currentUsers.find(CT => CT._id === T._id)) {
						currentUsers.push(T);
					}
				});
			}
			// console.warn('currentUsers');
			// console.log(currentUsers);
			let currentUser;
			if (state.selectedUserId) {
				const selectedUser = currentUsers.find(D => D._id === state.selectedUserId);
				if (selectedUser) {
					currentUser = selectedUser;
				}
			}
			return {
				...state,
				loadError: null,
				currentUser,
				list: currentUsers
			};

		case USERS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(USERS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_USER_ID:
			return {
				...state,
				selectedUserId: action.payload?.id,
				currentUser:
					state.currentUser?._id === action.payload?.id ? state.currentUser : null
			};

		case USERS_UPDATE_LOCAL_RECORD:
			const oldUser = state.list.find(D => D._id === id) || {};
			const newUser = _.merge(oldUser, data);
			return {
				...state,
				currentUser: state.currentUser?._id === id ? newUser : state.currentUser,
				list: state.list.map(D => {
					if (D._id === id) {
						return newUser;
					}
					return D;
				})
			};

		case USERS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case USERS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentUser: state.currentUser?._id === id ? null : state.currentUser,
				list: state.list.filter(D => D._id !== id)
			};

		case USERS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case USERS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case USER_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case USER_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case USER_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case USER_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case USER_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case USER_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
};

export default usersReducer;
