import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getDonationMatrixes as _getDonationMatrixes,
	setSelectedDonationMatrixId,
	loadDonationMatrix,
	addDonationMatrix,
	updateDonationMatrix,
	deleteDonationMatrix as _deleteDonationMatrix,
	donationMatrixSaveError,
	donationMatrixClearSaveResult,
	donationMatrixClearDeleteResult
} from '../redux/actions/donationMatrixes';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useDonationMatrixes = () => {
	const donationMatrixes = useSelector(state => state.donationMatrixes?.list, shallowEqual);
	const currentDonationMatrix = useSelector(
		state => state.donationMatrixes?.currentDonationMatrix
	);
	const selectedDonationMatrixId = useSelector(
		state => state.donationMatrixes?.selectedDonationMatrixId
	);
	const error = useSelector(state => state.donationMatrixes?.loadError);
	const saveResult = useSelector(state => state.donationMatrixes?.saveResult);
	const deleteResult = useSelector(state => state.donationMatrixes?.deleteResult);
	const isProcessing = useSelector(state => state.donationMatrixes?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.donationMatrix && data.car;
	};

	const getDonationMatrixes = useCallback(() => {
		dispatch(_getDonationMatrixes());
	}, [dispatch]);

	const getDonationMatrix = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedDonationMatrixId(id));
				dispatch(loadDonationMatrix({ id }));
			}
		},
		[dispatch]
	);

	const selectDonationMatrix = useCallback(
		id => {
			dispatch(setSelectedDonationMatrixId(id));
		},
		[dispatch]
	);

	const deleteDonationMatrix = useCallback(
		id => {
			dispatch(_deleteDonationMatrix(id));
		},
		[dispatch]
	);

	const saveDonationMatrix = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateDonationMatrix(id, data));
				}
				return dispatch(addDonationMatrix(data));
			}
			dispatch(donationMatrixSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(donationMatrixClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(donationMatrixClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getDonationMatrixes,
		getDonationMatrix,
		saveDonationMatrix,
		selectDonationMatrix,
		deleteDonationMatrix,
		isProcessing,
		currentDonationMatrix,
		selectedDonationMatrixId,
		donationMatrixes,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useDonationMatrixes;
