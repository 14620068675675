/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Flex, Box, Button, Input, Label, Spinner } from 'theme-ui';
import useRaces from '../../../hooks/useRaces';
import useLocations from '../../../hooks/useLocations';
import useCars from '../../../hooks/useCars';
import useDrivers from '../../../hooks/useDrivers';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';
import { raceTypeOptions } from 'constants/eventUiHelpers';
import RaceSetup from 'components/tools/EventBuilders/SetupBuilders/RaceSetup';
import RaceResults from 'components/tools/EventBuilders/ResultBuilders/RaceResults';

const RacesAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveRace,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useRaces();
	const {
		getLocations,
		locations,
		error: locationError,
		isProcessing: isProcessingLocations
	} = useLocations();
	const { getCars, cars, error: carError, isProcessing: isProcessingCars } = useCars();
	const {
		getDrivers,
		drivers,
		error: driverError,
		isProcessing: isProcessingDrivers
	} = useDrivers();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);
	const [hasRequestedCars, setHasRequestedCars] = useState(false);
	const [hasRequestedDrivers, setHasRequestedDrivers] = useState(false);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	useEffect(() => {
		if (!hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [hasRequestedLocations, getLocations, setHasRequestedLocations]);

	useEffect(() => {
		if (!hasRequestedCars) {
			setHasRequestedCars(true);
			getCars();
		}
	}, [hasRequestedCars, getCars, setHasRequestedCars]);

	useEffect(() => {
		if (!hasRequestedDrivers) {
			setHasRequestedDrivers(true);
			getDrivers();
		}
	}, [hasRequestedDrivers, getDrivers, setHasRequestedDrivers]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const handleForm = e => {
		e.preventDefault();
		const raceDate = moment(localData.raceDate);
		localData.raceDate = raceDate.format('YYYY-MM-DD');
		saveRace(null, localData);
	};

	// calculate if all places are set, not in use yet
	const placesAreSet = useMemo(
		() => () => {
			if (localData?.carCount && localData?.lockRoster) {
				let places = 0;
				// helps guard against doubles until child components are smarter
				const placeTracker = {};
				for (let i = 1; i <= localData.carCount; i += 1) {
					if (localData[`slot_${i}_place`] > 0 && placeTracker[i]) {
						places += 1;
						placeTracker[i] = true;
					}
				}
				return places === parseInt(localData.carCount);
			}
			return false;
		},
		[localData]
	);

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Race:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Race</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='location'>Location</Label>
							<SelectOne
								data={locations}
								value={localData?.location}
								name='location'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No locations found to choose from.'
							/>
							<Label htmlFor='raceType'>Race Type</Label>
							<SelectOne
								data={raceTypeOptions}
								value={localData?.raceType}
								name='raceType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No race types found to choose from.'
							/>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='raceDate'>Date</Label>
									<DatePicker
										value={localData?.raceDate}
										name='raceDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto'
									}}>
									<Label htmlFor='time'>Time (GMT/24hr)</Label>
									<Input
										name='time'
										mb={3}
										value={localData?.time || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<h5>[ Add logo upload widget if we want one ]</h5>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Race'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Set up Cars + Drivers</h3>
							{/*raceSetupOptions*/}
							<RaceSetup
								cars={cars}
								drivers={drivers}
								localData={localData}
								onFieldUpdate={onFieldUpdate}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Race Results</h3>
							{/*raceResultsOptions*/}
							<RaceResults
								localData={localData}
								cars={cars}
								onFieldUpdate={onFieldUpdate}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default RacesAdd;
