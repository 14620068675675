import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Button, Styled } from 'theme-ui';
import useImages from '../../hooks/useImages';

const humanFileSize = bytes => {
	const i = bytes ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;
	return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const ImageUploadContainer = styled(Box)`
	border: 1px solid #aaa;
	border-radius: 8px;
	padding: 12px;
	text-align: center;

	img {
		max-width: 60%;
		height: auto;
		margin: 0 auto 1em;
		display: block;
	}
`;

const ImageDisplayer = props => {
	const { id } = props;

	const { getImage, imageData, imageLoadError } = useImages();

	const [localErrors, setLocalErrors] = useState(null);
	const [localImageData, setLocalImageData] = useState(null);

	useEffect(() => {
		if (id && !imageData && !imageLoadError) {
			console.log('go get the image');
			getImage(id);
		}
	}, [id, getImage, imageData, imageLoadError]);

	const displayImage = useMemo(() => {
		const myImageSrc = localImageData || imageData;
		if (!myImageSrc) return null;
		return (
			<ImageUploadContainer>
				<img src={myImageSrc.dataURI} alt="Displayer" />
				<Styled.p>
					<em>
						{myImageSrc.fileMeta?.name}, {humanFileSize(myImageSrc.fileMeta?.size)}
					</em>
				</Styled.p>
			</ImageUploadContainer>
		);
	}, [localImageData, imageData]);

	if (displayImage) {
		return displayImage;
	}

	return (
		<ImageUploadContainer>
			<Styled.h1>No image found to display, id={id}</Styled.h1>
		</ImageUploadContainer>
	);
};

export default ImageDisplayer;
