import _ from 'lodash';

import {
	CARS_LOADED,
	CARS_LOAD_ERROR,
	CARS_REQUEST_END,
	CARS_REQUEST_START,
	CARS_ADD_LOCAL_RECORD,
	CARS_UPDATE_LOCAL_RECORD,
	CAR_DELETED,
	CAR_DELETE_ERROR,
	CAR_DELETE_RESULT_CLEAR,
	CARS_DROP_LOCAL_RECORD,
	CAR_SAVED,
	CAR_SAVE_ERROR,
	CAR_SAVE_RESULT_CLEAR,
	SET_SELECTED_CAR_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedCarId: null,
	currentCar: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { cars, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case CARS_LOADED:
			// console.warn(CARS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { cars } = action.payload;
			const currentCars = [..._state.list];
			if (cars?.length) {
				cars.forEach(T => {
					if (!currentCars.find(CT => CT._id === T._id)) {
						currentCars.push(T);
					}
				});
			}
			// console.warn('currentCars');
			// console.log(currentCars);
			let currentCar;
			if (state.selectedCarId) {
				const selectedCar = currentCars.find(D => D._id === state.selectedCarId);
				if (selectedCar) {
					currentCar = selectedCar;
				}
			}
			return {
				...state,
				loadError: null,
				currentCar,
				list: currentCars
			};

		case CARS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(CARS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_CAR_ID:
			return {
				...state,
				selectedCarId: action.payload?.id,
				currentCar: state.currentCar?._id === action.payload?.id ? state.currentCar : null
			};

		case CARS_UPDATE_LOCAL_RECORD:
			const oldCar = state.list.find(D => D._id === id) || {};
			const newCar = _.merge(oldCar, data);
			return {
				...state,
				currentCar: state.currentCar?._id === id ? newCar : state.currentCar,
				list: state.list.map(D => {
					if (D._id === id) {
						return newCar;
					}
					return D;
				})
			};

		case CARS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case CARS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentCar: state.currentCar?._id === id ? null : state.currentCar,
				list: state.list.filter(D => D._id !== id)
			};

		case CARS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case CARS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case CAR_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case CAR_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case CAR_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case CAR_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case CAR_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case CAR_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
