import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	USERS_ADD_ERROR,
	USERS_LOADED,
	USERS_LOAD_ERROR,
	USERS_REQUEST_END,
	USERS_REQUEST_START,
	USERS_UPDATE_ERROR,
	USERS_ADD_LOCAL_RECORD,
	USERS_UPDATE_LOCAL_RECORD,
	USERS_DROP_LOCAL_RECORD,
	USER_DELETED,
	USER_DELETE_ERROR,
	USER_DELETE_RESULT_CLEAR,
	USER_SAVED,
	USER_SAVE_ERROR,
	USER_SAVE_RESULT_CLEAR,
	SET_SELECTED_USER_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * User Actions
 */

export const getUsers = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().users;
		if (state.isRequesting) {
			// console.log('getUsers: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getUsers(ids, options);
			};
			dispatch(queueDeferredAction('users', deferred));
			return;
		}
		dispatch(usersRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=users');
		} catch (err) {
			error = err;
		}

		dispatch(usersRequestEnd());

		if (error) {
			return dispatch(usersLoadError(error));
		}

		dispatch(usersLoaded({ users: response?.data }));
	};
};

export const loadUser = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadUser, no id!');
			return dispatch(usersLoadError({ message: 'no id found to load user with' }));
		}
		console.log('loadUser loadUser loadUser loadUser ');
		console.log(id);
		const state = getState().users;
		if (state.isRequesting) {
			// console.log('getUsers: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadUser(id);
			};
			dispatch(queueDeferredAction('users', deferred));
			return;
		}
		dispatch(usersRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=users&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(usersRequestEnd());

		if (error) {
			return dispatch(usersLoadError(error));
		}

		console.log('loadUser loadUser loadUser loadUser ');
		console.log(response);

		dispatch(usersLoaded({ users: [response?.data] }));
	};
};

export const setSelectedUserId = id => ({
	type: SET_SELECTED_USER_ID,
	payload: { id }
});

export const addUser = data => {
	return async (dispatch, getState) => {
		const state = getState().users;
		if (state.isRequesting) {
			const deferred = () => {
				addUser(data);
			};
			dispatch(queueDeferredAction('users', deferred));
			return;
		}
		dispatch(usersRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=users', { data });
		} catch (err) {
			error = err;
		}

		console.log('addUser, response');
		console.log(response);

		dispatch(usersRequestEnd());

		if (error) {
			dispatch(userSaveError(responses.USER_SAVE_ERROR));
			return dispatch(usersAddError(error));
		}

		dispatch(userSaved(responses.USER_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addUserLocalRecord(data));
		}
	};
};

export const updateUser = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateUser, no id!');
			return dispatch(
				usersUpdateError({
					message: 'no id found to update user with'
				})
			);
		}
		const state = getState().users;
		if (state.isRequesting) {
			const deferred = () => {
				updateUser(id, data);
			};
			dispatch(queueDeferredAction('users', deferred));
			return;
		}
		dispatch(usersRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=users&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(usersRequestEnd());

		console.log('updateUser ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(userSaveError(responses.USER_SAVE_ERROR));
			return dispatch(usersUpdateError(error));
		}

		dispatch(userSaved(responses.USER_SAVED));
		dispatch(updateUserLocalRecord(id, data));
	};
};

export const deleteUser = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteUser, no id!');
			return false; // dispatch(usersUpdateError({ message: 'no id found to update user with' }));
		}
		const state = getState().users;
		if (state.isRequesting) {
			const deferred = () => {
				deleteUser(id);
			};
			dispatch(queueDeferredAction('users', deferred));
			return;
		}
		dispatch(usersRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=users&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(usersRequestEnd());

		console.log('deleteUser ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(userDeleteError(responses.USER_DELETE_ERROR));
			// return dispatch(usersUpdateError(error));
		}

		dispatch(userDeleted(responses.USER_DELETED));
		dispatch(dropUserLocalRecord(id));
	};
};

export const addUserLocalRecord = data => ({
	type: USERS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateUserLocalRecord = (id, data) => ({
	type: USERS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const usersLoaded = ({ users }) => ({
	type: USERS_LOADED,
	payload: { users }
});

export const userSaved = responseMsg => ({
	type: USER_SAVED,
	payload: responseMsg
});

export const userSaveError = responseMsg => ({
	type: USER_SAVE_ERROR,
	payload: responseMsg
});

export const dropUserLocalRecord = id => ({
	type: USERS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const userDeleted = responseMsg => ({
	type: USER_DELETED,
	payload: responseMsg
});

export const userDeleteError = responseMsg => ({
	type: USER_DELETE_ERROR,
	payload: responseMsg
});

export const userClearSaveResult = () => ({
	type: USER_SAVE_RESULT_CLEAR
});

export const userClearDeleteResult = () => ({
	type: USER_DELETE_RESULT_CLEAR
});

export const usersLoadError = error => ({
	type: USERS_LOAD_ERROR,
	payload: { error }
});

export const usersAddError = error => ({
	type: USERS_ADD_ERROR,
	payload: { error }
});

export const usersUpdateError = error => ({
	type: USERS_UPDATE_ERROR,
	payload: { error }
});

export const usersRequestEnd = () => ({
	type: USERS_REQUEST_END,
	payload: false
});

export const usersRequestStart = () => ({
	type: USERS_REQUEST_START,
	payload: true
});
