import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Button, Spinner, Styled } from 'theme-ui';
import useImages from '../../hooks/useImages';

const humanFileSize = bytes => {
	const i = bytes ? Math.floor(Math.log(bytes) / Math.log(1024)) : 0;
	return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const allowedFileTypes = [
	'image/jpg',
	'image/gif',
	'image/jpeg',
	'image/png',
	'image/svg',
	'image/svg+xml'
];

const MAX_FILE_SIZE = 4194304; // 4MB
const MAX_FILE_SIZE_DISPLAY = '4MB';

const ImageUploadContainer = styled(Box)`
	border: 1px solid #aaa;
	border-radius: 8px;
	padding: 12px;
	text-align: center;

	img {
		max-width: 60%;
		height: auto;
		margin: 0 auto 1em;
		display: block;
	}
`;

const ImageUploader = props => {
	const { id, onChange } = props;
	const hiddenFileInput = useRef(null);

	const { getImage, clearImage, imageData, imageLoadError } = useImages();

	const [localErrors, setLocalErrors] = useState(null);
	const [localImageData, setLocalImageData] = useState(null);
	const [isLoading, setIsLoading] = useState(null);

	useEffect(() => {
		if (id && !imageData && !imageLoadError) {
			console.log('go get the image');
			getImage(id);
		}
	}, [id, getImage, imageData, imageLoadError]);

	const handleClick = event => {
		event.preventDefault();
		setLocalErrors(null);
		hiddenFileInput.current.click();
	};

	const handleChange = event => {
		event.preventDefault();
		const file = event.target?.files?.[0];
		if (!file) {
			// selecting none should remove the file
			setLocalErrors(null);
			setLocalImageData(null);
			clearImage();
			onChange(null);
			return false;
		}

		const { type: ftype, size: fsize, name: fname } = file;

		if (!ftype) {
			return setLocalErrors('No type found for the file, please try again.');
		}

		if (allowedFileTypes.indexOf(ftype) === -1) {
			return setLocalErrors('Unsupported image type, please try again. ' + ftype);
		}

		if (fsize > MAX_FILE_SIZE) {
			return setLocalErrors(
				'Image file is too large, please try again. Maximum image size allowed is ' +
					MAX_FILE_SIZE_DISPLAY
			);
		}

		const reader = new FileReader();

		try {
			reader.readAsDataURL(file);

			reader.onloadstart = () => {
				setIsLoading(true);
			};

			reader.onloadend = upload => {
				const fileMeta = {
					type: ftype,
					size: fsize,
					name: fname
				};
				setIsLoading(false);
				setLocalImageData({
					fileMeta, // << FILE META
					dataURI: upload.target.result // << FILE DATA URI
				});

				onChange({
					fileMeta, // << FILE META
					dataURI: upload.target.result // << FILE DATA URI
				});
			};
		} catch (error) {
			setLocalErrors('Unexpected error occurred, please try again.');
		}
	};

	const displayImage = useMemo(() => {
		const myImageSrc = localImageData || imageData;
		if (!myImageSrc) return null;
		return (
			<ImageUploadContainer>
				<img src={myImageSrc.dataURI} />
				<Styled.p>
					<em>
						{myImageSrc.fileMeta?.name}, {humanFileSize(myImageSrc.fileMeta?.size)}
					</em>
				</Styled.p>
				<Button onClick={handleChange}>Choose a different image</Button>
			</ImageUploadContainer>
		);
	}, [localImageData, imageData]);

	if (displayImage) {
		return displayImage;
	}

	return (
		<ImageUploadContainer>
			<Button onClick={handleClick}>Select your image</Button>
			<input
				type='file'
				ref={hiddenFileInput}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
			{localErrors && <Styled.p>{localErrors}</Styled.p>}
			{imageLoadError && <Styled.p>{imageLoadError}</Styled.p>}
			{isLoading && <Spinner />}
		</ImageUploadContainer>
	);
};

export default ImageUploader;
