import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	RACES_ADD_ERROR,
	RACES_LOADED,
	RACES_LOAD_ERROR,
	RACES_REQUEST_END,
	RACES_REQUEST_START,
	RACES_UPDATE_ERROR,
	RACES_ADD_LOCAL_RECORD,
	RACES_UPDATE_LOCAL_RECORD,
	RACES_DROP_LOCAL_RECORD,
	RACE_DELETED,
	RACE_DELETE_ERROR,
	RACE_DELETE_RESULT_CLEAR,
	RACE_SAVED,
	RACE_SAVE_ERROR,
	RACE_SAVE_RESULT_CLEAR,
	SET_SELECTED_RACE_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Race Actions
 */

export const getRaces = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().races;
		if (state.isRequesting) {
			// console.log('getRaces: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getRaces(ids, options);
			};
			dispatch(queueDeferredAction('races', deferred));
			return;
		}
		dispatch(racesRequestStart());
		let response;
		let error;
		const params = {
			oType: 'races'
		};
		if (options.sort) {
			const sortList = [];
			Object.keys(options.sort).forEach(K => {
				sortList.push(K + '.' + options.sort[K]);
			});
			if (sortList.length) {
				params.sort = sortList.join(',');
			}
		}
		try {
			response = await axios.get('/api/list', { params });
		} catch (err) {
			error = err;
		}

		dispatch(racesRequestEnd());

		if (error) {
			return dispatch(racesLoadError(error));
		}

		dispatch(racesLoaded({ races: response?.data }));
	};
};

export const loadRace = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadRace, no id!');
			return dispatch(racesLoadError({ message: 'no id found to load race with' }));
		}
		console.log('loadRace loadRace loadRace loadRace ');
		console.log(id);
		const state = getState().races;
		if (state.isRequesting) {
			// console.log('getRaces: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadRace({ id }, options);
			};
			dispatch(queueDeferredAction('races', deferred));
			return;
		}
		dispatch(racesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=races&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(racesRequestEnd());

		if (error) {
			return dispatch(racesLoadError(error));
		}

		console.log('loadRace loadRace loadRace loadRace ');
		console.log(response);

		dispatch(racesLoaded({ races: [response?.data] }));
	};
};

export const setSelectedRaceId = id => ({
	type: SET_SELECTED_RACE_ID,
	payload: { id }
});

export const addRace = data => {
	return async (dispatch, getState) => {
		const state = getState().races;
		if (state.isRequesting) {
			const deferred = () => {
				addRace(data);
			};
			dispatch(queueDeferredAction('races', deferred));
			return;
		}
		dispatch(racesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=races', { data });
		} catch (err) {
			error = err;
		}

		console.log('addRace, response');
		console.log(response);

		dispatch(racesRequestEnd());

		if (error) {
			dispatch(raceSaveError(responses.RACE_SAVE_ERROR));
			return dispatch(racesAddError(error));
		}

		dispatch(raceSaved(responses.RACE_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addRaceLocalRecord(data));
		}
	};
};

export const updateRace = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateRace, no id!');
			return dispatch(racesUpdateError({ message: 'no id found to update race with' }));
		}
		const state = getState().races;
		if (state.isRequesting) {
			const deferred = () => {
				updateRace(id, data);
			};
			dispatch(queueDeferredAction('races', deferred));
			return;
		}
		dispatch(racesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=races&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(racesRequestEnd());

		console.log('updateRace ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(raceSaveError(responses.RACE_SAVE_ERROR));
			return dispatch(racesUpdateError(error));
		}

		dispatch(raceSaved(responses.RACE_SAVED));
		dispatch(updateRaceLocalRecord(id, data));
	};
};

export const deleteRace = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteRace, no id!');
			return false; // dispatch(racesUpdateError({ message: 'no id found to update race with' }));
		}
		const state = getState().races;
		if (state.isRequesting) {
			const deferred = () => {
				deleteRace(id);
			};
			dispatch(queueDeferredAction('races', deferred));
			return;
		}
		dispatch(racesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=races&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(racesRequestEnd());

		console.log('deleteRace ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(raceDeleteError(responses.RACE_DELETE_ERROR));
			// return dispatch(racesUpdateError(error));
		}

		dispatch(raceDeleted(responses.RACE_DELETED));
		dispatch(dropRaceLocalRecord(id));
	};
};

export const addRaceLocalRecord = data => ({
	type: RACES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateRaceLocalRecord = (id, data) => ({
	type: RACES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const racesLoaded = ({ races }) => ({
	type: RACES_LOADED,
	payload: { races }
});

export const raceSaved = responseMsg => ({
	type: RACE_SAVED,
	payload: responseMsg
});

export const raceSaveError = responseMsg => ({
	type: RACE_SAVE_ERROR,
	payload: responseMsg
});

export const dropRaceLocalRecord = id => ({
	type: RACES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const raceDeleted = responseMsg => ({
	type: RACE_DELETED,
	payload: responseMsg
});

export const raceDeleteError = responseMsg => ({
	type: RACE_DELETE_ERROR,
	payload: responseMsg
});

export const raceClearSaveResult = () => ({
	type: RACE_SAVE_RESULT_CLEAR
});

export const raceClearDeleteResult = () => ({
	type: RACE_DELETE_RESULT_CLEAR
});

export const racesLoadError = error => ({
	type: RACES_LOAD_ERROR,
	payload: { error }
});

export const racesAddError = error => ({
	type: RACES_ADD_ERROR,
	payload: { error }
});

export const racesUpdateError = error => ({
	type: RACES_UPDATE_ERROR,
	payload: { error }
});

export const racesRequestEnd = () => ({
	type: RACES_REQUEST_END,
	payload: false
});

export const racesRequestStart = () => ({
	type: RACES_REQUEST_START,
	payload: true
});
