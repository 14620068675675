import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	SPONSORS_ADD_ERROR,
	SPONSORS_LOADED,
	SPONSORS_LOAD_ERROR,
	SPONSORS_REQUEST_END,
	SPONSORS_REQUEST_START,
	SPONSORS_UPDATE_ERROR,
	SPONSORS_ADD_LOCAL_RECORD,
	SPONSORS_UPDATE_LOCAL_RECORD,
	SPONSORS_DROP_LOCAL_RECORD,
	SPONSOR_DELETED,
	SPONSOR_DELETE_ERROR,
	SPONSOR_DELETE_RESULT_CLEAR,
	SPONSOR_SAVED,
	SPONSOR_SAVE_ERROR,
	SPONSOR_SAVE_RESULT_CLEAR,
	SET_SELECTED_SPONSOR_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';
import { PROFESSIONAL_ENTITY_TYPES } from '../../constants';

/**
 * Sponsor Actions
 */

export const getSponsors = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().sponsors;
		if (state.isRequesting) {
			// console.log('getSponsors: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getSponsors(ids, options);
			};
			dispatch(queueDeferredAction('sponsors', deferred));
			return;
		}
		dispatch(sponsorsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/list?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.SPONSOR
			);
		} catch (err) {
			error = err;
		}

		dispatch(sponsorsRequestEnd());

		if (error) {
			return dispatch(sponsorsLoadError(error));
		}

		dispatch(sponsorsLoaded({ sponsors: response?.data }));
	};
};

export const loadSponsor = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadSponsor, no id!');
			return dispatch(
				sponsorsLoadError({
					message: 'no id found to load sponsor with'
				})
			);
		}
		console.log('loadSponsor loadSponsor loadSponsor loadSponsor ');
		console.log(id);
		const state = getState().sponsors;
		if (state.isRequesting) {
			// console.log('getSponsors: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadSponsor(id);
			};
			dispatch(queueDeferredAction('sponsors', deferred));
			return;
		}
		dispatch(sponsorsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/get?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.SPONSOR + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(sponsorsRequestEnd());

		if (error) {
			return dispatch(sponsorsLoadError(error));
		}

		console.log('loadSponsor loadSponsor loadSponsor loadSponsor ');
		console.log(response);

		dispatch(sponsorsLoaded({ sponsors: [response?.data] }));
	};
};

export const setSelectedSponsorId = id => ({
	type: SET_SELECTED_SPONSOR_ID,
	payload: { id }
});

export const addSponsor = data => {
	return async (dispatch, getState) => {
		const state = getState().sponsors;
		if (state.isRequesting) {
			const deferred = () => {
				addSponsor(data);
			};
			dispatch(queueDeferredAction('sponsors', deferred));
			return;
		}
		dispatch(sponsorsRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.SPONSOR;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities', { data });
		} catch (err) {
			error = err;
		}

		console.log('addSponsor, response');
		console.log(response);

		dispatch(sponsorsRequestEnd());

		if (error) {
			dispatch(sponsorSaveError(responses.SPONSOR_SAVE_ERROR));
			return dispatch(sponsorsAddError(error));
		}

		dispatch(sponsorSaved(responses.SPONSOR_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addSponsorLocalRecord(data));
		}
	};
};

export const updateSponsor = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateSponsor, no id!');
			return dispatch(
				sponsorsUpdateError({
					message: 'no id found to update sponsor with'
				})
			);
		}
		const state = getState().sponsors;
		if (state.isRequesting) {
			const deferred = () => {
				updateSponsor(id, data);
			};
			dispatch(queueDeferredAction('sponsors', deferred));
			return;
		}
		dispatch(sponsorsRequestStart());
		data.type = PROFESSIONAL_ENTITY_TYPES.SPONSOR;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=entities&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(sponsorsRequestEnd());

		console.log('updateSponsor ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(sponsorSaveError(responses.SPONSOR_SAVE_ERROR));
			return dispatch(sponsorsUpdateError(error));
		}

		dispatch(sponsorSaved(responses.SPONSOR_SAVED));
		dispatch(updateSponsorLocalRecord(id, data));
	};
};

export const deleteSponsor = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteSponsor, no id!');
			return false; // dispatch(sponsorsUpdateError({ message: 'no id found to update sponsor with' }));
		}
		const state = getState().sponsors;
		if (state.isRequesting) {
			const deferred = () => {
				deleteSponsor(id);
			};
			dispatch(queueDeferredAction('sponsors', deferred));
			return;
		}
		dispatch(sponsorsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete(
				'/api/delete?oType=entities&type=' + PROFESSIONAL_ENTITY_TYPES.SPONSOR + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(sponsorsRequestEnd());

		console.log('deleteSponsor ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(sponsorDeleteError(responses.SPONSOR_DELETE_ERROR));
			// return dispatch(sponsorsUpdateError(error));
		}

		dispatch(sponsorDeleted(responses.SPONSOR_DELETED));
		dispatch(dropSponsorLocalRecord(id));
	};
};

export const addSponsorLocalRecord = data => ({
	type: SPONSORS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateSponsorLocalRecord = (id, data) => ({
	type: SPONSORS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const sponsorsLoaded = ({ sponsors }) => ({
	type: SPONSORS_LOADED,
	payload: { sponsors }
});

export const sponsorSaved = responseMsg => ({
	type: SPONSOR_SAVED,
	payload: responseMsg
});

export const sponsorSaveError = responseMsg => ({
	type: SPONSOR_SAVE_ERROR,
	payload: responseMsg
});

export const dropSponsorLocalRecord = id => ({
	type: SPONSORS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const sponsorDeleted = responseMsg => ({
	type: SPONSOR_DELETED,
	payload: responseMsg
});

export const sponsorDeleteError = responseMsg => ({
	type: SPONSOR_DELETE_ERROR,
	payload: responseMsg
});

export const sponsorClearSaveResult = () => ({
	type: SPONSOR_SAVE_RESULT_CLEAR
});

export const sponsorClearDeleteResult = () => ({
	type: SPONSOR_DELETE_RESULT_CLEAR
});

export const sponsorsLoadError = error => ({
	type: SPONSORS_LOAD_ERROR,
	payload: { error }
});

export const sponsorsAddError = error => ({
	type: SPONSORS_ADD_ERROR,
	payload: { error }
});

export const sponsorsUpdateError = error => ({
	type: SPONSORS_UPDATE_ERROR,
	payload: { error }
});

export const sponsorsRequestEnd = () => ({
	type: SPONSORS_REQUEST_END,
	payload: false
});

export const sponsorsRequestStart = () => ({
	type: SPONSORS_REQUEST_START,
	payload: true
});
