import {
	JACKPOT_PICKS_LOADED,
	JACKPOT_PICKS_LOAD_ERROR,
	JACKPOT_PICKS_CREDITS_LOADED,
	JACKPOT_PICKS_CREDITS_LOAD_ERROR,
	JACKPOT_PICKS_REQUEST_END,
	JACKPOT_PICKS_REQUEST_START
} from '../actions/actionTypes';

const initialState = {
	picks: [],
	credits: 0,
	loadError: null,
	isRequesting: false
};

export default function (state = initialState, action) {
	const _state = { ...state };
	switch (action.type) {
		case JACKPOT_PICKS_LOADED:
			const { picks } = action.payload;
			const currentPicks = [..._state.picks];
			if (picks?.length) {
				picks.forEach(T => {
					if (!currentPicks.find(CT => CT._id === T._id)) {
						currentPicks.push(T);
					}
				});
			}
			return {
				...state,
				loadError: null,
				picks: currentPicks
			};

		case JACKPOT_PICKS_CREDITS_LOADED:
			const { credits = 0 } = action.payload;
			return {
				...state,
				loadError: null,
				credits: _state.credits + credits
			};

		case JACKPOT_PICKS_LOAD_ERROR:
		case JACKPOT_PICKS_CREDITS_LOAD_ERROR:
			const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(JACKPOT_PICKS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case JACKPOT_PICKS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case JACKPOT_PICKS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		default:
			return state;
	}
}
