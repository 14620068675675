/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useArticles from '../../../hooks/useArticles';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const Articles = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const {
		getArticles,
		articles,
		currentArticle,
		selectedArticleId,
		selectArticle,
		error
	} = useArticles();

	const [hasRequestedArticles, setHasRequestedArticles] = useState(false);
	const [myArticleId, setMyArticleId] = useState(selectedArticleId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedArticles) {
			setHasRequestedArticles(true);
			getArticles();
		}
	}, [method, hasRequestedArticles, getArticles]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentArticle, method: ' + method);
		if (
			['view', 'edit'].indexOf(method) > -1 &&
			(!currentArticle || currentArticle._id !== _id)
		) {
			console.log('set it! ' + _id);
			selectArticle(_id);
			setMyArticleId(_id);
		}
	}, [selectedArticleId, _id, currentArticle, method, selectArticle]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage Articles
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={articles} error={error} />}
			{method === 'view' && <View data={currentArticle} error={error} />}
			{method === 'edit' && <Edit data={currentArticle} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default Articles;
