import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	CARS_ADD_ERROR,
	CARS_LOADED,
	CARS_LOAD_ERROR,
	CARS_REQUEST_END,
	CARS_REQUEST_START,
	CARS_UPDATE_ERROR,
	CARS_ADD_LOCAL_RECORD,
	CARS_UPDATE_LOCAL_RECORD,
	CARS_DROP_LOCAL_RECORD,
	CAR_DELETED,
	CAR_DELETE_ERROR,
	CAR_DELETE_RESULT_CLEAR,
	CAR_SAVED,
	CAR_SAVE_ERROR,
	CAR_SAVE_RESULT_CLEAR,
	SET_SELECTED_CAR_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Car Actions
 */

export const getCars = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().cars;
		if (state.isRequesting) {
			// console.log('getCars: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getCars(ids, options);
			};
			dispatch(queueDeferredAction('cars', deferred));
			return;
		}
		dispatch(carsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=cars');
		} catch (err) {
			error = err;
		}

		dispatch(carsRequestEnd());

		if (error) {
			return dispatch(carsLoadError(error));
		}

		dispatch(carsLoaded({ cars: response?.data }));
	};
};

export const loadCar = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadCar, no id!');
			return dispatch(carsLoadError({ message: 'no id found to load car with' }));
		}
		console.log('loadCar loadCar loadCar loadCar ');
		console.log(id);
		const state = getState().cars;
		if (state.isRequesting) {
			// console.log('getCars: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadCar(id);
			};
			dispatch(queueDeferredAction('cars', deferred));
			return;
		}
		dispatch(carsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=cars&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(carsRequestEnd());

		if (error) {
			return dispatch(carsLoadError(error));
		}

		console.log('loadCar loadCar loadCar loadCar ');
		console.log(response);

		dispatch(carsLoaded({ cars: [response?.data] }));
	};
};

export const setSelectedCarId = id => ({
	type: SET_SELECTED_CAR_ID,
	payload: { id }
});

export const addCar = data => {
	return async (dispatch, getState) => {
		const state = getState().cars;
		if (state.isRequesting) {
			const deferred = () => {
				addCar(data);
			};
			dispatch(queueDeferredAction('cars', deferred));
			return;
		}
		dispatch(carsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=cars', { data });
		} catch (err) {
			error = err;
		}

		console.log('addCar, response');
		console.log(response);

		dispatch(carsRequestEnd());

		if (error) {
			dispatch(carSaveError(responses.CAR_SAVE_ERROR));
			return dispatch(carsAddError(error));
		}

		dispatch(carSaved(responses.CAR_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addCarLocalRecord(data));
		}
	};
};

export const updateCar = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateCar, no id!');
			return dispatch(carsUpdateError({ message: 'no id found to update car with' }));
		}
		const state = getState().cars;
		if (state.isRequesting) {
			const deferred = () => {
				updateCar(id, data);
			};
			dispatch(queueDeferredAction('cars', deferred));
			return;
		}
		dispatch(carsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=cars&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(carsRequestEnd());

		console.log('updateCar ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(carSaveError(responses.CAR_SAVE_ERROR));
			return dispatch(carsUpdateError(error));
		}

		dispatch(carSaved(responses.CAR_SAVED));
		dispatch(updateCarLocalRecord(id, data));
	};
};

export const deleteCar = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteCar, no id!');
			return false; // dispatch(carsUpdateError({ message: 'no id found to update car with' }));
		}
		const state = getState().cars;
		if (state.isRequesting) {
			const deferred = () => {
				deleteCar(id);
			};
			dispatch(queueDeferredAction('cars', deferred));
			return;
		}
		dispatch(carsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=cars&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(carsRequestEnd());

		console.log('deleteCar ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(carDeleteError(responses.CAR_DELETE_ERROR));
			// return dispatch(carsUpdateError(error));
		}

		dispatch(carDeleted(responses.CAR_DELETED));
		dispatch(dropCarLocalRecord(id));
	};
};

export const addCarLocalRecord = data => ({
	type: CARS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateCarLocalRecord = (id, data) => ({
	type: CARS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const carsLoaded = ({ cars }) => ({
	type: CARS_LOADED,
	payload: { cars }
});

export const carSaved = responseMsg => ({
	type: CAR_SAVED,
	payload: responseMsg
});

export const carSaveError = responseMsg => ({
	type: CAR_SAVE_ERROR,
	payload: responseMsg
});

export const dropCarLocalRecord = id => ({
	type: CARS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const carDeleted = responseMsg => ({
	type: CAR_DELETED,
	payload: responseMsg
});

export const carDeleteError = responseMsg => ({
	type: CAR_DELETE_ERROR,
	payload: responseMsg
});

export const carClearSaveResult = () => ({
	type: CAR_SAVE_RESULT_CLEAR
});

export const carClearDeleteResult = () => ({
	type: CAR_DELETE_RESULT_CLEAR
});

export const carsLoadError = error => ({
	type: CARS_LOAD_ERROR,
	payload: { error }
});

export const carsAddError = error => ({
	type: CARS_ADD_ERROR,
	payload: { error }
});

export const carsUpdateError = error => ({
	type: CARS_UPDATE_ERROR,
	payload: { error }
});

export const carsRequestEnd = () => ({
	type: CARS_REQUEST_END,
	payload: false
});

export const carsRequestStart = () => ({
	type: CARS_REQUEST_START,
	payload: true
});
