import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useRafflePrizes from '../../../hooks/useRafflePrizes';
import useSponsors from '../../../hooks/useSponsors';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const RafflePrizesView = props => {
	const { data, error } = props;
	const { selectedRafflePrizeId, getRafflePrize } = useRafflePrizes();
	const {
		getSponsor,
		currentSponsor
		// error: sponsorError,
		// isProcessing: isProcessingEntities
	} = useSponsors();

	console.log('RafflePrizesView');
	console.log(selectedRafflePrizeId, data, error);

	useEffect(() => {
		if (!data && selectedRafflePrizeId) {
			console.log('getRafflePrize!!!!' + selectedRafflePrizeId);
			getRafflePrize(selectedRafflePrizeId);
		}
	}, [data, selectedRafflePrizeId, getRafflePrize]);

	useEffect(() => {
		if (data && currentSponsor?._id !== data.entity) {
			getSponsor(data?.entity);
		}
	}, [currentSponsor._id, data, data.entity, getSponsor]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading rafflePrize....</p>
			</>
		);
	}

	return (
		<div>
			<h2>RafflePrize:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Value: </td>
						<td>${data.value}</td>
					</tr>
					<tr>
						<td>Donated By: </td>
						<td>{currentSponsor?.name || data.entity || 'none'}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default RafflePrizesView;
