import React, { useState } from 'react';
import styled from 'styled-components';
// import { FormField, TextInput, Button } from 'grommet';
import { Box, Button, Flex, Input, Label } from 'theme-ui';
import useUser from '../../hooks/useUser';
import { getOnFieldUpdate } from '../../utils/datasource';

const ButtonStyled = styled(Button)`
	margin: 1em auto;
	padding: 0.5em 1em;
	background-color: green;
`;

const UserAuth = props => {
	const {
		// userAccount,
		login,
		authError,
		isAuthorized,
		isProcessing
	} = useUser();
	const [localData, setLocalData] = useState({});

	const skip = false;

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const handleLogin = event => {
		event.preventDefault();
		const { email, password } = localData;
		if (email && password) {
			login(email, password);
		}
	};

	if (!skip && !isAuthorized) {
		return (
			<Flex
				sx={{
					justifyContent: 'center'
				}}>
				<Box
					sx={{
						width: ['100%', '50%', '35%'],
						m: '20px'
					}}>
					<h1>Login</h1>
					<Box as='form' onSubmit={handleLogin}>
						<Label htmlFor='email'>Email</Label>
						<Input
							name='email'
							mb={3}
							value={localData?.email || ''}
							onChange={onFieldUpdate}
						/>
						<Label htmlFor='password'>Password</Label>
						<Input
							name='password'
							type='password'
							mb={3}
							value={localData?.password || ''}
							onChange={onFieldUpdate}
						/>
						<ButtonStyled disabled={isProcessing} primary size='medium'>
							Login
						</ButtonStyled>
						{authError && <p>{authError}</p>}
					</Box>
				</Box>
			</Flex>
		);
	}

	return <>{props.children}</>;
};

export default UserAuth;
