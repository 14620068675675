import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getCandidates as _getCandidates,
	setSelectedCandidateId,
	loadCandidate,
	addCandidate,
	updateCandidate,
	deleteCandidate as _deleteCandidate,
	candidateSaveError,
	candidateClearSaveResult,
	candidateClearDeleteResult
} from '../redux/actions/candidates';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useCandidates = () => {
	const candidates = useSelector(state => state.candidates?.list, shallowEqual);
	const currentCandidate = useSelector(state => state.candidates?.currentCandidate);
	const selectedCandidateId = useSelector(state => state.candidates?.selectedCandidateId);
	const error = useSelector(state => state.candidates?.loadError);
	const saveResult = useSelector(state => state.candidates?.saveResult);
	const deleteResult = useSelector(state => state.candidates?.deleteResult);
	const isProcessing = useSelector(state => state.candidates?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.candidate && data.car;
	};

	const getCandidates = useCallback(() => {
		dispatch(_getCandidates());
	}, [dispatch]);

	const getCandidate = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedCandidateId(id));
				dispatch(loadCandidate({ id }));
			}
		},
		[dispatch]
	);

	const selectCandidate = useCallback(
		id => {
			dispatch(setSelectedCandidateId(id));
		},
		[dispatch]
	);

	const deleteCandidate = useCallback(
		id => {
			dispatch(_deleteCandidate(id));
		},
		[dispatch]
	);

	const saveCandidate = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateCandidate(id, data));
				}
				return dispatch(addCandidate(data));
			}
			dispatch(candidateSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(candidateClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(candidateClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getCandidates,
		getCandidate,
		saveCandidate,
		selectCandidate,
		deleteCandidate,
		isProcessing,
		currentCandidate,
		selectedCandidateId,
		candidates,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useCandidates;
