import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	CANDIDATES_ADD_ERROR,
	CANDIDATES_LOADED,
	CANDIDATES_LOAD_ERROR,
	CANDIDATES_REQUEST_END,
	CANDIDATES_REQUEST_START,
	CANDIDATES_UPDATE_ERROR,
	CANDIDATES_ADD_LOCAL_RECORD,
	CANDIDATES_UPDATE_LOCAL_RECORD,
	CANDIDATES_DROP_LOCAL_RECORD,
	CANDIDATE_DELETED,
	CANDIDATE_DELETE_ERROR,
	CANDIDATE_DELETE_RESULT_CLEAR,
	CANDIDATE_SAVED,
	CANDIDATE_SAVE_ERROR,
	CANDIDATE_SAVE_RESULT_CLEAR,
	SET_SELECTED_CANDIDATE_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Candidate Actions
 */

export const getCandidates = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().candidates;
		if (state.isRequesting) {
			// console.log('getCandidates: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getCandidates(ids, options);
			};
			dispatch(queueDeferredAction('candidates', deferred));
			return;
		}
		dispatch(candidatesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=candidates');
		} catch (err) {
			error = err;
		}

		dispatch(candidatesRequestEnd());

		if (error) {
			return dispatch(candidatesLoadError(error));
		}

		dispatch(candidatesLoaded({ candidates: response?.data }));
	};
};

export const loadCandidate = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadCandidate, no id!');
			return dispatch(candidatesLoadError({ message: 'no id found to load candidate with' }));
		}
		console.log('loadCandidate loadCandidate loadCandidate loadCandidate ');
		console.log(id);
		const state = getState().candidates;
		if (state.isRequesting) {
			// console.log('getCandidates: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadCandidate(id);
			};
			dispatch(queueDeferredAction('candidates', deferred));
			return;
		}
		dispatch(candidatesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=candidates&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(candidatesRequestEnd());

		if (error) {
			return dispatch(candidatesLoadError(error));
		}

		console.log('loadCandidate loadCandidate loadCandidate loadCandidate ');
		console.log(response);

		dispatch(candidatesLoaded({ candidates: [response?.data] }));
	};
};

export const setSelectedCandidateId = id => ({
	type: SET_SELECTED_CANDIDATE_ID,
	payload: { id }
});

export const addCandidate = data => {
	return async (dispatch, getState) => {
		const state = getState().candidates;
		if (state.isRequesting) {
			const deferred = () => {
				addCandidate(data);
			};
			dispatch(queueDeferredAction('candidates', deferred));
			return;
		}
		dispatch(candidatesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=candidates', { data });
		} catch (err) {
			error = err;
		}

		console.log('addCandidate, response');
		console.log(response);

		dispatch(candidatesRequestEnd());

		if (error) {
			dispatch(candidateSaveError(responses.CANDIDATE_SAVE_ERROR));
			return dispatch(candidatesAddError(error));
		}

		dispatch(candidateSaved(responses.CANDIDATE_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addCandidateLocalRecord(data));
		}
	};
};

export const updateCandidate = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateCandidate, no id!');
			return dispatch(
				candidatesUpdateError({
					message: 'no id found to update candidate with'
				})
			);
		}
		const state = getState().candidates;
		if (state.isRequesting) {
			const deferred = () => {
				updateCandidate(id, data);
			};
			dispatch(queueDeferredAction('candidates', deferred));
			return;
		}
		dispatch(candidatesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=candidates&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(candidatesRequestEnd());

		console.log('updateCandidate ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(candidateSaveError(responses.CANDIDATE_SAVE_ERROR));
			return dispatch(candidatesUpdateError(error));
		}

		dispatch(candidateSaved(responses.CANDIDATE_SAVED));
		dispatch(updateCandidateLocalRecord(id, data));
	};
};

export const deleteCandidate = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteCandidate, no id!');
			return false; // dispatch(candidatesUpdateError({ message: 'no id found to update candidate with' }));
		}
		const state = getState().candidates;
		if (state.isRequesting) {
			const deferred = () => {
				deleteCandidate(id);
			};
			dispatch(queueDeferredAction('candidates', deferred));
			return;
		}
		dispatch(candidatesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=candidates&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(candidatesRequestEnd());

		console.log('deleteCandidate ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(candidateDeleteError(responses.CANDIDATE_DELETE_ERROR));
			// return dispatch(candidatesUpdateError(error));
		}

		dispatch(candidateDeleted(responses.CANDIDATE_DELETED));
		dispatch(dropCandidateLocalRecord(id));
	};
};

export const addCandidateLocalRecord = data => ({
	type: CANDIDATES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateCandidateLocalRecord = (id, data) => ({
	type: CANDIDATES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const candidatesLoaded = ({ candidates }) => ({
	type: CANDIDATES_LOADED,
	payload: { candidates }
});

export const candidateSaved = responseMsg => ({
	type: CANDIDATE_SAVED,
	payload: responseMsg
});

export const candidateSaveError = responseMsg => ({
	type: CANDIDATE_SAVE_ERROR,
	payload: responseMsg
});

export const dropCandidateLocalRecord = id => ({
	type: CANDIDATES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const candidateDeleted = responseMsg => ({
	type: CANDIDATE_DELETED,
	payload: responseMsg
});

export const candidateDeleteError = responseMsg => ({
	type: CANDIDATE_DELETE_ERROR,
	payload: responseMsg
});

export const candidateClearSaveResult = () => ({
	type: CANDIDATE_SAVE_RESULT_CLEAR
});

export const candidateClearDeleteResult = () => ({
	type: CANDIDATE_DELETE_RESULT_CLEAR
});

export const candidatesLoadError = error => ({
	type: CANDIDATES_LOAD_ERROR,
	payload: { error }
});

export const candidatesAddError = error => ({
	type: CANDIDATES_ADD_ERROR,
	payload: { error }
});

export const candidatesUpdateError = error => ({
	type: CANDIDATES_UPDATE_ERROR,
	payload: { error }
});

export const candidatesRequestEnd = () => ({
	type: CANDIDATES_REQUEST_END,
	payload: false
});

export const candidatesRequestStart = () => ({
	type: CANDIDATES_REQUEST_START,
	payload: true
});
