/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
// import styled from 'styled-components';
import { Button, Spinner, Flex, Box } from 'theme-ui';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import useJackpotResults from '../../../hooks/useJackpotResults';
import useRaces from '../../../hooks/useRaces';
import useCars from '../../../hooks/useCars';
// import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { ViewTable } from '../../ThemeUiStyled';
import { showDate } from '../../../utils/datasource';
import PickRandomBall from './PickRandomBall';
import EditorSaveButton from '../../segments/EditorSaveButton';
import { objectSort } from '../../../utils';
/*
BALLS: (darker)
	background: rgb(180,221,180);
	background: -moz-linear-gradient(-45deg,  rgba(180,221,180,1) 0%, rgba(131,199,131,1) 16%, rgba(82,177,82,1) 23%, rgba(0,138,0,1) 57%, rgba(0,87,0,1) 83%, rgba(0,36,0,1) 100%);
	background: -webkit-linear-gradient(-45deg,  rgba(180,221,180,1) 0%,rgba(131,199,131,1) 16%,rgba(82,177,82,1) 23%,rgba(0,138,0,1) 57%,rgba(0,87,0,1) 83%,rgba(0,36,0,1) 100%);
	background: linear-gradient(135deg,  rgba(180,221,180,1) 0%,rgba(131,199,131,1) 16%,rgba(82,177,82,1) 23%,rgba(0,138,0,1) 57%,rgba(0,87,0,1) 83%,rgba(0,36,0,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ddb4', endColorstr='#002400',GradientType=1 );		
BUTTON: (lighter)
	background: rgb(248,255,232);
	background: -moz-linear-gradient(-45deg,  rgba(248,255,232,1) 0%, rgba(227,245,171,1) 33%, rgba(183,223,45,1) 100%);
	background: -webkit-linear-gradient(-45deg,  rgba(248,255,232,1) 0%,rgba(227,245,171,1) 33%,rgba(183,223,45,1) 100%);
	background: linear-gradient(135deg,  rgba(248,255,232,1) 0%,rgba(227,245,171,1) 33%,rgba(183,223,45,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8ffe8', endColorstr='#b7df2d',GradientType=1 );
*/

const sizeBase = 120;
const getSize = factor => Math.round(sizeBase * factor) + 'px';
export const pickBallSx = {
	width: getSize(1),
	height: getSize(1),
	margin: '10px',
	background:
		'linear-gradient(135deg,  rgba(180,221,180,1) 0%,rgba(131,199,131,1) 16%,rgba(82,177,82,1) 23%,rgba(0,138,0,1) 57%,rgba(0,87,0,1) 83%,rgba(0,36,0,1) 100%)',
	borderRadius: getSize(0.5),
	lineHeight: getSize(1),
	fontSize: getSize(0.5),
	textAlign: 'center',
	fontWeight: 'bold',
	color: 'white',
	textShadow: '4px 4px ' + getSize(0.2) + ' black, 4px 4px ' + getSize(0.1) + ' black',
	boxShadow: '8px 8px ' + getSize(0.1) + ' black'
};

export const greenActionButtonSx = {
	fontSize: '48px',
	mb: '10px',
	borderRadius: '24px',
	color: 'rgba(0,87,0,1)',
	background:
		'linear-gradient(135deg,  rgba(248,255,232,1) 0%,rgba(227,245,171,1) 33%,rgba(183,223,45,1) 100%)'
};

const PickWinners = props => {
	const { data, error } = props;
	const {
		selectedJackpotResultId,
		getJackpotResult,
		saveJackpotResult,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useJackpotResults();
	const history = useHistory();

	const { getRace, currentRace } = useRaces();

	const { theme } = useThemeUI();
	const { getCars, cars /*error: carError, isProcessing: isProcessingCars*/ } = useCars();
	const [hasRequestedCars, setHasRequestedCars] = useState(false);
	const [hasLoadedData, setHasLoadedData] = useState(false);
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedJackpotResultId) {
			getJackpotResult(selectedJackpotResultId);
		}
	}, [data, selectedJackpotResultId, getJackpotResult]);

	useEffect(() => {
		if (!hasRequestedCars) {
			setHasRequestedCars(true);
			getCars();
		}
	}, [hasRequestedCars, getCars, setHasRequestedCars]);

	useEffect(() => {
		if (!hasLoadedData && data && !localData) {
			setHasLoadedData(true);
			setLocalData(data);
		}
	}, [data, hasLoadedData, localData]);

	useEffect(() => {
		if (data?.race && currentRace?._id !== data.race) {
			getRace(data.race);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRace?._id, data, data?.race, getRace]);

	const updatePicks = data => {
		const { place, pick } = data;
		const myLocalData = { ...localData };
		myLocalData['randomPick' + place] = pick;
		setLocalData(myLocalData);
	};

	const saveWinners = e => {
		e.preventDefault();
		const hasPicks = topFourSlots || localData?.selectedNumbers;
		const myLocalData = { ...localData };
		if (hasPicks && currentRace) {
			if (!data?.selectedNumbers) {
				const { randomPick5, randomPick6 } = myLocalData;
				if (randomPick5 && randomPick6) {
					// ok, good to save draw now
					myLocalData.selectedNumbers = [...topFourSlots, randomPick5, randomPick6];
					const raceResults = [];
					Object.keys(currentRace).forEach(oKey => {
						const placeMatch = oKey.match(/slot_(\d{1,2})_place/);
						if (placeMatch?.length === 2) {
							const place = parseInt(placeMatch[1]);
							raceResults.push({
								place,
								car: currentRace[oKey],
								number: topFourSlots[place - 1] || null
							});
						}
					});
					myLocalData.raceResultsTopFour = raceResults
						.sort(objectSort('place'))
						.slice(0, 4);
					myLocalData.isLocked = true;
					delete myLocalData.randomPick5;
					delete myLocalData.randomPick6;
					// return setTimeout(resetRandomDraw, 2000);
				}
			} else {
				myLocalData.isLocked = true;
			}
			if (myLocalData.isLocked) {
				setLocalData(myLocalData);
				return saveJackpotResult(selectedJackpotResultId, myLocalData);
			}
		}
		console.error('missing topfour! or other stuff');
		console.log(hasPicks, currentRace);
		console.log(localData);
		console.log(myLocalData);
	};

	const resetRandomDraw = e => {
		console.log('resetRandomDraw');
		e && e.preventDefault();
		setLocalData({});
	};

	const setRaceResults = e => {
		e && e.preventDefault();
		if (data.race) {
			history.push(`/data/races/edit/${data.race}`);
		}
	};

	const setRace = e => {
		e && e.preventDefault();
		if (data.race) {
			history.push(`/data/jackpot-results/edit/${data._id}`);
		}
	};

	const topFourSlots = useMemo(() => {
		if (currentRace && cars?.length) {
			const { slot_1_place, slot_2_place, slot_3_place, slot_4_place } = currentRace;
			if (slot_1_place && slot_2_place && slot_3_place && slot_4_place) {
				const top4 = {
					place1: cars.find(C => C._id === slot_1_place),
					place2: cars.find(C => C._id === slot_2_place),
					place3: cars.find(C => C._id === slot_3_place),
					place4: cars.find(C => C._id === slot_4_place)
				};
				// FIXME: verify we have all cars
				return [
					top4.place1.number,
					top4.place2.number,
					top4.place3.number,
					top4.place4.number
				];
			}
		}
		return null;
	}, [currentRace, cars]);

	const pickedSlots = useMemo(() => {
		if (topFourSlots) {
			const slots = [...topFourSlots];
			if (localData?.randomPick5) {
				slots.push(localData?.randomPick5);
			}
			if (localData?.randomPick6) {
				slots.push(localData?.randomPick6);
			}
			return slots;
		}
		return null;
	}, [localData, topFourSlots]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading jackpotResult....</p>
			</>
		);
	}

	const pick5 = localData?.selectedNumbers?.[4] || localData?.randomPick5;
	const pick6 = localData?.selectedNumbers?.[5] || localData?.randomPick6;

	return (
		<div>
			<h2>Jackpot Pick Winners!</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Date: </td>
						<td>{showDate(data.jackpotDate || 'none')}</td>
					</tr>
					<tr>
						<td>Race: </td>
						<td>{currentRace?.name || 'no race found'}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			<h2>Picks:</h2>
			{topFourSlots ? (
				<Flex sx={{ justifyContent: 'center' }}>
					<Box>
						<Box sx={pickBallSx}>{topFourSlots[0]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{topFourSlots[1]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{topFourSlots[2]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{topFourSlots[3]}</Box>
					</Box>
					<Box
						sx={{
							width:
								parseInt(pickBallSx.width) + parseInt(pickBallSx.margin) * 2 + 'px'
						}}>
						{pick5 ? (
							<Box sx={pickBallSx}>{pick5}</Box>
						) : (
							<PickRandomBall
								size={parseInt(pickBallSx.width)}
								place={5}
								pickedSlots={pickedSlots}
								maxSlots={36}
								setPick={updatePicks}
							/>
						)}
					</Box>
					<Box
						sx={{
							width:
								parseInt(pickBallSx.width) + parseInt(pickBallSx.margin) * 2 + 'px'
						}}>
						{pick6 ? (
							<Box sx={pickBallSx}>{pick6}</Box>
						) : (
							<PickRandomBall
								size={parseInt(pickBallSx.width)}
								place={6}
								pickedSlots={pickedSlots}
								maxSlots={36}
								setPick={updatePicks}
							/>
						)}
					</Box>
				</Flex>
			) : data.race ? (
				<Box>
					<h3>Before completing the Jackpot random draw, race results need to be set.</h3>
					<Button
						sx={{
							...greenActionButtonSx,
							margin: '0 auto',
							display: 'block',
							cursor: 'pointer'
						}}
						onClick={setRaceResults}>
						Click here to set race results
					</Button>
				</Box>
			) : (
				<Box>
					<h3>This Jackpot Draw does not have a race linked yet.</h3>
					<Button
						sx={{
							...greenActionButtonSx,
							margin: '0 auto',
							display: 'block',
							cursor: 'pointer'
						}}
						onClick={setRace}>
						Click here to choose a race
					</Button>
				</Box>
			)}
			<UiStatusMessageDisplay
				message={uiStatusMessageSaveResult}
				consumed={uiStatusSaveMessageConsumed}
			/>
			{!localData?.isLocked && pick5 && pick6 && (
				<Box sx={{ mt: '20px' }}>
					<EditorSaveButton
						isProcessing={isProcessing}
						component={
							<Button
								sx={{
									...greenActionButtonSx,
									margin: '0 auto',
									display: 'block',
									cursor: 'pointer'
								}}
								onClick={saveWinners}>
								Save Winners
							</Button>
						}
					/>
					<br />
					<Button onClick={resetRandomDraw}>Reset Random Draw</Button>
				</Box>
			)}
			{/*
			<pre>
				{JSON.stringify(data, null, 4)}
				{JSON.stringify(currentRace, null, 4)}
			</pre>
			*/}
		</div>
	);
};

export default PickWinners;
