// import { getDrivers } from "./actions/drivers";
// import { getRaces } from "./actions/races";

const bootstrapData = dispatch => {
	// dispatch(getDrivers());
	// dispatch(getRaces());
	console.error('nothing to bootstrap');
};

export default bootstrapData;
