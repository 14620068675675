/** @jsx jsx */
import { Box, jsx, Styled, Button, Input, Label, Spinner } from 'theme-ui';
import React, { useMemo, useState } from 'react';
import useComms from '../../../hooks/useComms';
import RichTextEditor from '../../segments/RichTextEditor';
import { getOnFieldUpdate } from '../../../utils/datasource';
import { getEmailFromUser } from '../../datasources/Users/List';

const SendCustomEmailMessage = props => {
	const { user } = props;
	const {
		sendCustomEmailMessage,
		emailMessageSendResponse,
		emailMessageSendResponseError,
		isProcessing
	} = useComms();

	const [localData, setLocalData] = useState({});
	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const updateLocalEditorState = content => {
		onFieldUpdate({
			target: {
				name: 'messageBody',
				value: content
			}
		});
	};

	const handleForm = e => {
		e.preventDefault();
		if (localData.messageBody && localData.subject) {
			sendCustomEmailMessage(user._id, localData.messageBody, localData.subject);
		}
	};

	const disabledSendButton = useMemo(() => {
		return !(localData?.messageBody && localData?.subject);
	}, [localData]);

	if (!user) {
		return <Styled.h3>Please select a user before sending a custom email message.</Styled.h3>;
	}

	return (
		<Box as='form' onSubmit={handleForm}>
			<Styled.h4>
				Send Custom Email Message to {user.name} ({getEmailFromUser(user)})
			</Styled.h4>

			<Label htmlFor='subject'>Subject</Label>
			<Input
				name='subject'
				mb={3}
				value={localData?.subject || ''}
				onChange={onFieldUpdate}
			/>

			<Box>
				<RichTextEditor setRichTextContent={updateLocalEditorState} />
			</Box>

			<Box sx={{ mt: '20px' }}>
				{!isProcessing ? (
					<Button disabled={disabledSendButton}>Send Email</Button>
				) : (
					<Spinner />
				)}
			</Box>

			{emailMessageSendResponse && <p>{JSON.stringify(emailMessageSendResponse)}</p>}
			{emailMessageSendResponseError && (
				<p sx={{ color: 'red' }}>{JSON.stringify(emailMessageSendResponseError)}</p>
			)}
		</Box>
	);
};

export default SendCustomEmailMessage;
