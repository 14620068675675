import { useState } from 'react';
import axios from 'axios';

const useImages = () => {
	const [imageData, setImageData] = useState(null);
	const [imageLoadError, setImageLoadError] = useState(null);

	const getImage = async id => {
		if (!id) {
			return setImageLoadError('no ID, cannot load any image without an id');
		}
		try {
			const result = await axios.get('/api/get?oType=images&id=' + id);
			return setImageData(result?.data);
		} catch (error) {
			return setImageLoadError(error.toString());
		}
	};

	const clearImage = () => {
		setImageData();
		setImageLoadError();
	};

	return {
		getImage,
		clearImage,
		imageData,
		imageLoadError
	};
};

export default useImages;
