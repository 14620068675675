/** @jsx jsx */
import { jsx, Label, Box } from 'theme-ui';
import React, { useMemo } from 'react';
import EventSetup from '../EventSetup';
import SelectOne from 'components/segments/SelectOne';

export const voteContestCategoryTypes = {
	ALL: 'ALL',
	SELECTED: 'SELECTED'
};

export const voteContestCategoryTypeOptions = [
	{ name: 'All Candidates', _id: 'ALL' },
	{ name: 'Selected', _id: 'SELECTED' },
];

const VoteContestSetup = props => {
	const { localData, onFieldUpdate } = props;

	const onVoteContestSetupUpdate = e => {
		const { name, value } = e?.target || {};
		const {categoryCount, categories = []} = localData || {};
		if (!categoryCount) {
			return;
		}
		let extra;
		// this is auto-matching, used by race setup to auto-link a driver to a car
		// we don't have this in vote contest categories, disabling auto-select...
		// also, voteContestCategoryTypes is not really set up to do this, it's a type list not list-of-objects

		// if (name.indexOf('name') && value) {
		// 	const slotMatch = name.match(/(\d+)/);
		// 	const slotNumber = slotMatch.length && slotMatch[0];
		// 	const categoryType = slotNumber && localData[`category_${slotNumber}_type`];
		// 	if (!categoryType) {
		// 		const myCategory = voteContestCategoryTypes.find(C => C.value === value);
		// 		if (myCategory?.categoryType) {
		// 			extra = {
		// 				[`category_${slotNumber}_type`]: myCategory.categoryType
		// 			};
		// 		}
		// 	}
		// }

		// instead of auto-matching, we're going to generate a custom field type:
		// categories: [{name, type}]
		// well, maybe later.... let's just do it messy for now.
		// const myCategories = [];
		// for (let i = 1; i <= categoryCount; i += 1) {
		// 	// set up the node by each `category_${i}_name`
		// }

		onFieldUpdate(e, extra);
	};

	const setupData = useMemo(() => {
		if (localData?.categoryCount && voteContestCategoryTypeOptions?.length) {
			const stack = [];
			stack.push({
				data: localData?.categories,
				type: 'string-input', // instead of default SelectInput
				label: 'catName',
				fieldnameGenerator: i => `category_${i}_name`
			});
			stack.push({
				data: voteContestCategoryTypeOptions,
				label: 'catType',
				fieldnameGenerator: i => `category_${i}_type`
			});
			return stack;
		}
		return null;
	}, [localData]);

	// const rosterLockedRow = (stack, i, locked, eventData) => {
	// 	if (locked) {
	// 		const myCar = cars.find(C => C._id === eventData[`slot_${i}_car`]) || {
	// 			name: 'unknown car'
	// 		};
	// 		const myDriver = drivers.find(C => C._id === eventData[`slot_${i}_driver`]) || {
	// 			name: 'unknown driver'
	// 		};
	// 		stack.push(
	// 			<p key={i} sx={{ m: 0 }}>
	// 				<strong>Slot {i}:</strong> {myCar.name}, {myDriver.name}
	// 			</p>
	// 		);
	// 	}
	// };

	return (
		<Box>
			{!localData?.lockRoster && (
				<Label htmlFor='categoryCount'>
					How many voting categories in the contest?
					<SelectOne
						range={[1, 50]}
						value={localData?.categoryCount || 0}
						name='categoryCount'
						onFieldUpdate={onFieldUpdate}
						_sx={{
							width: '100px',
							display: 'inline',
							marginLeft: '20px',
							height: '40px'
						}}
					/>
				</Label>
			)}
			<h4 sx={{m: 0, p: 0}}>Category Name / Type</h4>
			{localData?.categoryCount && (
			<p sx={{m: 0, p: 0, fontSize: '12px'}}><em>"All Candidates" = all candidates are eligible
				<br />"Selected" = only candidates placed in this category are eligible</em></p>
				)}
			<EventSetup
				eventData={localData}
				setupData={setupData}
				slotCount={localData?.categoryCount}
				showSlotTypeLabel={false}
				setupHeaderLabel='Vote Categories:'
				onSetupUpdate={onVoteContestSetupUpdate}
				onFieldUpdate={onFieldUpdate}
				lockable={false}
			/>
		</Box>
	);
};

export default VoteContestSetup;
