import React, { useState } from 'react';
// import {DateRangeInput, DateSingleInput, Datepicker} from '@datepicker-react/styled';
import { DateSingleInput } from '@datepicker-react/styled';

const DatePicker = props => {
	const { value, name, closeOnPick, onFieldUpdate } = props;

	const [showPicker, setShowPicker] = useState(false);

	const setDate = ({ date }) => {
		onFieldUpdate({ target: { name, value: date } });
		if (closeOnPick) {
			setShowPicker(false);
		}
	};

	const focusPicker = show => {
		setShowPicker(show);
	};

	const enforceDateObjectDate = value => {
		if (value) {
			if (!(value instanceof Date)) {
				return new Date(value);
			}
		}
		return value;
	};

	return (
		<DateSingleInput
			onDateChange={setDate}
			onFocusChange={focusPicker}
			date={enforceDateObjectDate(value)}
			showDatepicker={showPicker}
		/>
	);
};

DatePicker.defaultProps = {
	closeOnPick: true
};

export default DatePicker;
