import _ from 'lodash';

import {
	SPONSORS_LOADED,
	SPONSORS_LOAD_ERROR,
	SPONSORS_REQUEST_END,
	SPONSORS_REQUEST_START,
	SPONSORS_ADD_LOCAL_RECORD,
	SPONSORS_UPDATE_LOCAL_RECORD,
	SPONSOR_DELETED,
	SPONSOR_DELETE_ERROR,
	SPONSOR_DELETE_RESULT_CLEAR,
	SPONSORS_DROP_LOCAL_RECORD,
	SPONSOR_SAVED,
	SPONSOR_SAVE_ERROR,
	SPONSOR_SAVE_RESULT_CLEAR,
	SET_SELECTED_SPONSOR_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedSponsorId: null,
	currentSponsor: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { sponsors, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case SPONSORS_LOADED:
			// console.warn(SPONSORS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { sponsors } = action.payload;
			const currentSponsors = [..._state.list];
			if (sponsors?.length) {
				sponsors.forEach(T => {
					if (!currentSponsors.find(CT => CT._id === T._id)) {
						currentSponsors.push(T);
					}
				});
			}
			// console.warn('currentSponsors');
			// console.log(currentSponsors);
			let currentSponsor;
			if (state.selectedSponsorId) {
				const selectedSponsor = currentSponsors.find(
					D => D._id === state.selectedSponsorId
				);
				if (selectedSponsor) {
					currentSponsor = selectedSponsor;
				}
			}
			return {
				...state,
				loadError: null,
				currentSponsor,
				list: currentSponsors
			};

		case SPONSORS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(SPONSORS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_SPONSOR_ID:
			return {
				...state,
				selectedSponsorId: action.payload?.id,
				currentSponsor:
					state.currentSponsor?._id === action.payload?.id ? state.currentSponsor : null
			};

		case SPONSORS_UPDATE_LOCAL_RECORD:
			const oldSponsor = state.list.find(D => D._id === id) || {};
			const newSponsor = _.merge(oldSponsor, data);
			return {
				...state,
				currentSponsor:
					state.currentSponsor?._id === id ? newSponsor : state.currentSponsor,
				list: state.list.map(D => {
					if (D._id === id) {
						return newSponsor;
					}
					return D;
				})
			};

		case SPONSORS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case SPONSORS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentSponsor: state.currentSponsor?._id === id ? null : state.currentSponsor,
				list: state.list.filter(D => D._id !== id)
			};

		case SPONSORS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case SPONSORS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case SPONSOR_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case SPONSOR_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case SPONSOR_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case SPONSOR_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case SPONSOR_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case SPONSOR_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
