import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getCharities as _getCharities,
	setSelectedCharityId,
	loadCharity,
	addCharity,
	updateCharity,
	deleteCharity as _deleteCharity,
	charitySaveError,
	charityClearSaveResult,
	charityClearDeleteResult
} from '../redux/actions/charities';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';
import { PROFESSIONAL_ENTITY_TYPES } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useCharities = () => {
	const charities = useSelector(state => state.charities?.list, shallowEqual);
	const currentCharity = useSelector(state => state.charities?.currentCharity);
	const selectedCharityId = useSelector(state => state.charities?.selectedCharityId);
	const error = useSelector(state => state.charities?.loadError);
	const saveResult = useSelector(state => state.charities?.saveResult);
	const deleteResult = useSelector(state => state.charities?.deleteResult);
	const isProcessing = useSelector(state => state.charities?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);

	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.charity && data.car;
	};

	const getCharities = useCallback(() => {
		dispatch(_getCharities());
	}, [dispatch]);

	const getCharity = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedCharityId(id));
				dispatch(loadCharity({ id }));
			}
		},
		[dispatch]
	);

	const selectCharity = useCallback(
		id => {
			dispatch(setSelectedCharityId(id));
		},
		[dispatch]
	);

	const deleteCharity = useCallback(
		id => {
			dispatch(_deleteCharity(id));
		},
		[dispatch]
	);

	const saveCharity = useCallback(
		(id, data) => {
			if (validateData(data)) {
				data.type = PROFESSIONAL_ENTITY_TYPES.CHARITY;
				if (id) {
					return dispatch(updateCharity(id, data));
				}
				return dispatch(addCharity(data));
			}
			dispatch(charitySaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(charityClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(charityClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getCharities,
		getCharity,
		saveCharity,
		selectCharity,
		deleteCharity,
		isProcessing,
		currentCharity,
		selectedCharityId,
		charities,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useCharities;
