import _ from 'lodash';

import {
	RACERESULTS_LOADED,
	RACERESULTS_LOAD_ERROR,
	RACERESULTS_REQUEST_END,
	RACERESULTS_REQUEST_START,
	RACERESULTS_ADD_LOCAL_RECORD,
	RACERESULTS_UPDATE_LOCAL_RECORD,
	RACERESULT_DELETED,
	RACERESULT_DELETE_ERROR,
	RACERESULT_DELETE_RESULT_CLEAR,
	RACERESULTS_DROP_LOCAL_RECORD,
	RACERESULT_SAVED,
	RACERESULT_SAVE_ERROR,
	RACERESULT_SAVE_RESULT_CLEAR,
	SET_SELECTED_RACERESULT_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedRaceResultId: null,
	currentRaceResult: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { raceResults, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case RACERESULTS_LOADED:
			// console.warn(RACERESULTS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { raceResults } = action.payload;
			const currentRaceResults = [..._state.list];
			if (raceResults?.length) {
				raceResults.forEach(T => {
					if (!currentRaceResults.find(CT => CT._id === T._id)) {
						currentRaceResults.push(T);
					}
				});
			}
			// console.warn('currentRaceResults');
			// console.log(currentRaceResults);
			let currentRaceResult;
			if (state.selectedRaceResultId) {
				const selectedRaceResult = currentRaceResults.find(
					D => D._id === state.selectedRaceResultId
				);
				if (selectedRaceResult) {
					currentRaceResult = selectedRaceResult;
				}
			}
			return {
				...state,
				loadError: null,
				currentRaceResult,
				list: currentRaceResults
			};

		case RACERESULTS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(RACERESULTS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_RACERESULT_ID:
			return {
				...state,
				selectedRaceResultId: action.payload?.id,
				currentRaceResult:
					state.currentRaceResult?._id === action.payload?.id
						? state.currentRaceResult
						: null
			};

		case RACERESULTS_UPDATE_LOCAL_RECORD:
			const oldRaceResult = state.list.find(D => D._id === id) || {};
			const newRaceResult = _.merge(oldRaceResult, data);
			return {
				...state,
				currentRaceResult:
					state.currentRaceResult?._id === id ? newRaceResult : state.currentRaceResult,
				list: state.list.map(D => {
					if (D._id === id) {
						return newRaceResult;
					}
					return D;
				})
			};

		case RACERESULTS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case RACERESULTS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentRaceResult:
					state.currentRaceResult?._id === id ? null : state.currentRaceResult,
				list: state.list.filter(D => D._id !== id)
			};

		case RACERESULTS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case RACERESULTS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case RACERESULT_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case RACERESULT_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case RACERESULT_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case RACERESULT_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case RACERESULT_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case RACERESULT_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
