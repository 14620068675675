import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getEntries as _getEntries,
	setSelectedEntryId,
	loadEntry,
	addEntry,
	updateEntry,
	deleteEntry as _deleteEntry,
	entrySaveError,
	entryClearSaveResult,
	entryClearDeleteResult
} from '../redux/actions/entries';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useEntries = ({ event }) => {
	const entries = useSelector(state => state.entries?.list, shallowEqual);
	const currentEntry = useSelector(state => state.entries?.currentEntry);
	const selectedEntryId = useSelector(state => state.entries?.selectedEntryId);
	const error = useSelector(state => state.entries?.loadError);
	const saveResult = useSelector(state => state.entries?.saveResult);
	const deleteResult = useSelector(state => state.entries?.deleteResult);
	const isProcessing = useSelector(state => state.entries?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.entry && data.car;
	};

	const getEntries = useCallback(() => {
		dispatch(_getEntries({ event }));
	}, [dispatch, event]);

	const getEntry = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedEntryId(id));
				dispatch(loadEntry({ id, event }));
			}
		},
		[dispatch, event]
	);

	const selectEntry = useCallback(
		id => {
			dispatch(setSelectedEntryId(id));
		},
		[dispatch]
	);

	const deleteEntry = useCallback(
		id => {
			dispatch(_deleteEntry(id));
		},
		[dispatch]
	);

	const saveEntry = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateEntry(id, data));
				}
				return dispatch(addEntry(data));
			}
			dispatch(entrySaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(entryClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(entryClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getEntries,
		getEntry,
		saveEntry,
		selectEntry,
		deleteEntry,
		isProcessing,
		currentEntry,
		selectedEntryId,
		entries,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useEntries;
