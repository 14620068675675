/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useRaces from '../../../hooks/useRaces';
import useLocations from '../../../hooks/useLocations';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { showDate } from '../../../utils/datasource';
import { ListTable } from '../../ThemeUiStyled';

const RacesList = props => {
	const { data, error } = props;
	const {
		selectRace,
		deleteRace,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useRaces();
	const {
		getLocations,
		locations,
		error: locationError,
		isProcessing: isProcessingLocations
	} = useLocations();

	const { theme } = useThemeUI();

	const history = useHistory();
	const [raceToDelete, setRaceToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);

	useEffect(() => {
		if (!hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [hasRequestedLocations, getLocations, setHasRequestedLocations]);

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting races....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectRace(item._id);
		history.push('/data/races/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectRace(item._id);
		history.push('/data/races/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setRaceToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setRaceToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		raceToDelete && deleteRace(raceToDelete._id);
		setRaceToDelete(null);
		setShowDeleteModal(false);
	};

	const showLocationName = location => {
		if (locations) {
			const myLocation = locations.find(L => L._id === location);
			return myLocation?.name || location;
		}
		return location;
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '5px 8px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='race'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Race</td>
						<td>Location</td>
						<td>Date</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{item.name}</td>
								<td>{showLocationName(item.location || 'none')}</td>
								<td>{showDate(item.raceDate || 'none')}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default RacesList;
