/** @jsx jsx */
import { Box, jsx, Styled } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import useQuery from '../../../hooks/useQuery';
import useUsers from '../../../hooks/useUsers';
import useComms from '../../../hooks/useComms';
import SendCustomEmailMessage from './SendCustomEmailMessage';
import SendAghWelcomeEmail from './SendAghWelcomeEmail';
// import './SendEmail.scss';

// import useCommsHub from '../../../hooks/useCommsHub';
// import { cleanDataMethod } from '../../../utils/datasource';

const TempInstructions = () => (
	<Box>
		<Styled.h3>Temporary usage:</Styled.h3>
		<Styled.p>First version, we are linking from user pages.</Styled.p>
		<Styled.ol>
			<Styled.li>Go to the Users list</Styled.li>
			<Styled.li>"View" a User</Styled.li>
			<Styled.li>Use email buttons from there to start</Styled.li>
		</Styled.ol>
	</Box>
);

const SendEmailTemplate = () => (
	<Box>
		<Styled.h1>temp placeholder component: SendEmailTemplate</Styled.h1>
	</Box>
);

const staticEmailTemplates = {
	welcomeEmail: SendAghWelcomeEmail
};

const SendEmail = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const queryVars = useQuery();
	const { oMethod /* = 'list'*/, _id = null } = params;
	const userId = queryVars.get('user');
	const templateId = queryVars.get('template');
	const staticTemplateId = queryVars.get('staticTemplate');
	const customMessage = queryVars.get('customMessage');

	const { getUser, currentUser } = useUsers();
	const { getEmailTemplate, currentEmailTemplate } = useComms();

	useEffect(() => {
		const { _id } = currentUser || {};
		if (userId && (_id !== userId || !currentUser)) {
			getUser(userId);
		}
	}, [currentUser, getUser, userId]);

	useEffect(() => {
		const { _id } = currentEmailTemplate || {};
		if (templateId && (_id !== templateId || !currentEmailTemplate)) {
			getEmailTemplate(templateId);
		}
	}, [currentEmailTemplate, getEmailTemplate, templateId]);

	// const { getCommsHub, commsHub, currentCommsHub, selectedCommsHubId, selectCommsHub, error } = useCommsHub();
	// const [hasRequestedCommsHub, setHasRequestedCommsHub] = useState(false);
	// const [myCommsHubId, setMyCommsHubId] = useState(selectedCommsHubId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		// setMethod(cleanDataMethod(oMethod));
		setMethod(oMethod);
	}, [oMethod]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	const { oType } = useParams();
	const StaticTemplate = staticEmailTemplates[staticTemplateId];

	return (
		<Box>
			<h2
				sx={{
					mb: '0.25em'
				}}>
				Send Email
			</h2>
			{!currentUser && <TempInstructions />}
			{currentEmailTemplate && (
				<SendEmailTemplate user={currentUser} template={currentEmailTemplate} />
			)}

			{StaticTemplate && <StaticTemplate user={currentUser} />}

			{customMessage && <SendCustomEmailMessage user={currentUser} />}

			<Box>
				<hr />
				<p sx={{ fontSize: '0.7em' }}>user: {userId}</p>
				<p sx={{ fontSize: '0.7em' }}>template: {templateId}</p>
				<p sx={{ fontSize: '0.7em' }}>currentUser: {JSON.stringify(currentUser)}</p>
			</Box>
		</Box>
	);
};

export default SendEmail;
