import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useArticles from '../../../hooks/useArticles';
import { ViewTable } from '../../ThemeUiStyled';

const ArticlesView = props => {
	const { data, error } = props;
	const { selectedArticleId, getArticle } = useArticles();

	console.log('ArticlesView');
	console.log(selectedArticleId, data, error);

	useEffect(() => {
		if (!data && selectedArticleId) {
			console.log('getArticle!!!!' + selectedArticleId);
			getArticle(selectedArticleId);
		}
	}, [data, selectedArticleId, getArticle]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading article....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Article:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.article}</td>
					</tr>
					<tr>
						<td>Car: </td>
						<td>{data.car}</td>
					</tr>
					<tr>
						<td>Brand: </td>
						<td>{data.brand?.name || 'none'}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
		</div>
	);
};

export default ArticlesView;
