/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner, Textarea, useThemeUI } from 'theme-ui';
import useCharities from '../../../hooks/useCharities';
import AddressFields from '../../segments/AddressFields';
import EditorSaveButton from '../../segments/EditorSaveButton';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { Devnote, EditorContainer } from '../../ThemeUiStyled';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const CharitiesEdit = props => {
	const { data, error } = props;
	const {
		selectedCharityId,
		getCharity,
		saveCharity,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useCharities();
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedCharityId) {
			getCharity(selectedCharityId);
		} else if (data?._id !== selectedCharityId) {
			getCharity(selectedCharityId);
		}
	}, [data, selectedCharityId, getCharity]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveCharity(selectedCharityId, localData);
		getCharity(selectedCharityId);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading charity....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Charity:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Charity Org</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='website'>Website</Label>
							<Input
								name='website'
								mb={3}
								value={localData?.website || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='contact'>Contact Person</Label>
							<Input
								name='contact'
								mb={3}
								value={localData?.contact || ''}
								onChange={onFieldUpdate}
							/>
							<Devnote>this will eventually link to an internal user</Devnote>
							<AddressFields onFieldUpdate={onFieldUpdate} data={localData} />
							<EditorSaveButton isProcessing={isProcessing} label='Charity' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='description'>Description</Label>
							<Textarea
								rows={4}
								name='description'
								mb={3}
								value={localData?.description || ''}
								onChange={onFieldUpdate}
							/>
							<h3>Charity Logo:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default CharitiesEdit;
