import _ from 'lodash';

import {
	LOCATIONS_LOADED,
	LOCATIONS_LOAD_ERROR,
	LOCATIONS_REQUEST_END,
	LOCATIONS_REQUEST_START,
	LOCATIONS_ADD_LOCAL_RECORD,
	LOCATIONS_UPDATE_LOCAL_RECORD,
	LOCATION_DELETED,
	LOCATION_DELETE_ERROR,
	LOCATION_DELETE_RESULT_CLEAR,
	LOCATIONS_DROP_LOCAL_RECORD,
	LOCATION_SAVED,
	LOCATION_SAVE_ERROR,
	LOCATION_SAVE_RESULT_CLEAR,
	SET_SELECTED_LOCATION_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedLocationId: null,
	currentLocation: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { locations, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case LOCATIONS_LOADED:
			// console.warn(LOCATIONS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { locations } = action.payload;
			const currentLocations = [..._state.list];
			if (locations?.length) {
				locations.forEach(T => {
					if (!currentLocations.find(CT => CT._id === T._id)) {
						currentLocations.push(T);
					}
				});
			}
			// console.warn('currentLocations');
			// console.log(currentLocations);
			let currentLocation;
			if (state.selectedLocationId) {
				const selectedLocation = currentLocations.find(
					D => D._id === state.selectedLocationId
				);
				if (selectedLocation) {
					currentLocation = selectedLocation;
				}
			}
			return {
				...state,
				loadError: null,
				currentLocation,
				list: currentLocations
			};

		case LOCATIONS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(LOCATIONS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_LOCATION_ID:
			return {
				...state,
				selectedLocationId: action.payload?.id,
				currentLocation:
					state.currentLocation?._id === action.payload?.id ? state.currentLocation : null
			};

		case LOCATIONS_UPDATE_LOCAL_RECORD:
			const oldLocation = state.list.find(D => D._id === id) || {};
			const newLocation = _.merge(oldLocation, data);
			return {
				...state,
				currentLocation:
					state.currentLocation?._id === id ? newLocation : state.currentLocation,
				list: state.list.map(D => {
					if (D._id === id) {
						return newLocation;
					}
					return D;
				})
			};

		case LOCATIONS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case LOCATIONS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentLocation: state.currentLocation?._id === id ? null : state.currentLocation,
				list: state.list.filter(D => D._id !== id)
			};

		case LOCATIONS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case LOCATIONS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case LOCATION_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case LOCATION_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case LOCATION_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case LOCATION_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case LOCATION_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case LOCATION_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
