import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getAdmins as _getAdmins,
	setSelectedAdminId,
	loadAdmin,
	addAdmin,
	updateAdmin,
	deleteAdmin as _deleteAdmin,
	adminSaveError,
	adminClearSaveResult,
	adminClearDeleteResult
} from '../redux/actions/admins';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useAdmins = () => {
	const admins = useSelector(state => state.admins?.list, shallowEqual);
	const currentAdmin = useSelector(state => state.admins?.currentAdmin);
	const selectedAdminId = useSelector(state => state.admins?.selectedAdminId);
	const error = useSelector(state => state.admins?.loadError);
	const saveResult = useSelector(state => state.admins?.saveResult);
	const deleteResult = useSelector(state => state.admins?.deleteResult);
	const isProcessing = useSelector(state => state.admins?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.admin && data.car;
	};

	const getAdmins = useCallback(() => {
		dispatch(_getAdmins());
	}, [dispatch]);

	const getAdmin = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedAdminId(id));
				dispatch(loadAdmin({ id }));
			}
		},
		[dispatch]
	);

	const selectAdmin = useCallback(
		id => {
			dispatch(setSelectedAdminId(id));
		},
		[dispatch]
	);

	const deleteAdmin = useCallback(
		id => {
			dispatch(_deleteAdmin(id));
		},
		[dispatch]
	);

	const saveAdmin = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateAdmin(id, data));
				}
				return dispatch(addAdmin(data));
			}
			dispatch(adminSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(adminClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(adminClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getAdmins,
		getAdmin,
		saveAdmin,
		selectAdmin,
		deleteAdmin,
		isProcessing,
		currentAdmin,
		selectedAdminId,
		admins,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useAdmins;
