import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	VOTECONTESTS_ADD_ERROR,
	VOTECONTESTS_LOADED,
	VOTECONTESTS_LOAD_ERROR,
	VOTECONTESTS_REQUEST_END,
	VOTECONTESTS_REQUEST_START,
	VOTECONTESTS_UPDATE_ERROR,
	VOTECONTESTS_ADD_LOCAL_RECORD,
	VOTECONTESTS_UPDATE_LOCAL_RECORD,
	VOTECONTESTS_DROP_LOCAL_RECORD,
	VOTECONTEST_DELETED,
	VOTECONTEST_DELETE_ERROR,
	VOTECONTEST_DELETE_RESULT_CLEAR,
	VOTECONTEST_SAVED,
	VOTECONTEST_SAVE_ERROR,
	VOTECONTEST_SAVE_RESULT_CLEAR,
	SET_SELECTED_VOTECONTEST_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * VoteContest Actions
 */

export const getVoteContests = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().voteContests;
		if (state.isRequesting) {
			// console.log('getVoteContests: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getVoteContests(ids, options);
			};
			dispatch(queueDeferredAction('voteContests', deferred));
			return;
		}
		dispatch(voteContestsRequestStart());
		let response;
		let error;
		const params = {
			oType: 'vote-contests'
		};
		if (options.sort) {
			const sortList = [];
			Object.keys(options.sort).forEach(K => {
				sortList.push(K + '.' + options.sort[K]);
			});
			if (sortList.length) {
				params.sort = sortList.join(',');
			}
		}
		try {
			response = await axios.get('/api/list', { params });
		} catch (err) {
			error = err;
		}

		dispatch(voteContestsRequestEnd());

		if (error) {
			return dispatch(voteContestsLoadError(error));
		}

		dispatch(voteContestsLoaded({ voteContests: response?.data }));
	};
};

export const loadVoteContest = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadVoteContest, no id!');
			return dispatch(voteContestsLoadError({ message: 'no id found to load voteContest with' }));
		}
		console.log('loadVoteContest loadVoteContest loadVoteContest loadVoteContest ');
		console.log(id);
		const state = getState().voteContests;
		if (state.isRequesting) {
			// console.log('getVoteContests: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadVoteContest({ id }, options);
			};
			dispatch(queueDeferredAction('voteContests', deferred));
			return;
		}
		dispatch(voteContestsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=vote-contests&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(voteContestsRequestEnd());

		if (error) {
			return dispatch(voteContestsLoadError(error));
		}

		console.log('loadVoteContest loadVoteContest loadVoteContest loadVoteContest ');
		console.log(response);

		dispatch(voteContestsLoaded({ voteContests: [response?.data] }));
	};
};

export const setSelectedVoteContestId = id => ({
	type: SET_SELECTED_VOTECONTEST_ID,
	payload: { id }
});

export const addVoteContest = data => {
	return async (dispatch, getState) => {
		const state = getState().voteContests;
		if (state.isRequesting) {
			const deferred = () => {
				addVoteContest(data);
			};
			dispatch(queueDeferredAction('voteContests', deferred));
			return;
		}
		dispatch(voteContestsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=vote-contests', { data });
		} catch (err) {
			error = err;
		}

		console.log('addVoteContest, response');
		console.log(response);

		dispatch(voteContestsRequestEnd());

		if (error) {
			dispatch(voteContestSaveError(responses.VOTECONTEST_SAVE_ERROR));
			return dispatch(voteContestsAddError(error));
		}

		dispatch(voteContestSaved(responses.VOTECONTEST_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addVoteContestLocalRecord(data));
		}
	};
};

export const updateVoteContest = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateVoteContest, no id!');
			return dispatch(voteContestsUpdateError({ message: 'no id found to update voteContest with' }));
		}
		const state = getState().voteContests;
		if (state.isRequesting) {
			const deferred = () => {
				updateVoteContest(id, data);
			};
			dispatch(queueDeferredAction('voteContests', deferred));
			return;
		}
		dispatch(voteContestsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=vote-contests&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(voteContestsRequestEnd());

		console.log('updateVoteContest ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(voteContestSaveError(responses.VOTECONTEST_SAVE_ERROR));
			return dispatch(voteContestsUpdateError(error));
		}

		dispatch(voteContestSaved(responses.VOTECONTEST_SAVED));
		dispatch(updateVoteContestLocalRecord(id, data));
	};
};

export const deleteVoteContest = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteVoteContest, no id!');
			return false; // dispatch(voteContestsUpdateError({ message: 'no id found to update voteContest with' }));
		}
		const state = getState().voteContests;
		if (state.isRequesting) {
			const deferred = () => {
				deleteVoteContest(id);
			};
			dispatch(queueDeferredAction('voteContests', deferred));
			return;
		}
		dispatch(voteContestsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=vote-contests&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(voteContestsRequestEnd());

		console.log('deleteVoteContest ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(voteContestDeleteError(responses.VOTECONTEST_DELETE_ERROR));
			// return dispatch(voteContestsUpdateError(error));
		}

		dispatch(voteContestDeleted(responses.VOTECONTEST_DELETED));
		dispatch(dropVoteContestLocalRecord(id));
	};
};

export const addVoteContestLocalRecord = data => ({
	type: VOTECONTESTS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateVoteContestLocalRecord = (id, data) => ({
	type: VOTECONTESTS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const voteContestsLoaded = ({ voteContests }) => ({
	type: VOTECONTESTS_LOADED,
	payload: { voteContests }
});

export const voteContestSaved = responseMsg => ({
	type: VOTECONTEST_SAVED,
	payload: responseMsg
});

export const voteContestSaveError = responseMsg => ({
	type: VOTECONTEST_SAVE_ERROR,
	payload: responseMsg
});

export const dropVoteContestLocalRecord = id => ({
	type: VOTECONTESTS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const voteContestDeleted = responseMsg => ({
	type: VOTECONTEST_DELETED,
	payload: responseMsg
});

export const voteContestDeleteError = responseMsg => ({
	type: VOTECONTEST_DELETE_ERROR,
	payload: responseMsg
});

export const voteContestClearSaveResult = () => ({
	type: VOTECONTEST_SAVE_RESULT_CLEAR
});

export const voteContestClearDeleteResult = () => ({
	type: VOTECONTEST_DELETE_RESULT_CLEAR
});

export const voteContestsLoadError = error => ({
	type: VOTECONTESTS_LOAD_ERROR,
	payload: { error }
});

export const voteContestsAddError = error => ({
	type: VOTECONTESTS_ADD_ERROR,
	payload: { error }
});

export const voteContestsUpdateError = error => ({
	type: VOTECONTESTS_UPDATE_ERROR,
	payload: { error }
});

export const voteContestsRequestEnd = () => ({
	type: VOTECONTESTS_REQUEST_END,
	payload: false
});

export const voteContestsRequestStart = () => ({
	type: VOTECONTESTS_REQUEST_START,
	payload: true
});
