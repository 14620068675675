import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner, Flex, Box } from 'theme-ui';
import useCandidates from '../../../hooks/useCandidates';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const CandidatesView = props => {
	const { data, error } = props;
	const { selectedCandidateId, getCandidate } = useCandidates();

	console.log('CandidatesView');
	console.log(selectedCandidateId, data, error);

	useEffect(() => {
		if (!data && selectedCandidateId) {
			console.log('getCandidate!!!!' + selectedCandidateId);
			getCandidate(selectedCandidateId);
		}
	}, [data, selectedCandidateId, getCandidate]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading candidate....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Candidate:</h2>
			<Flex>
				<Box style={{flex: 0.5}}>
					<ViewTable>
						<tbody>
							<tr>
								<td>Candidate: </td>
								<td>{data.name}</td>
							</tr>
							<tr>
								<td>Title: </td>
								<td>{data.title}</td>
							</tr>
							<tr>
								<td>Group / Segment: </td>
								<td>{data.candidateGroup}</td>
							</tr>
							<tr>
								<td>General Location: </td>
								<td>{data.location}</td>
							</tr>
							{/*
							<tr>
								<td>Age / Birthday: </td>
								<td>{data.birthday?.split('T').shift()}</td>
							</tr>
							<tr>
								<td>Email: </td>
								<td>{data.email}</td>
							</tr>
							<tr>
								<td>City: </td>
								<td>{data.city}</td>
							</tr>
							<tr>
								<td>State / Province: </td>
								<td>{data.state}</td>
							</tr>
							<tr>
								<td>Country: </td>
								<td>{data.country}</td>
							</tr>
							*/}
						</tbody>
						<colgroup>
							<col style={{ width: '120px' }}></col>
							<col style={{ width: '320px' }}></col>
						</colgroup>
					</ViewTable>
				</Box>
				<Box style={{flex: 0.5}}>
					<h3>Candidate Image:</h3>
					{data.imageId ? <div style={{width: '200px'}}><ImageDisplayer id={data.imageId} /></div> : (<p>no image found</p>)}
					<h3>Eligible Voting Categories:</h3>
					<p><em>(TBD: show the categories here, only People's Choice Award)</em></p>
				</Box>
			</Flex>
		</div>
	);
};

export default CandidatesView;
