/**
 * jackpotUi
 *
 * works with useJackpotUi hook
 *
 * shareable state for jackpot data
 *      - currentCard chosen by user, for differrent components
 *      - detailView show/hide for modal use etc
 */

import {
	JACKPOT_UI_HIDE_DETAIL_VIEW,
	JACKPOT_UI_SHOW_DETAIL_VIEW,
	JACKPOT_UI_SET_CURRENT_CARD,
	JACKPOT_UI_CLEAR_CURRENT_CARD
} from '../actions/actionTypes';

const initialState = {
	currentJackpotCard: null,
	showDetailView: false
};

export default function (state = initialState, action) {
	const _state = { ...state };
	switch (action.type) {
		case JACKPOT_UI_HIDE_DETAIL_VIEW: {
			console.log('JACKPOT_UI_HIDE_DETAIL_VIEW');
			return {
				..._state,
				showDetailView: false
			};
		}
		case JACKPOT_UI_SHOW_DETAIL_VIEW: {
			console.log('JACKPOT_UI_SHOW_DETAIL_VIEW');
			return {
				..._state,
				showDetailView: true
			};
		}
		case JACKPOT_UI_SET_CURRENT_CARD: {
			console.log('JACKPOT_UI_SET_CURRENT_CARD');
			const { card } = action.payload;
			console.log(action);
			return {
				..._state,
				currentJackpotCard: card
			};
		}
		case JACKPOT_UI_CLEAR_CURRENT_CARD: {
			console.log('JACKPOT_UI_CLEAR_CURRENT_CARD');
			return {
				..._state,
				currentJackpotCard: null
			};
		}
		default:
			return state;
	}
}
