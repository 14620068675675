import axios from 'axios';
// import { queueDeferredAction } from './';
import {
	USER_ACTION_REQUEST_END,
	USER_ACTION_REQUEST_START,
	USER_ACTION_ERROR,
	USER_REQUEST_END,
	USER_REQUEST_START,
	USER_LOADED,
	USER_LOGOUT,
	USER_REFRESH,
	USER_LOAD_ERROR
} from './actionTypes';

/**
 * User Actions
 */

export const userLoaded = ({ user }) => ({
	type: USER_LOADED,
	payload: { user }
});

export const userLogout = () => ({
	type: USER_LOGOUT
});

export const userRefreshed = ({ user }) => ({
	type: USER_REFRESH,
	payload: { user }
});

export const userLoadError = error => ({
	type: USER_LOAD_ERROR,
	payload: { error }
});

export const userRequestEnd = () => ({
	type: USER_REQUEST_END,
	payload: false
});

export const userRequestStart = () => ({
	type: USER_REQUEST_START,
	payload: true
});

export const userActionRequestEnd = () => ({
	type: USER_ACTION_REQUEST_END,
	payload: false
});

export const userActionRequestStart = () => ({
	type: USER_ACTION_REQUEST_START,
	payload: true
});

export const userActionError = error => ({
	type: USER_ACTION_ERROR,
	payload: { error }
});

export const login = (email, password) => {
	return async (dispatch, getState) => {
		const state = getState().user;
		if (state.isAuthorized && state.user) {
			return false;
		}
		if (state.isRequesting) {
			return false;
		}
		dispatch(userRequestStart());

		let response;
		let error;
		try {
			response = await axios.post('/auth/user/login', { email, password });
		} catch (err) {
			error = err;
		}

		console.log('login response + error');
		console.log(response?.data);
		console.log(error);

		dispatch(userRequestEnd());

		if (error) {
			return dispatch(userLoadError(error));
		}

		const { user, authorized } = response?.data;

		if (authorized && user) {
			dispatch(userLoaded({ user }));
		} else {
			dispatch(userLoadError('no user found after authorization'));
		}
	};
};

export const logout = () => {
	return async (dispatch, getState) => {
		const state = getState().user;
		if (!state.isAuthorized || !state.user) {
			return false;
		}
		if (state.isRequesting) {
			return false;
		}
		dispatch(userRequestStart());

		// eslint-disable-next-line no-unused-vars
		let response;
		let error;
		try {
			response = await axios.post('/auth/user/logout');
		} catch (err) {
			error = err;
		}

		dispatch(userRequestEnd());
		dispatch(userLogout());

		if (error) {
			console.error('error logging out!');
			console.log(error);
			return dispatch(userLoadError(error));
		}
	};
};

export const refresh = () => {
	return async (dispatch, getState) => {
		console.log('user::refresh');
		const state = getState().user;
		if (state.isAuthorized || state.user) {
			return false;
		}
		if (state.isRequesting) {
			return false;
		}
		dispatch(userRequestStart());

		let response;
		let error;
		try {
			response = await axios.post('/auth/user/refresh');
		} catch (err) {
			error = err;
		}

		dispatch(userRequestEnd());

		if (error) {
			return dispatch(userLoadError(error));
		}

		const { user, authorized } = response?.data;

		if (authorized && user) {
			dispatch(userRefreshed({ user }));
		} else {
			dispatch(userLoadError('no user found after authorization'));
		}
	};
};
