/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Text } from 'theme-ui'

import useRaces from '../../../hooks/useRaces';

const ActionButtons = props => {
	const { method } = props;
	const { theme } = useThemeUI();

	const {
		// races,
		// currentRace,
		selectedRaceId,
		error
	} = useRaces();

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<div
			sx={{
				mb: '1.5em'
			}}>
			{method !== 'list' && (
				<Link sx={linkSx} to='/data/races'>
					Back to Races List
				</Link>
			)}
			{['add', 'edit', 'delete'].indexOf(method) === -1 && (
				<Link sx={linkSx} to='/data/races/add'>
					+ Add a Race
				</Link>
			)}
			{method === 'view' && selectedRaceId && (
				<>
					<Link
						sx={{
							...linkSx,
							color: theme.colors.text,
							fontWeight: 'bold'
						}}
						to={'/data/races/edit/' + selectedRaceId}>
						Edit this Race
					</Link>
					<Link sx={linkSx} to={'/data/races/delete/' + selectedRaceId}>
						-- DELETE Race --
					</Link>
				</>
			)}
		</div>
	);
};

export default ActionButtons;
