/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useVoteContests from '../../../hooks/useVoteContests';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const VoteContests = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const { getVoteContests, voteContests, currentVoteContest, selectedVoteContestId, selectVoteContest, error } = useVoteContests();

	const [hasRequestedVoteContests, setHasRequestedVoteContests] = useState(false);
	const [myVoteContestId, setMyVoteContestId] = useState(selectedVoteContestId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedVoteContests) {
			setHasRequestedVoteContests(true);
			getVoteContests();
		}
	}, [method, hasRequestedVoteContests, getVoteContests]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentVoteContest, method: ' + method);
		if (['view', 'edit'].indexOf(method) > -1 && (!currentVoteContest || currentVoteContest._id !== _id)) {
			console.log('set it! ' + _id);
			selectVoteContest(_id);
			setMyVoteContestId(_id);
		}
	}, [selectedVoteContestId, _id, currentVoteContest, method, selectVoteContest]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage VoteContests
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={voteContests} error={error} />}
			{method === 'view' && <View data={currentVoteContest} error={error} />}
			{method === 'edit' && <Edit data={currentVoteContest} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default VoteContests;
