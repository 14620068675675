/** @jsx jsx */
import { Box, jsx, Styled, Button, Input, Label, Spinner } from 'theme-ui';
// import React, { useMemo, useState } from 'react';
import useComms from '../../../hooks/useComms';
// import RichTextEditor from '../../segments/RichTextEditor';
// import { getOnFieldUpdate } from '../../../utils/datasource';
import { getEmailFromUser } from '../../datasources/Users/List';

const STATIC_TEMPLATE_NAME = 'AghWelcomeEmail';

const SendAghWelcomeEmail = props => {
	const { user } = props;
	const {
		sendStaticTemplateEmailMessage,
		emailMessageSendResponse,
		emailMessageSendResponseError,
		isProcessing
	} = useComms();

	const handleClick = e => {
		e.preventDefault();
		sendStaticTemplateEmailMessage(user._id, STATIC_TEMPLATE_NAME);
	};

	if (!user) {
		return <Styled.h3>Please select a user before sending a custom email message.</Styled.h3>;
	}

	return (
		<Box>
			<Styled.h4>
				Send the AGH Signup Welcome Email to {user.name} ({getEmailFromUser(user)})
			</Styled.h4>

			<Box sx={{ mt: '20px' }}>
				{!isProcessing ? (
					<Button onClick={handleClick}>Send Welcome Message</Button>
				) : (
					<Spinner />
				)}
			</Box>

			{emailMessageSendResponse && <p>{JSON.stringify(emailMessageSendResponse)}</p>}
			{emailMessageSendResponseError && (
				<p sx={{ color: 'red' }}>{JSON.stringify(emailMessageSendResponseError)}</p>
			)}
		</Box>
	);
};

export default SendAghWelcomeEmail;
