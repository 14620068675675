import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useDonationMatrixes from '../../../hooks/useDonationMatrixes';
import { showDate } from '../../../utils/datasource';
import { ViewTable } from '../../ThemeUiStyled';

const MatrixRow = styled.div`
	margin: 0 0 0.5em;
	padding: 0;
`;

const DonationMatrixesView = props => {
	const { data, error } = props;
	const { selectedDonationMatrixId, getDonationMatrix } = useDonationMatrixes();

	console.log('DonationMatrixesView');
	console.log(selectedDonationMatrixId, data, error);

	useEffect(() => {
		if (!data && selectedDonationMatrixId) {
			console.log('getDonationMatrix!!!!' + selectedDonationMatrixId);
			getDonationMatrix(selectedDonationMatrixId);
		}
	}, [data, selectedDonationMatrixId, getDonationMatrix]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading donationMatrix....</p>
			</>
		);
	}

	return (
		<div>
			<h2>DonationMatrix:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Start Date: </td>
						<td>{showDate(data.startDate)}</td>
					</tr>
					<tr>
						<td>Matrix: </td>
						<td>
							{data.matrixes.map((item, idx) => {
								return (
									<MatrixRow key={idx}>
										${item.amount}: {item.jackpotCredits} Jackpot Credits,{' '}
										{item.raffleTickets} Raffles
									</MatrixRow>
								);
							})}
						</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
		</div>
	);
};

export default DonationMatrixesView;
