import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

const composedEnhancer = composeWithDevTools(
	applyMiddleware(thunkMiddleware) //, logger)
);

export default createStore(rootReducer, composedEnhancer);
