/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Spinner, Styled } from 'theme-ui';
import usePrizeDraws from '../../../hooks/usePrizeDraws';
import useRafflePrizes from '../../../hooks/useRafflePrizes';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { showDate } from '../../../utils/datasource';
import { ListTable } from '../../ThemeUiStyled';

const PrizeDrawsList = props => {
	const { data, error } = props;
	const {
		selectPrizeDraw,
		deletePrizeDraw,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = usePrizeDraws();
	const {
		getRafflePrizes,
		rafflePrizes,
		error: rafflePrizeError,
		isProcessing: isProcessingRafflePrizes
	} = useRafflePrizes();

	const history = useHistory();

	const { theme } = useThemeUI();

	const [prizeDrawToDelete, setPrizeDrawToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [hasRequestedRafflePrizes, setHasRequestedRafflePrizes] = useState(false);

	useEffect(() => {
		if (!hasRequestedRafflePrizes) {
			setHasRequestedRafflePrizes(true);
			getRafflePrizes();
		}
	}, [hasRequestedRafflePrizes, getRafflePrizes, setHasRequestedRafflePrizes]);

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting prizeDraws....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectPrizeDraw(item._id);
		history.push('/data/prize-draws/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectPrizeDraw(item._id);
		history.push('/data/prize-draws/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setPrizeDrawToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setPrizeDrawToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		prizeDrawToDelete && deletePrizeDraw(prizeDrawToDelete._id);
		setPrizeDrawToDelete(null);
		setShowDeleteModal(false);
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '5px 8px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const showRafflePrizeName = prize => {
		if (rafflePrizes) {
			const myRafflePrize = rafflePrizes.find(L => L._id === prize);
			return myRafflePrize?.name || prize;
		}
		return prize;
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='prizeDraw'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Grand Prize Draw</td>
						<td>Prize</td>
						<td>Date</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{item.name}</td>
								<td>{showRafflePrizeName(item.rafflePrize || 'non')}</td>
								<td>{showDate(item.prizeDrawDate || 'none')}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default PrizeDrawsList;
