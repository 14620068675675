/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import usePrizeDraws from '../../../hooks/usePrizeDraws';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const PrizeDraws = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const {
		getPrizeDraws,
		prizeDraws,
		currentPrizeDraw,
		selectedPrizeDrawId,
		selectPrizeDraw,
		error
	} = usePrizeDraws();

	const [hasRequestedPrizeDraws, setHasRequestedPrizeDraws] = useState(false);
	const [myPrizeDrawId, setMyPrizeDrawId] = useState(selectedPrizeDrawId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedPrizeDraws) {
			setHasRequestedPrizeDraws(true);
			getPrizeDraws();
		}
	}, [method, hasRequestedPrizeDraws, getPrizeDraws]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentPrizeDraw, method: ' + method);
		if (
			['view', 'edit'].indexOf(method) > -1 &&
			(!currentPrizeDraw || currentPrizeDraw._id !== _id)
		) {
			console.log('set it! ' + _id);
			selectPrizeDraw(_id);
			setMyPrizeDrawId(_id);
		}
	}, [selectedPrizeDrawId, _id, currentPrizeDraw, method, selectPrizeDraw]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage PrizeDraws
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={prizeDraws} error={error} />}
			{method === 'view' && <View data={currentPrizeDraw} error={error} />}
			{method === 'edit' && <Edit data={currentPrizeDraw} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default PrizeDraws;
