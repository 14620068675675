import _ from 'lodash';

import {
	CANDIDATES_LOADED,
	CANDIDATES_LOAD_ERROR,
	CANDIDATES_REQUEST_END,
	CANDIDATES_REQUEST_START,
	CANDIDATES_ADD_LOCAL_RECORD,
	CANDIDATES_UPDATE_LOCAL_RECORD,
	CANDIDATE_DELETED,
	CANDIDATE_DELETE_ERROR,
	CANDIDATE_DELETE_RESULT_CLEAR,
	CANDIDATES_DROP_LOCAL_RECORD,
	CANDIDATE_SAVED,
	CANDIDATE_SAVE_ERROR,
	CANDIDATE_SAVE_RESULT_CLEAR,
	SET_SELECTED_CANDIDATE_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedCandidateId: null,
	currentCandidate: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

const candidates = (state = initialState, action) => {
	const _state = { ...state };
	const { candidates, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case CANDIDATES_LOADED:
			// console.warn(CANDIDATES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { candidates } = action.payload;
			const currentCandidates = [..._state.list];
			if (candidates?.length) {
				candidates.forEach(T => {
					if (!currentCandidates.find(CT => CT._id === T._id)) {
						currentCandidates.push(T);
					}
				});
			}
			// console.warn('currentCandidates');
			// console.log(currentCandidates);
			let currentCandidate;
			if (state.selectedCandidateId) {
				const selectedCandidate = currentCandidates.find(D => D._id === state.selectedCandidateId);
				if (selectedCandidate) {
					currentCandidate = selectedCandidate;
				}
			}
			return {
				...state,
				loadError: null,
				currentCandidate,
				list: currentCandidates
			};

		case CANDIDATES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(CANDIDATES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_CANDIDATE_ID:
			return {
				...state,
				selectedCandidateId: action.payload?.id,
				currentCandidate:
					state.currentCandidate?._id === action.payload?.id ? state.currentCandidate : null
			};

		case CANDIDATES_UPDATE_LOCAL_RECORD:
			const oldCandidate = state.list.find(D => D._id === id) || {};
			const newCandidate = _.merge(oldCandidate, data);
			return {
				...state,
				currentCandidate: state.currentCandidate?._id === id ? newCandidate : state.currentCandidate,
				list: state.list.map(D => {
					if (D._id === id) {
						return newCandidate;
					}
					return D;
				})
			};

		case CANDIDATES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case CANDIDATES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentCandidate: state.currentCandidate?._id === id ? null : state.currentCandidate,
				list: state.list.filter(D => D._id !== id)
			};

		case CANDIDATES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case CANDIDATES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case CANDIDATE_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case CANDIDATE_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case CANDIDATE_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case CANDIDATE_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case CANDIDATE_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case CANDIDATE_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
};

export default candidates;