import _ from 'lodash';

import {
	RACES_LOADED,
	RACES_LOAD_ERROR,
	RACES_REQUEST_END,
	RACES_REQUEST_START,
	RACES_ADD_LOCAL_RECORD,
	RACES_UPDATE_LOCAL_RECORD,
	RACE_DELETED,
	RACE_DELETE_ERROR,
	RACE_DELETE_RESULT_CLEAR,
	RACES_DROP_LOCAL_RECORD,
	RACE_SAVED,
	RACE_SAVE_ERROR,
	RACE_SAVE_RESULT_CLEAR,
	SET_SELECTED_RACE_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedRaceId: null,
	currentRace: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

const races = function (state = initialState, action) {
	const _state = { ...state };
	const { races, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case RACES_LOADED:
			// console.warn(RACES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { races } = action.payload;
			const currentRaces = [..._state.list];
			if (races?.length) {
				races.forEach(T => {
					if (!currentRaces.find(CT => CT._id === T._id)) {
						currentRaces.push(T);
					}
				});
			}
			// console.warn('currentRaces');
			// console.log(currentRaces);
			let currentRace;
			if (state.selectedRaceId) {
				const selectedRace = currentRaces.find(D => D._id === state.selectedRaceId);
				if (selectedRace) {
					currentRace = selectedRace;
				}
			}
			return {
				...state,
				loadError: null,
				currentRace,
				list: currentRaces
			};

		case RACES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(RACES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_RACE_ID:
			return {
				...state,
				selectedRaceId: action.payload?.id,
				currentRace:
					state.currentRace?._id === action.payload?.id ? state.currentRace : null
			};

		case RACES_UPDATE_LOCAL_RECORD:
			const oldRace = state.list.find(D => D._id === id) || {};
			const newRace = _.merge(oldRace, data);
			return {
				...state,
				currentRace: state.currentRace?._id === id ? newRace : state.currentRace,
				list: state.list.map(D => {
					if (D._id === id) {
						return newRace;
					}
					return D;
				})
			};

		case RACES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case RACES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentRace: state.currentRace?._id === id ? null : state.currentRace,
				list: state.list.filter(D => D._id !== id)
			};

		case RACES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case RACES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case RACE_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case RACE_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case RACE_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case RACE_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case RACE_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case RACE_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
};

export default races;
