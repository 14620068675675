/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
	BrowserRouter as Router,
	Switch,
	Route
	// Link
} from 'react-router-dom';
import _ from 'lodash';
import { Provider } from 'react-redux';
import * as Themes from '@theme-ui/presets';
// import { MDXProvider } from '@mdx-js/react';
import { ThemeProvider } from 'theme-ui';
import { ModalProvider } from 'styled-react-modal';
import styled from 'styled-components';
import store from './redux/store';
import bootstrapData from './redux/bootstrapData';
import Homepage from './components/Homepage';
import UserAccountHome from './components/UserAccount/Home';
import UserAuth from './components/UserAccount/UserAuth';

import './App.scss';

// import logo from './assets/gfx/grateful-hearts-logo-small.png';
const app_logo = '/public/gfx/ultimus-esports-no-cafe-logo.png';
// const app_logo = 'https://ppihc.org/wp-content/uploads/2017/12/budweiser-logo-2016-768x249.jpg';

bootstrapData(store.dispatch);

console.log(Object.keys(Themes));

// const myTheme = Themes.deep;
const myTheme = Themes.tailwind;

const CHOSEN_THEME = _.merge(myTheme, {
	colors: {
		...myTheme.colors,
		uiSuccess: '#339900',
		uiFail: '#df0508'
	}
});
console.log('theme');
console.log(CHOSEN_THEME);

const SpecialModalBackground = styled.div`
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 30;
	opacity: 0.5;
	background-color: rgba(0, 0, 0, 0.1);
`;

const App = props => {
	// return (<h1>wtf</h1>);
	return (
		<Provider store={store}>
			<Router>
				<ThemeProvider theme={CHOSEN_THEME}>
					<ModalProvider backgroundComponent={SpecialModalBackground}>
						<header className='App-header'>
							<a href='/'>
								<img
									src={app_logo}
									className='App-logo'
									alt='logo'
									style={{
										maxWidth: '260px',
										height: 'auto'
									}}
								/>
							</a>
						</header>
						<UserAuth>
							<Switch>
								<Route exact path='/'>
									<Homepage />
								</Route>
								<Route path='/data/:oType/:oMethod?/:_id?'>
									<Homepage />
								</Route>
								<Route path='/tools/:oType/:oTool?/:oMethod?/:_id?'>
									<Homepage />
								</Route>
								<Route path='/account'>
									<UserAccountHome />
								</Route>
							</Switch>
						</UserAuth>
					</ModalProvider>
				</ThemeProvider>
			</Router>
		</Provider>
	);
};

export default App;
