import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getCars as _getCars,
	setSelectedCarId,
	loadCar,
	addCar,
	updateCar,
	deleteCar as _deleteCar,
	carSaveError,
	carClearSaveResult,
	carClearDeleteResult
} from '../redux/actions/cars';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useCars = () => {
	const cars = useSelector(state => state.cars?.list, shallowEqual);
	const currentCar = useSelector(state => state.cars?.currentCar);
	const selectedCarId = useSelector(state => state.cars?.selectedCarId);
	const error = useSelector(state => state.cars?.loadError);
	const saveResult = useSelector(state => state.cars?.saveResult);
	const deleteResult = useSelector(state => state.cars?.deleteResult);
	const isProcessing = useSelector(state => state.cars?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.car && data.car;
	};

	const getCars = useCallback(() => {
		dispatch(_getCars());
	}, [dispatch]);

	const getCar = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedCarId(id));
				dispatch(loadCar({ id }));
			}
		},
		[dispatch]
	);

	const selectCar = useCallback(
		id => {
			dispatch(setSelectedCarId(id));
		},
		[dispatch]
	);

	const deleteCar = useCallback(
		id => {
			dispatch(_deleteCar(id));
		},
		[dispatch]
	);

	const saveCar = useCallback(
		(id, data) => {
			if (validateData(data)) {
				data.number = parseInt(data.number);
				if (id) {
					return dispatch(updateCar(id, data));
				}
				return dispatch(addCar(data));
			}
			dispatch(carSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(carClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(carClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getCars,
		getCar,
		saveCar,
		selectCar,
		deleteCar,
		isProcessing,
		currentCar,
		selectedCarId,
		cars,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useCars;
