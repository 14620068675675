export const STANDARD_DATE_FORMAT = 'MMM D, YYYY';

// how long to time out a status message
export const UI_STATUS_MESSAGE_TIMEOUT = 5000;

export const PROFESSIONAL_ENTITY_TYPES = {
	BRAND: 'BRAND',
	CHARITY: 'CHARITY',
	SPONSOR: 'SPONSOR'
};

export const SPONSOR_TIERS = {
	// CHEAP: 'CHEAP',
	GOLD: 'GOLD',
	PLATINUM: 'PLATINUM',
	SILVER: 'SILVER'
};

export const MATRIX_TYPES = {
	DONATION: 'DONATION',
	JACKPOT: 'JACKPOT',
	// RAFFLE: 'RAFFLE',
	VOTE_CONTEST: 'VOTE_CONTEST'
};

export const RACETYPES = {
	GHA_JACKPOT: 'GHA_JACKPOT',
	QUALIFIER: 'QUALIFIER',
	FINAL: 'FINAL'
};

export const RACETYPES_LABELS = {
	GHA_JACKPOT: 'GHA Jackpot',
	QUALIFIER: 'Qualifier',
	FINAL: 'Final'
};

export const ARTICLETYPES = {
	PRESS_RELEASE: 'PRESS_RELEASE',
	NEWS: 'NEWS',
	BLOG: 'BLOG'
};

export const ARTICLETYPES_LABELS = {
	PRESS_RELEASE: 'Press Release',
	NEWS: 'News Article',
	BLOG: 'Blog'
};

export const COMMS_TYPES = {
	EMAIL_TEMPLATE: 'EMAIL_TEMPLATE'
};

export const EVENT_TYPES = {
	RACE: 'RACE',
	GAME: 'GAME',
	AGGREGATOR: 'AGGREGATOR'
};

export const TOURNAMENT_TYPES = {
	MIXED: 'MIXED', // thinking....
	PRESET1: 'PRESET1', // thinking.... likely just a named component/type instead of trying to programmatically combine types (for v1, watch for patterns)
	GHA_JACKPOT_RACE_DAY: 'GHA_JACKPOT_RACE_DAY'
};

export const VOTECONTESTTYPES = {
	BEAUTY_PAGEANT: 'BEAUTY_PAGEANT',
};

export const VOTECONTESTTYPES_LABELS = {
	BEAUTY_PAGEANT: 'Beauty Pageant',
};

