import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	DONATIONMATRIXES_ADD_ERROR,
	DONATIONMATRIXES_LOADED,
	DONATIONMATRIXES_LOAD_ERROR,
	DONATIONMATRIXES_REQUEST_END,
	DONATIONMATRIXES_REQUEST_START,
	DONATIONMATRIXES_UPDATE_ERROR,
	DONATIONMATRIXES_ADD_LOCAL_RECORD,
	DONATIONMATRIXES_UPDATE_LOCAL_RECORD,
	DONATIONMATRIXES_DROP_LOCAL_RECORD,
	DONATIONMATRIX_DELETED,
	DONATIONMATRIX_DELETE_ERROR,
	DONATIONMATRIX_DELETE_RESULT_CLEAR,
	DONATIONMATRIX_SAVED,
	DONATIONMATRIX_SAVE_ERROR,
	DONATIONMATRIX_SAVE_RESULT_CLEAR,
	SET_SELECTED_DONATIONMATRIX_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * DonationMatrix Actions
 */

export const getDonationMatrixes = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().donationMatrixes;
		if (state.isRequesting) {
			// console.log('getDonationMatrixes: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getDonationMatrixes(ids, options);
			};
			dispatch(queueDeferredAction('donationMatrixes', deferred));
			return;
		}
		dispatch(donationMatrixesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=donation-matrixes');
		} catch (err) {
			error = err;
		}

		dispatch(donationMatrixesRequestEnd());

		if (error) {
			return dispatch(donationMatrixesLoadError(error));
		}

		dispatch(donationMatrixesLoaded({ donationMatrixes: response?.data }));
	};
};

export const loadDonationMatrix = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadDonationMatrix, no id!');
			return dispatch(
				donationMatrixesLoadError({
					message: 'no id found to load donationMatrix with'
				})
			);
		}
		console.log('loadDonationMatrix loadDonationMatrix loadDonationMatrix loadDonationMatrix ');
		console.log(id);
		const state = getState().donationMatrixes;
		if (state.isRequesting) {
			// console.log('getDonationMatrixes: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadDonationMatrix(id);
			};
			dispatch(queueDeferredAction('donationMatrixes', deferred));
			return;
		}
		dispatch(donationMatrixesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=donation-matrixes&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(donationMatrixesRequestEnd());

		if (error) {
			return dispatch(donationMatrixesLoadError(error));
		}

		console.log('loadDonationMatrix loadDonationMatrix loadDonationMatrix loadDonationMatrix ');
		console.log(response);

		dispatch(donationMatrixesLoaded({ donationMatrixes: [response?.data] }));
	};
};

export const setSelectedDonationMatrixId = id => ({
	type: SET_SELECTED_DONATIONMATRIX_ID,
	payload: { id }
});

export const addDonationMatrix = data => {
	return async (dispatch, getState) => {
		const state = getState().donationMatrixes;
		if (state.isRequesting) {
			const deferred = () => {
				addDonationMatrix(data);
			};
			dispatch(queueDeferredAction('donationMatrixes', deferred));
			return;
		}
		dispatch(donationMatrixesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=donation-matrixes', {
				data
			});
		} catch (err) {
			error = err;
		}

		console.log('addDonationMatrix, response');
		console.log(response);

		dispatch(donationMatrixesRequestEnd());

		if (error) {
			dispatch(donationMatrixSaveError(responses.DONATIONMATRIX_SAVE_ERROR));
			return dispatch(donationMatrixesAddError(error));
		}

		dispatch(donationMatrixSaved(responses.DONATIONMATRIX_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addDonationMatrixLocalRecord(data));
		}
	};
};

export const updateDonationMatrix = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateDonationMatrix, no id!');
			return dispatch(
				donationMatrixesUpdateError({
					message: 'no id found to update donationMatrix with'
				})
			);
		}
		const state = getState().donationMatrixes;
		if (state.isRequesting) {
			const deferred = () => {
				updateDonationMatrix(id, data);
			};
			dispatch(queueDeferredAction('donationMatrixes', deferred));
			return;
		}
		dispatch(donationMatrixesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=donation-matrixes&id=' + id, { data });
		} catch (err) {
			error = err;
		}

		dispatch(donationMatrixesRequestEnd());

		console.log('updateDonationMatrix ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(donationMatrixSaveError(responses.DONATIONMATRIX_SAVE_ERROR));
			return dispatch(donationMatrixesUpdateError(error));
		}

		dispatch(donationMatrixSaved(responses.DONATIONMATRIX_SAVED));
		dispatch(updateDonationMatrixLocalRecord(id, data));
	};
};

export const deleteDonationMatrix = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteDonationMatrix, no id!');
			return false; // dispatch(donationMatrixesUpdateError({ message: 'no id found to update donationMatrix with' }));
		}
		const state = getState().donationMatrixes;
		if (state.isRequesting) {
			const deferred = () => {
				deleteDonationMatrix(id);
			};
			dispatch(queueDeferredAction('donationMatrixes', deferred));
			return;
		}
		dispatch(donationMatrixesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=donation-matrixes&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(donationMatrixesRequestEnd());

		console.log('deleteDonationMatrix ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(donationMatrixDeleteError(responses.DONATIONMATRIX_DELETE_ERROR));
			// return dispatch(donationMatrixesUpdateError(error));
		}

		dispatch(donationMatrixDeleted(responses.DONATIONMATRIX_DELETED));
		dispatch(dropDonationMatrixLocalRecord(id));
	};
};

export const addDonationMatrixLocalRecord = data => ({
	type: DONATIONMATRIXES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateDonationMatrixLocalRecord = (id, data) => ({
	type: DONATIONMATRIXES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const donationMatrixesLoaded = ({ donationMatrixes }) => ({
	type: DONATIONMATRIXES_LOADED,
	payload: { donationMatrixes }
});

export const donationMatrixSaved = responseMsg => ({
	type: DONATIONMATRIX_SAVED,
	payload: responseMsg
});

export const donationMatrixSaveError = responseMsg => ({
	type: DONATIONMATRIX_SAVE_ERROR,
	payload: responseMsg
});

export const dropDonationMatrixLocalRecord = id => ({
	type: DONATIONMATRIXES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const donationMatrixDeleted = responseMsg => ({
	type: DONATIONMATRIX_DELETED,
	payload: responseMsg
});

export const donationMatrixDeleteError = responseMsg => ({
	type: DONATIONMATRIX_DELETE_ERROR,
	payload: responseMsg
});

export const donationMatrixClearSaveResult = () => ({
	type: DONATIONMATRIX_SAVE_RESULT_CLEAR
});

export const donationMatrixClearDeleteResult = () => ({
	type: DONATIONMATRIX_DELETE_RESULT_CLEAR
});

export const donationMatrixesLoadError = error => ({
	type: DONATIONMATRIXES_LOAD_ERROR,
	payload: { error }
});

export const donationMatrixesAddError = error => ({
	type: DONATIONMATRIXES_ADD_ERROR,
	payload: { error }
});

export const donationMatrixesUpdateError = error => ({
	type: DONATIONMATRIXES_UPDATE_ERROR,
	payload: { error }
});

export const donationMatrixesRequestEnd = () => ({
	type: DONATIONMATRIXES_REQUEST_END,
	payload: false
});

export const donationMatrixesRequestStart = () => ({
	type: DONATIONMATRIXES_REQUEST_START,
	payload: true
});
