import { RACETYPES, RACETYPES_LABELS, VOTECONTESTTYPES, VOTECONTESTTYPES_LABELS } from './';

export const raceTypeOptions = Object.keys(RACETYPES).map(type => {
	return {
		name: RACETYPES_LABELS[type],
		// _id instead of value here, SelectOne handles it
		// otherwise you get the string name, not the CONSTANT_NAME
		_id: RACETYPES[type]
	};
});

export const voteContestTypeOptions = Object.keys(VOTECONTESTTYPES).map(type => {
	return {
		name: VOTECONTESTTYPES_LABELS[type],
		// _id instead of value here, SelectOne handles it
		// otherwise you get the string name, not the CONSTANT_NAME
		_id: VOTECONTESTTYPES[type]
	};
});
