/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useEntries from '../../../hooks/useEntries';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { ListTable } from '../../ThemeUiStyled';

const EntriesList = props => {
	const { data, error } = props;
	const {
		selectEntry,
		deleteEntry,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useEntries({ event: 'dress-a-like' });
	const history = useHistory();

	const [candidateToDelete, setEntryToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting entries....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectEntry(item._id);
		history.push('/data/dress-a-like-entries/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectEntry(item._id);
		history.push('/data/dress-a-like-entries/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setEntryToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		console.error('rejectDelete');
		setEntryToDelete(null);
		setShowDeleteModal(false);
		console.log('-- done');
	};

	const confirmDelete = () => {
		console.error('confirmDelete');
		setShowDeleteModal(false);
		candidateToDelete && deleteEntry(candidateToDelete._id);
		setEntryToDelete(null);
		console.log('-- done');
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '5px 8px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='candidate'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Entry</td>
						<td>Email</td>
						<td>Entry Date</td>
						<td>Entry Type</td>
						<td>Approved?</td>
						<td>Location</td>
						<td>Group ID</td>
						<td>Likes / Votes</td>
						<td>Dislikes</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{item.name}</td>
								<td>{item.email}</td>
								<td>{new Date(item.created).toUTCString()}</td>
								<td>
									{item.entry_photo
										? 'photo'
										: item.entry_video
										? 'video'
										: 'none! error'}
								</td>
								<td>{item.isApproved ? 'yes' : 'no'}</td>
								<td>
									{item.city}, {item.state}, {item.country}
								</td>
								<td>{item.groupId || 'none'}</td>
								<td>{item.likes || '0'}</td>
								<td>{item.dislikes || '0'}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default EntriesList;
