/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Flex, Box, Button, Input, Label, Spinner } from 'theme-ui';
import useRaces from '../../../hooks/useRaces';
import useLocations from '../../../hooks/useLocations';
import useCars from '../../../hooks/useCars';
import useDrivers from '../../../hooks/useDrivers';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';
// import { objectSort } from '../../../utils';
import { raceTypeOptions } from 'constants/eventUiHelpers';
import RaceSetup from 'components/tools/EventBuilders/SetupBuilders/RaceSetup';
import RaceResults from 'components/tools/EventBuilders/ResultBuilders/RaceResults';

const RacesEdit = props => {
	const { data, error } = props;
	const {
		selectedRaceId,
		getRace,
		saveRace,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useRaces();
	const {
		getLocations,
		locations,
		error: locationError,
		isProcessing: isProcessingLocations
	} = useLocations();
	const { getCars, cars, error: carError, isProcessing: isProcessingCars } = useCars();
	const {
		getDrivers,
		drivers,
		error: driverError,
		isProcessing: isProcessingDrivers
	} = useDrivers();

	const [localData, setLocalData] = useState(data);
	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);
	const [hasRequestedCars, setHasRequestedCars] = useState(false);
	const [hasRequestedDrivers, setHasRequestedDrivers] = useState(false);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	useEffect(() => {
		if (!hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [hasRequestedLocations, getLocations, setHasRequestedLocations]);

	useEffect(() => {
		if (!hasRequestedCars) {
			setHasRequestedCars(true);
			getCars();
		}
	}, [hasRequestedCars, getCars, setHasRequestedCars]);

	useEffect(() => {
		if (!hasRequestedDrivers) {
			setHasRequestedDrivers(true);
			getDrivers();
		}
	}, [hasRequestedDrivers, getDrivers, setHasRequestedDrivers]);

	useEffect(() => {
		if (!data && selectedRaceId) {
			getRace(selectedRaceId);
		} else if (data?._id !== selectedRaceId) {
			getRace(selectedRaceId);
		}
	}, [data, selectedRaceId, getRace]);

	const parseRaceDate = dateStamp => {
		if (/\d{4}\-\d{2}\-\d{2}/.test(dateStamp)) {
			const raceDate = new Date();
			const raceParts = dateStamp.split('T').shift().split('-');
			raceDate.setFullYear(raceParts[0]);
			raceDate.setMonth(raceParts[1] - 1);
			raceDate.setDate(raceParts[2]);
			// console.log('parseRaceDate', dateStamp, raceDate, raceParts, new Date(dateStamp));
			return raceDate;
		}
		throw new Error('bad date format, cannot continue');
	};

	useEffect(() => {
		if (!localData && data) {
			// set up race date for month picker
			// const raceDate = new Date();
			// const raceParts = data.raceDate.split('-');
			// raceDate.setFullYear(raceParts[0]);
			// raceDate.setMonth(raceParts[1] - 1);
			// raceDate.setDate(raceParts[2]);
			// data.raceDate = raceDate;
			data.raceDate = parseRaceDate(data.raceDate);
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const handleForm = e => {
		e.preventDefault();
		// console.log('save race!!!!');
		// console.log(localData);
		// const raceDate = new Date(localData.raceDate);
		// const timeParts = localData.time?.split(':') || ['19', '00']; // default race time is 19 hrs GMT
		// raceDate.setHours(timeParts[0]);
		// raceDate.setMinutes(timeParts[1]);
		const saveData = { ...localData };
		const raceDate = moment(localData.raceDate);
		saveData.raceDate = raceDate.format('YYYY-MM-DD');
		saveRace(selectedRaceId, saveData);
		localData.raceDate = parseRaceDate(saveData.raceDate);
		setLocalData(localData);
	};

	// calculate if all places are set, not in use yet
	// const placesAreSet = useMemo(
	// 	() => () => {
	// 		if (localData?.carCount && localData?.lockRoster) {
	// 			let places = 0;
	// 			// helps guard against doubles until child components are smarter
	// 			const placeTracker = {};
	// 			for (let i = 1; i <= localData.carCount; i += 1) {
	// 				if (localData[`slot_${i}_place`] > 0 && placeTracker[i]) {
	// 					places += 1;
	// 					placeTracker[i] = true;
	// 				}
	// 			}
	// 			return places === parseInt(localData.carCount);
	// 		}
	// 		return false;
	// 	},
	// 	[localData]
	// );

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading race....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Race:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Race</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='location'>Location</Label>
							<SelectOne
								data={locations}
								value={localData?.location}
								name='location'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No locations found to choose from.'
							/>
							<Label htmlFor='raceType'>Race Type</Label>
							<SelectOne
								data={raceTypeOptions}
								value={localData?.raceType}
								name='raceType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No race types found to choose from.'
							/>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='raceDate'>Date</Label>
									<DatePicker
										value={localData?.raceDate}
										name='raceDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto'
									}}>
									<Label htmlFor='time'>Time (GMT/24hr)</Label>
									<Input
										name='time'
										mb={3}
										value={localData?.time || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<h5>[ Add logo upload widget if we want one ]</h5>
							<EditorSaveButton isProcessing={isProcessing} label='Race' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Set up Cars + Drivers</h3>
							<RaceSetup
								cars={cars}
								drivers={drivers}
								localData={localData}
								onFieldUpdate={onFieldUpdate}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Race Results</h3>
							<RaceResults
								localData={localData}
								cars={cars}
								onFieldUpdate={onFieldUpdate}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default RacesEdit;
