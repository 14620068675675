import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getLocations as _getLocations,
	setSelectedLocationId,
	loadLocation,
	addLocation,
	updateLocation,
	deleteLocation as _deleteLocation,
	locationSaveError,
	locationClearSaveResult,
	locationClearDeleteResult
} from '../redux/actions/locations';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useLocations = () => {
	const locations = useSelector(state => state.locations?.list, shallowEqual);
	const currentLocation = useSelector(state => state.locations?.currentLocation);
	const selectedLocationId = useSelector(state => state.locations?.selectedLocationId);
	const error = useSelector(state => state.locations?.loadError);
	const saveResult = useSelector(state => state.locations?.saveResult);
	const deleteResult = useSelector(state => state.locations?.deleteResult);
	const isProcessing = useSelector(state => state.locations?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.location && data.car;
	};

	const getLocations = useCallback(() => {
		dispatch(_getLocations());
	}, [dispatch]);

	const getLocation = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedLocationId(id));
				dispatch(loadLocation({ id }));
			}
		},
		[dispatch]
	);

	const selectLocation = useCallback(
		id => {
			dispatch(setSelectedLocationId(id));
		},
		[dispatch]
	);

	const deleteLocation = useCallback(
		id => {
			dispatch(_deleteLocation(id));
		},
		[dispatch]
	);

	const saveLocation = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateLocation(id, data));
				}
				return dispatch(addLocation(data));
			}
			dispatch(locationSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(locationClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(locationClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getLocations,
		getLocation,
		saveLocation,
		selectLocation,
		deleteLocation,
		isProcessing,
		currentLocation,
		selectedLocationId,
		locations,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useLocations;
