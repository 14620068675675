/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect, useState, useMemo } from 'react';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Button } from 'theme-ui';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { createReactMarkup } from '../../utils/cleanHtml';
import './RichTextEditor.scss';

const RichTextEditor = props => {
	const { setRichTextContent, html } = props;

	const [currentHtml, setCurrentHtml] = useState(null);
	const [preview, setPreview] = useState(null);

	const [editorState, setEditorState] = useState(() => {
		if (html) {
			return EditorState.createWithContent(convertFromHTML(html));
		}
		return EditorState.createEmpty();
	});

	const handleEditorChange = state => {
		setEditorState(state);
		const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
		window.KF = editorState.getCurrentContent();
		setRichTextContent(currentContentAsHTML);
	};

	useEffect(() => {
		if (preview) {
			const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
			window.KF = currentContentAsHTML;
			setCurrentHtml(currentContentAsHTML);
		}
	}, [editorState, preview, setCurrentHtml]);

	const previewToggle = useMemo(() => {
		const togglePreview = () => {
			setPreview(!preview);
		};

		return (
			<Button
				sx={{
					float: 'right',
					m: '4px',
					p: '2px 6px',
					fontSize: 14
				}}
				onClick={togglePreview}>
				{preview ? 'Back to editor' : 'Show Preview'}
			</Button>
		);
	}, [preview]);

	const showPreview = useMemo(() => {
		if (preview) {
			return (
				<div>
					<h1>Preview:</h1>
					{currentHtml ? (
						<div dangerouslySetInnerHTML={createReactMarkup(currentHtml)} />
					) : (
						<p>nothing to preview...</p>
					)}
					{previewToggle}
				</div>
			);
		}
		return null;
	}, [preview, currentHtml, previewToggle]);

	if (showPreview) {
		return showPreview;
	}

	// return (
	// 	<h1>test</h1>
	// );

	return (
		<div>
			<Editor
				editorState={editorState}
				onEditorStateChange={handleEditorChange}
				placeholder='Write something!'
				wrapperClassName='wrapper-class'
				editorClassName='editor-class'
				toolbarClassName='toolbar-class'
			/>
			{previewToggle}
		</div>
	);
};

export default RichTextEditor;
