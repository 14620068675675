/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Text } from 'theme-ui'

// import useUsers from '../../../hooks/useUsers';

const ActionButtons = props => {
	const { method } = props;
	const { theme } = useThemeUI();

	// const {
	// 	// users,
	// 	// currentUser,
	// 	selectedUserId,
	// 	error
	// } = useUsers();

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<div
			sx={{
				mb: '1.5em'
			}}>
			<Link sx={linkSx} to='/tools/comms/send-email'>
				Send An Email
			</Link>
			{/*
			{method !== 'list' && (
				<Link sx={linkSx} to='/data/users'>
					Back to Users List
				</Link>
			)}
			{['add', 'edit', 'delete'].indexOf(method) === -1 && (
				<Link sx={linkSx} to='/data/users/add'>
					+ Add a User
				</Link>
			)}
			{method === 'view' && selectedUserId && (
				<>
					<Link
						sx={{
							...linkSx,
							color: theme.colors.text,
							fontWeight: 'bold'
						}}
						to={'/data/users/edit/' + selectedUserId}>
						Edit this User
					</Link>
					{/*
					<Link sx={linkSx} to={'/data/users/delete/' + selectedUserId}>
						-- DELETE User --
					</Link>
					* /}
				</>
			)}
		*/}
		</div>
	);
};

export default ActionButtons;
