import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	DRIVERS_ADD_ERROR,
	DRIVERS_LOADED,
	DRIVERS_LOAD_ERROR,
	DRIVERS_REQUEST_END,
	DRIVERS_REQUEST_START,
	DRIVERS_UPDATE_ERROR,
	DRIVERS_ADD_LOCAL_RECORD,
	DRIVERS_UPDATE_LOCAL_RECORD,
	DRIVERS_DROP_LOCAL_RECORD,
	DRIVER_DELETED,
	DRIVER_DELETE_ERROR,
	DRIVER_DELETE_RESULT_CLEAR,
	DRIVER_SAVED,
	DRIVER_SAVE_ERROR,
	DRIVER_SAVE_RESULT_CLEAR,
	SET_SELECTED_DRIVER_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Driver Actions
 */

export const getDrivers = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().drivers;
		if (state.isRequesting) {
			// console.log('getDrivers: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getDrivers(ids, options);
			};
			dispatch(queueDeferredAction('drivers', deferred));
			return;
		}
		dispatch(driversRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=drivers');
		} catch (err) {
			error = err;
		}

		dispatch(driversRequestEnd());

		if (error) {
			return dispatch(driversLoadError(error));
		}

		dispatch(driversLoaded({ drivers: response?.data }));
	};
};

export const loadDriver = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadDriver, no id!');
			return dispatch(driversLoadError({ message: 'no id found to load driver with' }));
		}
		console.log('loadDriver loadDriver loadDriver loadDriver ');
		console.log(id);
		const state = getState().drivers;
		if (state.isRequesting) {
			// console.log('getDrivers: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadDriver(id);
			};
			dispatch(queueDeferredAction('drivers', deferred));
			return;
		}
		dispatch(driversRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=drivers&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(driversRequestEnd());

		if (error) {
			return dispatch(driversLoadError(error));
		}

		console.log('loadDriver loadDriver loadDriver loadDriver ');
		console.log(response);

		dispatch(driversLoaded({ drivers: [response?.data] }));
	};
};

export const setSelectedDriverId = id => ({
	type: SET_SELECTED_DRIVER_ID,
	payload: { id }
});

export const addDriver = data => {
	return async (dispatch, getState) => {
		const state = getState().drivers;
		if (state.isRequesting) {
			const deferred = () => {
				addDriver(data);
			};
			dispatch(queueDeferredAction('drivers', deferred));
			return;
		}
		dispatch(driversRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=drivers', { data });
		} catch (err) {
			error = err;
		}

		console.log('addDriver, response');
		console.log(response);

		dispatch(driversRequestEnd());

		if (error) {
			dispatch(driverSaveError(responses.DRIVER_SAVE_ERROR));
			return dispatch(driversAddError(error));
		}

		dispatch(driverSaved(responses.DRIVER_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addDriverLocalRecord(data));
		}
	};
};

export const updateDriver = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateDriver, no id!');
			return dispatch(
				driversUpdateError({
					message: 'no id found to update driver with'
				})
			);
		}
		const state = getState().drivers;
		if (state.isRequesting) {
			const deferred = () => {
				updateDriver(id, data);
			};
			dispatch(queueDeferredAction('drivers', deferred));
			return;
		}
		dispatch(driversRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=drivers&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(driversRequestEnd());

		console.log('updateDriver ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(driverSaveError(responses.DRIVER_SAVE_ERROR));
			return dispatch(driversUpdateError(error));
		}

		dispatch(driverSaved(responses.DRIVER_SAVED));
		dispatch(updateDriverLocalRecord(id, data));
	};
};

export const deleteDriver = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteDriver, no id!');
			return false; // dispatch(driversUpdateError({ message: 'no id found to update driver with' }));
		}
		const state = getState().drivers;
		if (state.isRequesting) {
			const deferred = () => {
				deleteDriver(id);
			};
			dispatch(queueDeferredAction('drivers', deferred));
			return;
		}
		dispatch(driversRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=drivers&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(driversRequestEnd());

		console.log('deleteDriver ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(driverDeleteError(responses.DRIVER_DELETE_ERROR));
			// return dispatch(driversUpdateError(error));
		}

		dispatch(driverDeleted(responses.DRIVER_DELETED));
		dispatch(dropDriverLocalRecord(id));
	};
};

export const addDriverLocalRecord = data => ({
	type: DRIVERS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateDriverLocalRecord = (id, data) => ({
	type: DRIVERS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const driversLoaded = ({ drivers }) => ({
	type: DRIVERS_LOADED,
	payload: { drivers }
});

export const driverSaved = responseMsg => ({
	type: DRIVER_SAVED,
	payload: responseMsg
});

export const driverSaveError = responseMsg => ({
	type: DRIVER_SAVE_ERROR,
	payload: responseMsg
});

export const dropDriverLocalRecord = id => ({
	type: DRIVERS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const driverDeleted = responseMsg => ({
	type: DRIVER_DELETED,
	payload: responseMsg
});

export const driverDeleteError = responseMsg => ({
	type: DRIVER_DELETE_ERROR,
	payload: responseMsg
});

export const driverClearSaveResult = () => ({
	type: DRIVER_SAVE_RESULT_CLEAR
});

export const driverClearDeleteResult = () => ({
	type: DRIVER_DELETE_RESULT_CLEAR
});

export const driversLoadError = error => ({
	type: DRIVERS_LOAD_ERROR,
	payload: { error }
});

export const driversAddError = error => ({
	type: DRIVERS_ADD_ERROR,
	payload: { error }
});

export const driversUpdateError = error => ({
	type: DRIVERS_UPDATE_ERROR,
	payload: { error }
});

export const driversRequestEnd = () => ({
	type: DRIVERS_REQUEST_END,
	payload: false
});

export const driversRequestStart = () => ({
	type: DRIVERS_REQUEST_START,
	payload: true
});
