import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getVoteContests as _getVoteContests,
	setSelectedVoteContestId,
	loadVoteContest,
	addVoteContest,
	updateVoteContest,
	deleteVoteContest as _deleteVoteContest,
	voteContestSaveError,
	voteContestClearSaveResult,
	voteContestClearDeleteResult
} from '../redux/actions/voteContests';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useVoteContests = () => {
	const voteContests = useSelector(state => state.voteContests?.list, shallowEqual);
	const currentVoteContest = useSelector(state => state.voteContests?.currentVoteContest);
	const selectedVoteContestId = useSelector(state => state.voteContests?.selectedVoteContestId);
	const error = useSelector(state => state.voteContests?.loadError);
	const saveResult = useSelector(state => state.voteContests?.saveResult);
	const deleteResult = useSelector(state => state.voteContests?.deleteResult);
	const isProcessing = useSelector(state => state.voteContests?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);

	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.voteContest && data.car;
	};

	const getVoteContests = useCallback(() => {
		const options = {
			sort: { voteContestDate: '1' }
		};
		dispatch(_getVoteContests([], options));
	}, [dispatch]);

	const getVoteContest = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedVoteContestId(id));
				dispatch(loadVoteContest({ id }));
			}
		},
		[dispatch]
	);

	const selectVoteContest = useCallback(
		id => {
			dispatch(setSelectedVoteContestId(id));
		},
		[dispatch]
	);

	const deleteVoteContest = useCallback(
		id => {
			dispatch(_deleteVoteContest(id));
		},
		[dispatch]
	);

	const saveVoteContest = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateVoteContest(id, data));
				}
				return dispatch(addVoteContest(data));
			}
			dispatch(voteContestSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(voteContestClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(voteContestClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getVoteContests,
		getVoteContest,
		saveVoteContest,
		selectVoteContest,
		deleteVoteContest,
		isProcessing,
		currentVoteContest,
		selectedVoteContestId,
		voteContests,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useVoteContests;
