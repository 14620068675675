/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Flex, Box, Button, Input, Label, Spinner } from 'theme-ui';
import useVoteContests from '../../../hooks/useVoteContests';
import useLocations from '../../../hooks/useLocations';
import useCars from '../../../hooks/useCars';
import useDrivers from '../../../hooks/useDrivers';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import ImageUploader from '../../segments/ImageUploader';
import SelectOne from '../../segments/SelectOne';
import DatePicker from '../../segments/DatePicker';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import { voteContestTypeOptions } from 'constants/eventUiHelpers';
import VoteContestSetup from 'components/tools/EventBuilders/SetupBuilders/VoteContestSetup';

const VoteContestsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveVoteContest,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useVoteContests();
	const {
		getLocations,
		locations,
		error: locationError,
		isProcessing: isProcessingLocations
	} = useLocations();
	const { getCars, cars, error: carError, isProcessing: isProcessingCars } = useCars();
	const {
		getDrivers,
		drivers,
		error: driverError,
		isProcessing: isProcessingDrivers
	} = useDrivers();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);
	const [hasRequestedCars, setHasRequestedCars] = useState(false);
	const [hasRequestedDrivers, setHasRequestedDrivers] = useState(false);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	useEffect(() => {
		if (!hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [hasRequestedLocations, getLocations, setHasRequestedLocations]);

	useEffect(() => {
		if (!hasRequestedCars) {
			setHasRequestedCars(true);
			getCars();
		}
	}, [hasRequestedCars, getCars, setHasRequestedCars]);

	useEffect(() => {
		if (!hasRequestedDrivers) {
			setHasRequestedDrivers(true);
			getDrivers();
		}
	}, [hasRequestedDrivers, getDrivers, setHasRequestedDrivers]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const handleForm = e => {
		e.preventDefault();
		const voteContestDate = moment(localData.voteContestDate);
		localData.voteContestDate = voteContestDate.format('YYYY-MM-DD');
		saveVoteContest(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New VoteContest:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Vote Contest</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='location'>Location</Label>
							<SelectOne
								data={locations}
								value={localData?.location}
								name='location'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No locations found to choose from.'
							/>
							<Label htmlFor='voteContestType'>Vote Contest Type</Label>
							<SelectOne
								data={voteContestTypeOptions}
								value={localData?.voteContestType}
								name='voteContestType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No voteContest types found to choose from.'
							/>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteContestDate'>Vote Contest Date</Label>
									<DatePicker
										value={localData?.voteContestDate}
										name='voteContestDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto'
									}}>
									<Label htmlFor='time'>Time (GMT/24hr)</Label>
									<Input
										name='time'
										mb={3}
										value={localData?.time || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteStartDate'>Voting Starts Date</Label>
									<DatePicker
										value={localData?.voteStartDate}
										name='voteStartDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteEndDate'>Voting Ends Date</Label>
									<DatePicker
										value={localData?.voteEndDate}
										name='voteEndDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='VoteContest'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Set Vote Contest Logo / Image</h3>
							<Box
								sx={{
									flex: '1 1 auto',
									width: '50%',
									mx: '10px'
								}}>
								<ImageUploader
									id={getLocalImageId(localData)}
									onChange={updateImageData}
								/>
							</Box>
							<h3>Set up Vote Candidate Categories</h3>
							{/*voteContestSetupOptions*/}
							<VoteContestSetup
								localData={localData}
								onFieldUpdate={onFieldUpdate}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default VoteContestsAdd;
