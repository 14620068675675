/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner, useThemeUI } from 'theme-ui';
import useAdmins from '../../../hooks/useAdmins';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
// import AddressFields from '../../segments/AddressFields';
// import ImageUploader from '../../segments/ImageUploader';
// import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import { getOnFieldUpdate } from '../../../utils/datasource';

const AdminsEdit = props => {
	const { data, error } = props;
	const {
		selectedAdminId,
		getAdmin,
		saveAdmin,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useAdmins();
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedAdminId) {
			console.log('getAdmin!!!!' + selectedAdminId);
			getAdmin(selectedAdminId);
		} else if (data?._id !== selectedAdminId) {
			console.log('getAdmin!!!!' + selectedAdminId);
			getAdmin(selectedAdminId);
		}
	}, [data, selectedAdminId, getAdmin]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	// const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveAdmin(selectedAdminId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading admin....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Admin:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='phone'>Mobile Phone</Label>
							<Input
								name='phone'
								mb={3}
								value={localData?.phone || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='email'>Email</Label>
							<Input
								name='email'
								mb={3}
								value={localData?.email || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='password'>Password</Label>
							<Input
								name='password'
								type='password'
								mb={3}
								value={localData?.password || ''}
								onChange={onFieldUpdate}
							/>
							<EditorSaveButton isProcessing={isProcessing} label='Admin' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							{/*
                            <h3>Admin Photo:</h3>
                            <ImageUploader
                                id={getLocalImageId(localData)}
                                onChange={updateImageData}
                            />
                            */}
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default AdminsEdit;
