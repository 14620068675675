import _ from 'lodash';

import {
	ENTRIES_LOADED,
	ENTRIES_LOAD_ERROR,
	ENTRIES_REQUEST_END,
	ENTRIES_REQUEST_START,
	ENTRIES_ADD_LOCAL_RECORD,
	ENTRIES_UPDATE_LOCAL_RECORD,
	ENTRY_DELETED,
	ENTRY_DELETE_ERROR,
	ENTRY_DELETE_RESULT_CLEAR,
	ENTRIES_DROP_LOCAL_RECORD,
	ENTRY_SAVED,
	ENTRY_SAVE_ERROR,
	ENTRY_SAVE_RESULT_CLEAR,
	SET_SELECTED_ENTRY_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedEntryId: null,
	currentEntry: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

const entries = (state = initialState, action) => {
	const _state = { ...state };
	const { entries, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case ENTRIES_LOADED:
			// console.warn(ENTRIES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { entries } = action.payload;
			const currentEntries = [..._state.list];
			if (entries?.length) {
				entries.forEach(T => {
					if (!currentEntries.find(CT => CT._id === T._id)) {
						currentEntries.push(T);
					}
				});
			}
			// console.warn('currentEntries');
			// console.log(currentEntries);
			let currentEntry;
			if (state.selectedEntryId) {
				const selectedEntry = currentEntries.find(D => D._id === state.selectedEntryId);
				if (selectedEntry) {
					currentEntry = selectedEntry;
				}
			}
			return {
				...state,
				loadError: null,
				currentEntry,
				list: currentEntries
			};

		case ENTRIES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(ENTRIES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_ENTRY_ID:
			return {
				...state,
				selectedEntryId: action.payload?.id,
				currentEntry:
					state.currentEntry?._id === action.payload?.id ? state.currentEntry : null
			};

		case ENTRIES_UPDATE_LOCAL_RECORD:
			const oldEntry = state.list.find(D => D._id === id) || {};
			const newEntry = _.merge(oldEntry, data);
			return {
				...state,
				currentEntry: state.currentEntry?._id === id ? newEntry : state.currentEntry,
				list: state.list.map(D => {
					if (D._id === id) {
						return newEntry;
					}
					return D;
				})
			};

		case ENTRIES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case ENTRIES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentEntry: state.currentEntry?._id === id ? null : state.currentEntry,
				list: state.list.filter(D => D._id !== id)
			};

		case ENTRIES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case ENTRIES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case ENTRY_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case ENTRY_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case ENTRY_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case ENTRY_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case ENTRY_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case ENTRY_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
};

export default entries;
