import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getSponsors as _getSponsors,
	setSelectedSponsorId,
	loadSponsor,
	addSponsor,
	updateSponsor,
	deleteSponsor as _deleteSponsor,
	sponsorSaveError,
	sponsorClearSaveResult,
	sponsorClearDeleteResult
} from '../redux/actions/sponsors';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';
import { PROFESSIONAL_ENTITY_TYPES } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useSponsors = () => {
	const sponsors = useSelector(state => state.sponsors?.list, shallowEqual);
	const currentSponsor = useSelector(state => state.sponsors?.currentSponsor);
	const selectedSponsorId = useSelector(state => state.sponsors?.selectedSponsorId);
	const error = useSelector(state => state.sponsors?.loadError);
	const saveResult = useSelector(state => state.sponsors?.saveResult);
	const deleteResult = useSelector(state => state.sponsors?.deleteResult);
	const isProcessing = useSelector(state => state.sponsors?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.sponsor && data.car;
	};

	const getSponsors = useCallback(() => {
		dispatch(_getSponsors());
	}, [dispatch]);

	const getSponsor = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedSponsorId(id));
				dispatch(loadSponsor({ id }));
			}
		},
		[dispatch]
	);

	const selectSponsor = useCallback(
		id => {
			dispatch(setSelectedSponsorId(id));
		},
		[dispatch]
	);

	const deleteSponsor = useCallback(
		id => {
			dispatch(_deleteSponsor(id));
		},
		[dispatch]
	);

	const saveSponsor = useCallback(
		(id, data) => {
			if (validateData(data)) {
				data.type = PROFESSIONAL_ENTITY_TYPES.SPONSOR;
				if (id) {
					return dispatch(updateSponsor(id, data));
				}
				return dispatch(addSponsor(data));
			}
			dispatch(sponsorSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(sponsorClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(sponsorClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getSponsors,
		getSponsor,
		saveSponsor,
		selectSponsor,
		deleteSponsor,
		isProcessing,
		currentSponsor,
		selectedSponsorId,
		sponsors,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useSponsors;
