/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Input, Label, Spinner, Textarea, useThemeUI } from 'theme-ui';
import useDrivers from '../../../hooks/useDrivers';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { getDrivers } from '../../../redux/actions/drivers';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const DriversEdit = props => {
	const { data, error } = props;
	const {
		selectedDriverId,
		getDriver,
		saveDriver,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useDrivers();
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedDriverId) {
			console.log('getDriver!!!!' + selectedDriverId);
			getDriver(selectedDriverId);
		} else if (data?._id !== selectedDriverId) {
			console.log('getDriver!!!!' + selectedDriverId);
			getDriver(selectedDriverId);
		}
	}, [data, selectedDriverId, getDriver]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveDriver(selectedDriverId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading driver....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Driver:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%', '75%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Driver Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='carNumber'>Car Number</Label>
							<Input
								name='carNumber'
								mb={3}
								value={localData?.carNumber || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='iRacingId'>iRacing ID</Label>
							<Input
								name='iRacingId'
								mb={3}
								value={localData?.iRacingId || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='iRatingOnRoad'>iRating on Road</Label>
							<Input
								name='iRatingOnRoad'
								mb={3}
								value={localData?.iRatingOnRoad || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='raceTeam'>Race Team</Label>
							<Input
								name='raceTeam'
								mb={3}
								value={localData?.raceTeam || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='twitter'>Twitter Handle</Label>
							<Input
								name='twitter'
								mb={3}
								value={localData?.twitter || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='country'>Country</Label>
							<Input
								name='country'
								mb={3}
								value={localData?.country || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='equipment'>Equipment</Label>
							<Textarea
								name='equipment'
								mb={3}
								value={localData?.equipment || ''}
								rows={4}
								onChange={onFieldUpdate}
							/>
							<h3>Add image upload widget for driver photo (add to Role)</h3>
							<EditorSaveButton isProcessing={isProcessing} label='Driver' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Driver Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default DriversEdit;
