import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useDrivers from '../../../hooks/useDrivers';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const DriversView = props => {
	const { data, error } = props;
	const { selectedDriverId, getDriver } = useDrivers();

	console.log('DriversView');
	console.log(selectedDriverId, data, error);

	useEffect(() => {
		if (!data && selectedDriverId) {
			console.log('getDriver!!!!' + selectedDriverId);
			getDriver(selectedDriverId);
		}
	}, [data, selectedDriverId, getDriver]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading driver....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Driver:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Driver: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>iRacing ID: </td>
						<td>{data.iRacingId}</td>
					</tr>
					<tr>
						<td>iRating on Road: </td>
						<td>{data.iRatingOnRoad}</td>
					</tr>
					<tr>
						<td>Race Team: </td>
						<td>{data.raceTeam}</td>
					</tr>
					<tr>
						<td>Country: </td>
						<td>{data.country}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default DriversView;
