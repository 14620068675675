/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useRaces from '../../../hooks/useRaces';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const Races = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const { getRaces, races, currentRace, selectedRaceId, selectRace, error } = useRaces();

	const [hasRequestedRaces, setHasRequestedRaces] = useState(false);
	const [myRaceId, setMyRaceId] = useState(selectedRaceId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedRaces) {
			setHasRequestedRaces(true);
			getRaces();
		}
	}, [method, hasRequestedRaces, getRaces]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentRace, method: ' + method);
		if (['view', 'edit'].indexOf(method) > -1 && (!currentRace || currentRace._id !== _id)) {
			console.log('set it! ' + _id);
			selectRace(_id);
			setMyRaceId(_id);
		}
	}, [selectedRaceId, _id, currentRace, method, selectRace]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage Races
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={races} error={error} />}
			{method === 'view' && <View data={currentRace} error={error} />}
			{method === 'edit' && <Edit data={currentRace} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default Races;
