import _ from 'lodash';

import {
	JACKPOTRESULTS_LOADED,
	JACKPOTRESULTS_LOAD_ERROR,
	JACKPOTRESULTS_REQUEST_END,
	JACKPOTRESULTS_REQUEST_START,
	JACKPOTRESULTS_ADD_LOCAL_RECORD,
	JACKPOTRESULTS_UPDATE_LOCAL_RECORD,
	JACKPOTRESULT_DELETED,
	JACKPOTRESULT_DELETE_ERROR,
	JACKPOTRESULT_DELETE_RESULT_CLEAR,
	JACKPOTRESULTS_DROP_LOCAL_RECORD,
	JACKPOTRESULT_SAVED,
	JACKPOTRESULT_SAVE_ERROR,
	JACKPOTRESULT_SAVE_RESULT_CLEAR,
	SET_SELECTED_JACKPOTRESULT_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedJackpotResultId: null,
	currentJackpotResult: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { jackpotResults, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case JACKPOTRESULTS_LOADED:
			// console.warn(JACKPOTRESULTS_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { jackpotResults } = action.payload;
			const currentJackpotResults = [..._state.list];
			if (jackpotResults?.length) {
				jackpotResults.forEach(T => {
					if (!currentJackpotResults.find(CT => CT._id === T._id)) {
						currentJackpotResults.push(T);
					}
				});
			}
			// console.warn('currentJackpotResults');
			// console.log(currentJackpotResults);
			let currentJackpotResult;
			if (state.selectedJackpotResultId) {
				const selectedJackpotResult = currentJackpotResults.find(
					D => D._id === state.selectedJackpotResultId
				);
				if (selectedJackpotResult) {
					currentJackpotResult = selectedJackpotResult;
				}
			}
			return {
				...state,
				loadError: null,
				currentJackpotResult,
				list: currentJackpotResults
			};

		case JACKPOTRESULTS_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(JACKPOTRESULTS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_JACKPOTRESULT_ID:
			return {
				...state,
				selectedJackpotResultId: action.payload?.id,
				currentJackpotResult:
					state.currentJackpotResult?._id === action.payload?.id
						? state.currentJackpotResult
						: null
			};

		case JACKPOTRESULTS_UPDATE_LOCAL_RECORD:
			const oldJackpotResult = state.list.find(D => D._id === id) || {};
			const newJackpotResult = _.merge(oldJackpotResult, data);
			return {
				...state,
				currentJackpotResult:
					state.currentJackpotResult?._id === id
						? newJackpotResult
						: state.currentJackpotResult,
				list: state.list.map(D => {
					if (D._id === id) {
						return newJackpotResult;
					}
					return D;
				})
			};

		case JACKPOTRESULTS_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case JACKPOTRESULTS_DROP_LOCAL_RECORD:
			return {
				...state,
				currentJackpotResult:
					state.currentJackpotResult?._id === id ? null : state.currentJackpotResult,
				list: state.list.filter(D => D._id !== id)
			};

		case JACKPOTRESULTS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case JACKPOTRESULTS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case JACKPOTRESULT_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case JACKPOTRESULT_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case JACKPOTRESULT_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case JACKPOTRESULT_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case JACKPOTRESULT_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case JACKPOTRESULT_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
