//import Log from '../utils/BigLogger'
//const Big = new Log('FA_Loader');

import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// FREE:
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';

// PRO:
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faBellExclamation } from '@fortawesome/pro-regular-svg-icons/faBellExclamation';
import { faHomeLgAlt } from '@fortawesome/pro-regular-svg-icons/faHomeLgAlt';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons/faCalendarStar';
import { faBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faSteeringWheel } from '@fortawesome/pro-regular-svg-icons/faSteeringWheel';
import { faChevronSquareLeft } from '@fortawesome/pro-regular-svg-icons/faChevronSquareLeft';
import { faChevronSquareRight } from '@fortawesome/pro-regular-svg-icons/faChevronSquareRight';
import { faChevronSquareUp } from '@fortawesome/pro-regular-svg-icons/faChevronSquareUp';
import { faChevronSquareDown } from '@fortawesome/pro-regular-svg-icons/faChevronSquareDown';

library.add(faDollarSign);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faExclamationTriangle);
library.add(faBellExclamation);
library.add(faHomeLgAlt);
library.add(faFlagCheckered);
library.add(faCalendarStar);
library.add(faHandHoldingHeart);
library.add(faBullseye);
library.add(faBook);
library.add(faSteeringWheel);
library.add(faChevronSquareLeft);
library.add(faChevronSquareRight);
library.add(faChevronSquareUp);
library.add(faChevronSquareDown);

export default library;
