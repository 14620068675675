import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getJackpotResults as _getJackpotResults,
	setSelectedJackpotResultId,
	loadJackpotResult,
	addJackpotResult,
	updateJackpotResult,
	deleteJackpotResult as _deleteJackpotResult,
	jackpotResultSaveError,
	jackpotResultClearSaveResult,
	jackpotResultClearDeleteResult
} from '../redux/actions/jackpotResults';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useJackpotResults = () => {
	const jackpotResults = useSelector(state => state.jackpotResults?.list, shallowEqual);
	const currentJackpotResult = useSelector(state => state.jackpotResults?.currentJackpotResult);
	const selectedJackpotResultId = useSelector(
		state => state.jackpotResults?.selectedJackpotResultId
	);
	const error = useSelector(state => state.jackpotResults?.loadError);
	const saveResult = useSelector(state => state.jackpotResults?.saveResult);
	const deleteResult = useSelector(state => state.jackpotResults?.deleteResult);
	const isProcessing = useSelector(state => state.jackpotResults?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.jackpotResult && data.car;
	};

	const getJackpotResults = useCallback(() => {
		dispatch(_getJackpotResults());
	}, [dispatch]);

	const getJackpotResult = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedJackpotResultId(id));
				dispatch(loadJackpotResult({ id }));
			}
		},
		[dispatch]
	);

	const selectJackpotResult = useCallback(
		id => {
			dispatch(setSelectedJackpotResultId(id));
		},
		[dispatch]
	);

	const deleteJackpotResult = useCallback(
		id => {
			dispatch(_deleteJackpotResult(id));
		},
		[dispatch]
	);

	const saveJackpotResult = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateJackpotResult(id, data));
				}
				return dispatch(addJackpotResult(data));
			}
			dispatch(jackpotResultSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(jackpotResultClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(jackpotResultClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getJackpotResults,
		getJackpotResult,
		saveJackpotResult,
		selectJackpotResult,
		deleteJackpotResult,
		isProcessing,
		currentJackpotResult,
		selectedJackpotResultId,
		jackpotResults,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useJackpotResults;
