import _ from 'lodash';

import {
	RAFFLEPRIZES_LOADED,
	RAFFLEPRIZES_LOAD_ERROR,
	RAFFLEPRIZES_REQUEST_END,
	RAFFLEPRIZES_REQUEST_START,
	RAFFLEPRIZES_ADD_LOCAL_RECORD,
	RAFFLEPRIZES_UPDATE_LOCAL_RECORD,
	RAFFLEPRIZE_DELETED,
	RAFFLEPRIZE_DELETE_ERROR,
	RAFFLEPRIZE_DELETE_RESULT_CLEAR,
	RAFFLEPRIZES_DROP_LOCAL_RECORD,
	RAFFLEPRIZE_SAVED,
	RAFFLEPRIZE_SAVE_ERROR,
	RAFFLEPRIZE_SAVE_RESULT_CLEAR,
	SET_SELECTED_RAFFLEPRIZE_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedRafflePrizeId: null,
	currentRafflePrize: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { rafflePrizes, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case RAFFLEPRIZES_LOADED:
			// console.warn(RAFFLEPRIZES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { rafflePrizes } = action.payload;
			const currentRafflePrizes = [..._state.list];
			if (rafflePrizes?.length) {
				rafflePrizes.forEach(T => {
					if (!currentRafflePrizes.find(CT => CT._id === T._id)) {
						currentRafflePrizes.push(T);
					}
				});
			}
			// console.warn('currentRafflePrizes');
			// console.log(currentRafflePrizes);
			let currentRafflePrize;
			if (state.selectedRafflePrizeId) {
				const selectedRafflePrize = currentRafflePrizes.find(
					D => D._id === state.selectedRafflePrizeId
				);
				if (selectedRafflePrize) {
					currentRafflePrize = selectedRafflePrize;
				}
			}
			return {
				...state,
				loadError: null,
				currentRafflePrize,
				list: currentRafflePrizes
			};

		case RAFFLEPRIZES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(RAFFLEPRIZES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_RAFFLEPRIZE_ID:
			return {
				...state,
				selectedRafflePrizeId: action.payload?.id,
				currentRafflePrize:
					state.currentRafflePrize?._id === action.payload?.id
						? state.currentRafflePrize
						: null
			};

		case RAFFLEPRIZES_UPDATE_LOCAL_RECORD:
			const oldRafflePrize = state.list.find(D => D._id === id) || {};
			const newRafflePrize = _.merge(oldRafflePrize, data);
			return {
				...state,
				currentRafflePrize:
					state.currentRafflePrize?._id === id
						? newRafflePrize
						: state.currentRafflePrize,
				list: state.list.map(D => {
					if (D._id === id) {
						return newRafflePrize;
					}
					return D;
				})
			};

		case RAFFLEPRIZES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case RAFFLEPRIZES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentRafflePrize:
					state.currentRafflePrize?._id === id ? null : state.currentRafflePrize,
				list: state.list.filter(D => D._id !== id)
			};

		case RAFFLEPRIZES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case RAFFLEPRIZES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case RAFFLEPRIZE_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case RAFFLEPRIZE_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case RAFFLEPRIZE_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case RAFFLEPRIZE_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case RAFFLEPRIZE_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case RAFFLEPRIZE_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
