import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	ARTICLES_ADD_ERROR,
	ARTICLES_LOADED,
	ARTICLES_LOAD_ERROR,
	ARTICLES_REQUEST_END,
	ARTICLES_REQUEST_START,
	ARTICLES_UPDATE_ERROR,
	ARTICLES_ADD_LOCAL_RECORD,
	ARTICLES_UPDATE_LOCAL_RECORD,
	ARTICLES_DROP_LOCAL_RECORD,
	ARTICLE_DELETED,
	ARTICLE_DELETE_ERROR,
	ARTICLE_DELETE_RESULT_CLEAR,
	ARTICLE_SAVED,
	ARTICLE_SAVE_ERROR,
	ARTICLE_SAVE_RESULT_CLEAR,
	SET_SELECTED_ARTICLE_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Article Actions
 */

export const getArticles = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().articles;
		if (state.isRequesting) {
			// console.log('getArticles: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getArticles(ids, options);
			};
			dispatch(queueDeferredAction('articles', deferred));
			return;
		}
		dispatch(articlesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=articles');
		} catch (err) {
			error = err;
		}

		dispatch(articlesRequestEnd());

		if (error) {
			return dispatch(articlesLoadError(error));
		}

		dispatch(articlesLoaded({ articles: response?.data }));
	};
};

export const loadArticle = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadArticle, no id!');
			return dispatch(
				articlesLoadError({
					message: 'no id found to load article with'
				})
			);
		}
		console.log('loadArticle loadArticle loadArticle loadArticle ');
		console.log(id);
		const state = getState().articles;
		if (state.isRequesting) {
			// console.log('getArticles: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadArticle(id);
			};
			dispatch(queueDeferredAction('articles', deferred));
			return;
		}
		dispatch(articlesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=articles&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(articlesRequestEnd());

		if (error) {
			return dispatch(articlesLoadError(error));
		}

		console.log('loadArticle loadArticle loadArticle loadArticle ');
		console.log(response);

		dispatch(articlesLoaded({ articles: [response?.data] }));
	};
};

export const setSelectedArticleId = id => ({
	type: SET_SELECTED_ARTICLE_ID,
	payload: { id }
});

export const addArticle = data => {
	return async (dispatch, getState) => {
		const state = getState().articles;
		if (state.isRequesting) {
			const deferred = () => {
				addArticle(data);
			};
			dispatch(queueDeferredAction('articles', deferred));
			return;
		}
		dispatch(articlesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=articles', { data });
		} catch (err) {
			error = err;
		}

		console.log('addArticle, response');
		console.log(response);

		dispatch(articlesRequestEnd());

		if (error) {
			dispatch(articleSaveError(responses.ARTICLE_SAVE_ERROR));
			return dispatch(articlesAddError(error));
		}

		dispatch(articleSaved(responses.ARTICLE_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addArticleLocalRecord(data));
		}
	};
};

export const updateArticle = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateArticle, no id!');
			return dispatch(
				articlesUpdateError({
					message: 'no id found to update article with'
				})
			);
		}
		const state = getState().articles;
		if (state.isRequesting) {
			const deferred = () => {
				updateArticle(id, data);
			};
			dispatch(queueDeferredAction('articles', deferred));
			return;
		}
		dispatch(articlesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=articles&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(articlesRequestEnd());

		console.log('updateArticle ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(articleSaveError(responses.ARTICLE_SAVE_ERROR));
			return dispatch(articlesUpdateError(error));
		}

		dispatch(articleSaved(responses.ARTICLE_SAVED));
		dispatch(updateArticleLocalRecord(id, data));
	};
};

export const deleteArticle = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteArticle, no id!');
			return false; // dispatch(articlesUpdateError({ message: 'no id found to update article with' }));
		}
		const state = getState().articles;
		if (state.isRequesting) {
			const deferred = () => {
				deleteArticle(id);
			};
			dispatch(queueDeferredAction('articles', deferred));
			return;
		}
		dispatch(articlesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=articles&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(articlesRequestEnd());

		console.log('deleteArticle ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(articleDeleteError(responses.ARTICLE_DELETE_ERROR));
			// return dispatch(articlesUpdateError(error));
		}

		dispatch(articleDeleted(responses.ARTICLE_DELETED));
		dispatch(dropArticleLocalRecord(id));
	};
};

export const addArticleLocalRecord = data => ({
	type: ARTICLES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateArticleLocalRecord = (id, data) => ({
	type: ARTICLES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const articlesLoaded = ({ articles }) => ({
	type: ARTICLES_LOADED,
	payload: { articles }
});

export const articleSaved = responseMsg => ({
	type: ARTICLE_SAVED,
	payload: responseMsg
});

export const articleSaveError = responseMsg => ({
	type: ARTICLE_SAVE_ERROR,
	payload: responseMsg
});

export const dropArticleLocalRecord = id => ({
	type: ARTICLES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const articleDeleted = responseMsg => ({
	type: ARTICLE_DELETED,
	payload: responseMsg
});

export const articleDeleteError = responseMsg => ({
	type: ARTICLE_DELETE_ERROR,
	payload: responseMsg
});

export const articleClearSaveResult = () => ({
	type: ARTICLE_SAVE_RESULT_CLEAR
});

export const articleClearDeleteResult = () => ({
	type: ARTICLE_DELETE_RESULT_CLEAR
});

export const articlesLoadError = error => ({
	type: ARTICLES_LOAD_ERROR,
	payload: { error }
});

export const articlesAddError = error => ({
	type: ARTICLES_ADD_ERROR,
	payload: { error }
});

export const articlesUpdateError = error => ({
	type: ARTICLES_UPDATE_ERROR,
	payload: { error }
});

export const articlesRequestEnd = () => ({
	type: ARTICLES_REQUEST_END,
	payload: false
});

export const articlesRequestStart = () => ({
	type: ARTICLES_REQUEST_START,
	payload: true
});
