import React from 'react';
import { Box, Button, Input, Label, Spinner } from 'theme-ui';
import { EditorButtonContainer } from '../ThemeUiStyled';

const EditorSaveButton = props => {
	const {
		status,
		isProcessing,
		label,
		uiStatusSaveMessageConsumed,
		setLocalData,
		setLocalSaveResult,
		component
	} = props;

	const handleAddAnother = e => {
		e.preventDefault();
		uiStatusSaveMessageConsumed(false);
		setLocalData({});
		setLocalSaveResult(null);
	};

	return (
		<EditorButtonContainer>
			{setLocalData && status ? (
				<Button onClick={handleAddAnother}>Add Another {label}</Button>
			) : !isProcessing ? (
				component || <Button>Save {label}</Button>
			) : (
				<Spinner />
			)}
		</EditorButtonContainer>
	);
};

export default EditorSaveButton;
