import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Box, Button, Spinner } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import useJackpotResults from '../../../hooks/useJackpotResults';
import useRaces from '../../../hooks/useRaces';
import { ViewTable } from '../../ThemeUiStyled';
import { showDate } from '../../../utils/datasource';
import { pickBallSx, greenActionButtonSx } from './PickWinners';

const JackpotResultsView = props => {
	const { data, error } = props;
	const { selectedJackpotResultId, getJackpotResult } = useJackpotResults();
	const history = useHistory();

	// console.log('JackpotResultsView');
	// console.log(selectedJackpotResultId, data, error);

	const { getRace, currentRace } = useRaces();

	useEffect(() => {
		if (!data && selectedJackpotResultId) {
			getJackpotResult(selectedJackpotResultId);
		}
	}, [data, selectedJackpotResultId, getJackpotResult]);

	useEffect(() => {
		if (data && currentRace?._id !== data.race) {
			getRace(data.race);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRace?._id, data, data?.race, getRace]);

	const pickWinners = e => {
		e && e.preventDefault();
		if (data.race) {
			history.push(`/data/jackpot-results/pick-winners/${data._id}`);
		}
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading jackpotResult....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Jackpot Result:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Date: </td>
						<td>{showDate(data.jackpotDate || 'none')}</td>
					</tr>
					<tr>
						<td>Race: </td>
						<td>{currentRace?.name || 'no race found'}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			<h2>Picks:</h2>
			{data?.selectedNumbers ? (
				<Flex sx={{ justifyContent: 'center' }}>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[0]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[1]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[2]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[3]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[4]}</Box>
					</Box>
					<Box>
						<Box sx={pickBallSx}>{data?.selectedNumbers[5]}</Box>
					</Box>
				</Flex>
			) : (
				<Box>
					<h3>This Jackpot Draw does not have picks yet.</h3>
					<Button
						sx={{
							...greenActionButtonSx,
							margin: '0 auto',
							display: 'block',
							cursor: 'pointer'
						}}
						onClick={pickWinners}>
						Click here to pick winners
					</Button>
				</Box>
			)}
		</div>
	);
};

export default JackpotResultsView;
