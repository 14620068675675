/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useLocations from '../../../hooks/useLocations';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const Locations = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const {
		getLocations,
		locations,
		currentLocation,
		selectedLocationId,
		selectLocation,
		error
	} = useLocations();

	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);
	const [myLocationId, setMyLocationId] = useState(selectedLocationId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [method, hasRequestedLocations, getLocations]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentLocation, method: ' + method);
		if (
			['view', 'edit'].indexOf(method) > -1 &&
			(!currentLocation || currentLocation._id !== _id)
		) {
			console.log('set it! ' + _id);
			selectLocation(_id);
			setMyLocationId(_id);
		}
	}, [selectedLocationId, _id, currentLocation, method, selectLocation]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage Locations
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={locations} error={error} />}
			{method === 'view' && <View data={currentLocation} error={error} />}
			{method === 'edit' && <Edit data={currentLocation} error={error} />}
			{method === 'add' && <Add />}
		</DatasourceContainer>
	);
};

export default Locations;
