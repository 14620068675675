import {
	RAFFLE_TICKETS_LOADED,
	RAFFLE_TICKETS_LOAD_ERROR,
	RAFFLE_TICKETS_REQUEST_END,
	RAFFLE_TICKETS_REQUEST_START
} from '../actions/actionTypes';

const initialState = {
	tickets: [],
	loadError: null,
	isRequesting: false
};

export default function (state = initialState, action) {
	const _state = { ...state };
	switch (action.type) {
		case RAFFLE_TICKETS_LOADED:
			const { tickets } = action.payload;
			const currentTickets = [..._state.tickets];
			if (tickets?.length) {
				tickets.forEach(T => {
					if (!currentTickets.find(CT => CT._id === T._id)) {
						currentTickets.push(T);
					}
				});
			}
			return {
				...state,
				loadError: null,
				tickets: currentTickets
			};

		case RAFFLE_TICKETS_LOAD_ERROR:
			const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(RAFFLE_TICKETS_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case RAFFLE_TICKETS_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case RAFFLE_TICKETS_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		default:
			return state;
	}
}
