import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Input, Label, Spinner } from 'theme-ui';
import useJackpotResults from '../../../hooks/useJackpotResults';
import useRaces from '../../../hooks/useRaces';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import SelectOne from '../../segments/SelectOne';
import EditorSaveButton from '../../segments/EditorSaveButton';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';
import { RACETYPES } from '../../../constants';

const JackpotResultsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveJackpotResult,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useJackpotResults();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	const { getRaces, races, error: raceError, isProcessing: isProcessingRaces } = useRaces();

	const [hasRequestedRaces, setHasRequestedRaces] = useState(false);

	useEffect(() => {
		if (!hasRequestedRaces) {
			setHasRequestedRaces(true);
			getRaces({ raceType: RACETYPES.GHA_JACKPOT });
		}
	}, [hasRequestedRaces, getRaces, setHasRequestedRaces]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveJackpotResult(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New JackpotResult:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '65%', '40%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Label htmlFor='jackpotDate'>Date</Label>
					<DatePicker
						value={localData?.jackpotDate}
						name='jackpotDate'
						onFieldUpdate={onFieldUpdate}
					/>
					<Label htmlFor='race'>Race</Label>
					<SelectOne
						data={races}
						value={localData?.race}
						name='race'
						onFieldUpdate={onFieldUpdate}
						noDataMsg='No races found to choose from.'
					/>
					<EditorSaveButton
						status={localSaveResult?.status}
						isProcessing={isProcessing}
						label='Jackpot Result'
						uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
						setLocalData={setLocalData}
						setLocalSaveResult={setLocalSaveResult}
					/>
					<UiStatusMessageDisplay
						message={uiStatusMessageSaveResult}
						consumed={uiStatusSaveMessageConsumed}
					/>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default JackpotResultsAdd;
