import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getArticles as _getArticles,
	setSelectedArticleId,
	loadArticle,
	addArticle,
	updateArticle,
	deleteArticle as _deleteArticle,
	articleSaveError,
	articleClearSaveResult,
	articleClearDeleteResult
} from '../redux/actions/articles';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useArticles = () => {
	const articles = useSelector(state => state.articles?.list, shallowEqual);
	const currentArticle = useSelector(state => state.articles?.currentArticle);
	const selectedArticleId = useSelector(state => state.articles?.selectedArticleId);
	const error = useSelector(state => state.articles?.loadError);
	const saveResult = useSelector(state => state.articles?.saveResult);
	const deleteResult = useSelector(state => state.articles?.deleteResult);
	const isProcessing = useSelector(state => state.articles?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.article && data.car;
	};

	const getArticles = useCallback(() => {
		dispatch(_getArticles());
	}, [dispatch]);

	const getArticle = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedArticleId(id));
				dispatch(loadArticle({ id }));
			}
		},
		[dispatch]
	);

	const selectArticle = useCallback(
		id => {
			dispatch(setSelectedArticleId(id));
		},
		[dispatch]
	);

	const deleteArticle = useCallback(
		id => {
			dispatch(_deleteArticle(id));
		},
		[dispatch]
	);

	const saveArticle = useCallback(
		(id, data) => {
			if (validateData(data)) {
				if (id) {
					return dispatch(updateArticle(id, data));
				}
				return dispatch(addArticle(data));
			}
			dispatch(articleSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(articleClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(articleClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getArticles,
		getArticle,
		saveArticle,
		selectArticle,
		deleteArticle,
		isProcessing,
		currentArticle,
		selectedArticleId,
		articles,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useArticles;
