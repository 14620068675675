/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
// import styled from 'styled-components';
import { Flex, Box, Button, Input, Label, Textarea } from 'theme-ui';
import useArticles from '../../../hooks/useArticles';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import EditorSaveButton from '../../segments/EditorSaveButton';
import { EditorContainer } from '../../ThemeUiStyled';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import RichTextEditor from '../../segments/RichTextEditor';
import DatePicker from '../../segments/DatePicker';
import SelectOne from '../../segments/SelectOne';
import { ARTICLETYPES, ARTICLETYPES_LABELS } from '../../../constants';

const ArticlesAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveArticle,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useArticles();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	// FIXME: this should probably be a constant defined outside of component??
	const articleTypeOptions = useMemo(() => {
		return Object.keys(ARTICLETYPES).map(type => {
			return {
				name: ARTICLETYPES_LABELS[type],
				value: ARTICLETYPES[type]
			};
		});
	}, []);

	const updateLocalEditorState = content => {
		onFieldUpdate({
			target: {
				name: 'body',
				value: content
			}
		});
	};

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const handleForm = e => {
		e.preventDefault();
		saveArticle(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Article:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								// flex: '1 1 auto',
								width: '30%',
								p: 0
							}}>
							<Label htmlFor='article'>Title</Label>
							<Input
								name='title'
								mb={3}
								value={localData?.title || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='seo_description'>SEO Description</Label>
							<Textarea
								rows={4}
								name='seo_description'
								mb={3}
								value={localData?.seo_description || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='publishDate'>Publish Date</Label>
							<DatePicker
								value={localData?.publishDate}
								name='publishDate'
								onFieldUpdate={onFieldUpdate}
							/>
							<Label htmlFor='staleDate'>Stale Date</Label>
							<DatePicker
								value={localData?.staleDate}
								name='staleDate'
								onFieldUpdate={onFieldUpdate}
							/>
							<Label htmlFor='articleType'>Type</Label>
							<SelectOne
								data={articleTypeOptions}
								value={localData?.articleType}
								dataValueProp='value'
								name='articleType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No article types found to choose from.'
							/>
							<Label htmlFor='blurb'>Short blurb</Label>
							<Textarea
								rows={4}
								name='blurb'
								mb={3}
								value={localData?.blurb || ''}
								onChange={onFieldUpdate}
							/>
							<h3>Article Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Article'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								// __flex: '1 1 auto',
								maxWidth: '70%',
								p: '10px'
							}}>
							<Label htmlFor='body'>Article Body</Label>
							<RichTextEditor setRichTextContent={updateLocalEditorState} />
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default ArticlesAdd;
