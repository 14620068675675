import _ from 'lodash';

import {
	DONATIONMATRIXES_LOADED,
	DONATIONMATRIXES_LOAD_ERROR,
	DONATIONMATRIXES_REQUEST_END,
	DONATIONMATRIXES_REQUEST_START,
	DONATIONMATRIXES_ADD_LOCAL_RECORD,
	DONATIONMATRIXES_UPDATE_LOCAL_RECORD,
	DONATIONMATRIX_DELETED,
	DONATIONMATRIX_DELETE_ERROR,
	DONATIONMATRIX_DELETE_RESULT_CLEAR,
	DONATIONMATRIXES_DROP_LOCAL_RECORD,
	DONATIONMATRIX_SAVED,
	DONATIONMATRIX_SAVE_ERROR,
	DONATIONMATRIX_SAVE_RESULT_CLEAR,
	SET_SELECTED_DONATIONMATRIX_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedDonationMatrixId: null,
	currentDonationMatrix: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { donationMatrixes, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case DONATIONMATRIXES_LOADED:
			// console.warn(DONATIONMATRIXES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { donationMatrixes } = action.payload;
			const currentDonationMatrixes = [..._state.list];
			if (donationMatrixes?.length) {
				donationMatrixes.forEach(T => {
					if (!currentDonationMatrixes.find(CT => CT._id === T._id)) {
						currentDonationMatrixes.push(T);
					}
				});
			}
			// console.warn('currentDonationMatrixes');
			// console.log(currentDonationMatrixes);
			let currentDonationMatrix;
			if (state.selectedDonationMatrixId) {
				const selectedDonationMatrix = currentDonationMatrixes.find(
					D => D._id === state.selectedDonationMatrixId
				);
				if (selectedDonationMatrix) {
					currentDonationMatrix = selectedDonationMatrix;
				}
			}
			return {
				...state,
				loadError: null,
				currentDonationMatrix,
				list: currentDonationMatrixes
			};

		case DONATIONMATRIXES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(DONATIONMATRIXES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_DONATIONMATRIX_ID:
			return {
				...state,
				selectedDonationMatrixId: action.payload?.id,
				currentDonationMatrix:
					state.currentDonationMatrix?._id === action.payload?.id
						? state.currentDonationMatrix
						: null
			};

		case DONATIONMATRIXES_UPDATE_LOCAL_RECORD:
			const oldDonationMatrix = state.list.find(D => D._id === id) || {};
			const newDonationMatrix = _.merge(oldDonationMatrix, data);
			return {
				...state,
				currentDonationMatrix:
					state.currentDonationMatrix?._id === id
						? newDonationMatrix
						: state.currentDonationMatrix,
				list: state.list.map(D => {
					if (D._id === id) {
						return newDonationMatrix;
					}
					return D;
				})
			};

		case DONATIONMATRIXES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case DONATIONMATRIXES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentDonationMatrix:
					state.currentDonationMatrix?._id === id ? null : state.currentDonationMatrix,
				list: state.list.filter(D => D._id !== id)
			};

		case DONATIONMATRIXES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case DONATIONMATRIXES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case DONATIONMATRIX_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case DONATIONMATRIX_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case DONATIONMATRIX_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case DONATIONMATRIX_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case DONATIONMATRIX_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case DONATIONMATRIX_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
