import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	RAFFLEPRIZES_ADD_ERROR,
	RAFFLEPRIZES_LOADED,
	RAFFLEPRIZES_LOAD_ERROR,
	RAFFLEPRIZES_REQUEST_END,
	RAFFLEPRIZES_REQUEST_START,
	RAFFLEPRIZES_UPDATE_ERROR,
	RAFFLEPRIZES_ADD_LOCAL_RECORD,
	RAFFLEPRIZES_UPDATE_LOCAL_RECORD,
	RAFFLEPRIZES_DROP_LOCAL_RECORD,
	RAFFLEPRIZE_DELETED,
	RAFFLEPRIZE_DELETE_ERROR,
	RAFFLEPRIZE_DELETE_RESULT_CLEAR,
	RAFFLEPRIZE_SAVED,
	RAFFLEPRIZE_SAVE_ERROR,
	RAFFLEPRIZE_SAVE_RESULT_CLEAR,
	SET_SELECTED_RAFFLEPRIZE_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * RafflePrize Actions
 */

export const getRafflePrizes = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().rafflePrizes;
		if (state.isRequesting) {
			// console.log('getRafflePrizes: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getRafflePrizes(ids, options);
			};
			dispatch(queueDeferredAction('rafflePrizes', deferred));
			return;
		}
		dispatch(rafflePrizesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=raffle-prizes');
		} catch (err) {
			error = err;
		}

		dispatch(rafflePrizesRequestEnd());

		if (error) {
			return dispatch(rafflePrizesLoadError(error));
		}

		dispatch(rafflePrizesLoaded({ rafflePrizes: response?.data }));
	};
};

export const loadRafflePrize = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadRafflePrize, no id!');
			return dispatch(
				rafflePrizesLoadError({ message: 'no id found to load rafflePrize with' })
			);
		}
		console.log('loadRafflePrize loadRafflePrize loadRafflePrize loadRafflePrize ');
		console.log(id);
		const state = getState().rafflePrizes;
		if (state.isRequesting) {
			// console.log('getRafflePrizes: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadRafflePrize(id);
			};
			dispatch(queueDeferredAction('rafflePrizes', deferred));
			return;
		}
		dispatch(rafflePrizesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=raffle-prizes&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(rafflePrizesRequestEnd());

		if (error) {
			return dispatch(rafflePrizesLoadError(error));
		}

		console.log('loadRafflePrize loadRafflePrize loadRafflePrize loadRafflePrize ');
		console.log(response);

		dispatch(rafflePrizesLoaded({ rafflePrizes: [response?.data] }));
	};
};

export const setSelectedRafflePrizeId = id => ({
	type: SET_SELECTED_RAFFLEPRIZE_ID,
	payload: { id }
});

export const addRafflePrize = data => {
	return async (dispatch, getState) => {
		const state = getState().rafflePrizes;
		if (state.isRequesting) {
			const deferred = () => {
				addRafflePrize(data);
			};
			dispatch(queueDeferredAction('rafflePrizes', deferred));
			return;
		}
		dispatch(rafflePrizesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=raffle-prizes', { data });
		} catch (err) {
			error = err;
		}

		console.log('addRafflePrize, response');
		console.log(response);

		dispatch(rafflePrizesRequestEnd());

		if (error) {
			dispatch(rafflePrizeSaveError(responses.RAFFLEPRIZE_SAVE_ERROR));
			return dispatch(rafflePrizesAddError(error));
		}

		dispatch(rafflePrizeSaved(responses.RAFFLEPRIZE_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addRafflePrizeLocalRecord(data));
		}
	};
};

export const updateRafflePrize = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateRafflePrize, no id!');
			return dispatch(
				rafflePrizesUpdateError({
					message: 'no id found to update rafflePrize with'
				})
			);
		}
		const state = getState().rafflePrizes;
		if (state.isRequesting) {
			const deferred = () => {
				updateRafflePrize(id, data);
			};
			dispatch(queueDeferredAction('rafflePrizes', deferred));
			return;
		}
		dispatch(rafflePrizesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=raffle-prizes&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(rafflePrizesRequestEnd());

		console.log('updateRafflePrize ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(rafflePrizeSaveError(responses.RAFFLEPRIZE_SAVE_ERROR));
			return dispatch(rafflePrizesUpdateError(error));
		}

		dispatch(rafflePrizeSaved(responses.RAFFLEPRIZE_SAVED));
		dispatch(updateRafflePrizeLocalRecord(id, data));
	};
};

export const deleteRafflePrize = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteRafflePrize, no id!');
			return false; // dispatch(rafflePrizesUpdateError({ message: 'no id found to update rafflePrize with' }));
		}
		const state = getState().rafflePrizes;
		if (state.isRequesting) {
			const deferred = () => {
				deleteRafflePrize(id);
			};
			dispatch(queueDeferredAction('rafflePrizes', deferred));
			return;
		}
		dispatch(rafflePrizesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=raffle-prizes&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(rafflePrizesRequestEnd());

		console.log('deleteRafflePrize ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(rafflePrizeDeleteError(responses.RAFFLEPRIZE_DELETE_ERROR));
			// return dispatch(rafflePrizesUpdateError(error));
		}

		dispatch(rafflePrizeDeleted(responses.RAFFLEPRIZE_DELETED));
		dispatch(dropRafflePrizeLocalRecord(id));
	};
};

export const addRafflePrizeLocalRecord = data => ({
	type: RAFFLEPRIZES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateRafflePrizeLocalRecord = (id, data) => ({
	type: RAFFLEPRIZES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const rafflePrizesLoaded = ({ rafflePrizes }) => ({
	type: RAFFLEPRIZES_LOADED,
	payload: { rafflePrizes }
});

export const rafflePrizeSaved = responseMsg => ({
	type: RAFFLEPRIZE_SAVED,
	payload: responseMsg
});

export const rafflePrizeSaveError = responseMsg => ({
	type: RAFFLEPRIZE_SAVE_ERROR,
	payload: responseMsg
});

export const dropRafflePrizeLocalRecord = id => ({
	type: RAFFLEPRIZES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const rafflePrizeDeleted = responseMsg => ({
	type: RAFFLEPRIZE_DELETED,
	payload: responseMsg
});

export const rafflePrizeDeleteError = responseMsg => ({
	type: RAFFLEPRIZE_DELETE_ERROR,
	payload: responseMsg
});

export const rafflePrizeClearSaveResult = () => ({
	type: RAFFLEPRIZE_SAVE_RESULT_CLEAR
});

export const rafflePrizeClearDeleteResult = () => ({
	type: RAFFLEPRIZE_DELETE_RESULT_CLEAR
});

export const rafflePrizesLoadError = error => ({
	type: RAFFLEPRIZES_LOAD_ERROR,
	payload: { error }
});

export const rafflePrizesAddError = error => ({
	type: RAFFLEPRIZES_ADD_ERROR,
	payload: { error }
});

export const rafflePrizesUpdateError = error => ({
	type: RAFFLEPRIZES_UPDATE_ERROR,
	payload: { error }
});

export const rafflePrizesRequestEnd = () => ({
	type: RAFFLEPRIZES_REQUEST_END,
	payload: false
});

export const rafflePrizesRequestStart = () => ({
	type: RAFFLEPRIZES_REQUEST_START,
	payload: true
});
