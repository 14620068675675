/**
 * get a ?query=var from current location href
 * @function getGetVar
 * @param {string} key - query var key to look for
 * @param {string} [default_= ] - default value to pass back if not var not found
 * @returns {string} - value or default_
 */
export function getGetVar(key, default_) {
	if (default_ == null) {
		default_ = '';
	}
	// eslint-disable-next-line no-useless-escape
	key = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
	var qs = regex.exec(window.location.href);
	if (qs == null) {
		return default_;
	} else {
		return decodeURIComponent(qs[1]);
	}
}

export function _encodeURIComponent(str) {
	str = encodeURIComponent(str);
	str = str.replace('(', '%28');
	str = str.replace(')', '%29');
	str = str.replace('|', '%7C');
	return str;
}

export function dashedCase(str) {
	if (!str || typeof str != 'string') return str;
	return str.replace(/([A-Z])/g, function ($1) {
		return '-' + $1.toLowerCase();
	});
}

export function underCase(str) {
	if (!str || typeof str != 'string') return str;
	return str.replace(/([A-Z])/g, function ($1) {
		return '_' + $1.toLowerCase();
	});
}

export function camelCase(str) {
	if (!str || typeof str != 'string') return str;
	// eslint-disable-next-line no-useless-escape
	return str.replace(/(\-[a-z])/g, function ($1) {
		return $1.toUpperCase().replace('-', '');
	});
}

export function dateFromMongoId(id) {
	if (!id || typeof id != 'string' || !/[a-z0-9]{24}/.test(id.toLowerCase())) return null;
	try {
		return new Date(parseInt(id.substring(0, 8), 16) * 1000);
	} catch (e) {
		return null;
	}
}

export function slasher(str) {
	if (str.charAt(0) !== '/') {
		str = '/' + str;
	}
	if (str.charAt(str.length - 1) === '/') {
		str = str.substr(0, str.length - 1);
	}
	return str;
}

/**
 * Generate unique guid ID
 * @function uniq
 * @returns {string} uniq gui-ish ID
 */
export function uniq() {
	var uniq = Math.random() + '',
		ts = new Date().getTime();
	uniq = uniq.split('.');
	uniq = uniq[uniq.length - 1];
	uniq = uniq.substr(0, 5);
	return ts + uniq;
}

export function monthLabelFromMonthID(ID) {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	return months[ID];
}

/**
 * Returns a "clean" string for use in web-url-friendly tokens
 * <br />(essentially just a limited alphachar filter)
 * @function cleanString
 * @param {string} - (or ARRAY_OF_STRINGS)
 * @returns {string} - "clean" string
 */
export function cleanString(str, nodash) {
	nodash = nodash || false;

	if (str instanceof Array) {
		str.forEach(
			function (T, idx) {
				str[idx] = this.cleanString(T, nodash);
			}.bind(this)
		);

		return str;
	} else {
		// Remove spaces
		var clean = str.trim().replace(/\s/g, '_');

		if (nodash) {
			// Restrict to alphanumeric, underscore
			clean = clean.replace(/[^A-Za-z0-9_]/g, '');
		} else {
			// Restrict to alphanumeric, underscore, dash
			// eslint-disable-next-line no-useless-escape
			clean = clean.replace(/[^A-Za-z0-9_\-]/g, '');
		}

		return clean;
	}
}

/**
 * Returns a function for array-of-objects sort-by-prop
 * @function objectSort
 * @param {string} - name of sort property
 * @returns {function} - sort function
 */
export function objectSort(prop) {
	return function (a, b) {
		return a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0;
	};
}

/**
 * objectSort(prop, reverse, primer)
 *
 * @param string prop: what property to sort on
 * @param boolean reverse: return reverse sort
 * @param function primer: function to transform the data
 *
 * borrowed from: http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
 */
export function objectSortFull(prop, reverse, primer) {
	const key = primer ? x => primer(x[prop]) : x => x[prop];

	reverse = !reverse ? 1 : -1;

	return function (a, b) {
		a = key(a);
		b = key(b);
		return reverse * ((a > b) - (b > a));
	};
}

/**
 * moneyformat(amount, n, x, s, c)
 *
 * @param number  amount to format
 * @param integer n: length of decimal
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 * @param integer x: length of whole part
 *
 * borrowed from: http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
 */
export function moneyformat(amount, n, s, c, x) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = amount.toFixed(Math.max(0, ~~n));

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function moneyformatUsd(amount) {
	return moneyformat(amount, 2, ',', '.');
}

// export function moneyFormat(num) {
// 	if (typeof num !== 'string') {
// 		return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
// 	}
// 	if (num.indexOf('.') == -1) {
// 		return (num + '.00').replace(/\d(?=(\d{3})+\.)/g, '$&,');
// 	}
// 	return num.replace(/\d(?=(\d{3})+\.)/g, '$&,');
// }
