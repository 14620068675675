// import axios from 'axios';

import {
	// App
	QUEUE_DEFERRED_ACTION,

	// Jackpot UI
	JACKPOT_UI_HIDE_DETAIL_VIEW,
	JACKPOT_UI_SHOW_DETAIL_VIEW,
	JACKPOT_UI_SET_CURRENT_CARD,
	JACKPOT_UI_CLEAR_CURRENT_CARD
} from './actionTypes';

/**
 * App actions
 */

// export const queueDeferredAction = (branch, func) => ({
//     type: QUEUE_DEFERRED_ACTION,
//     payload: { branch, func }
// });

export const queueDeferredAction = (branch, func) => {
	console.warn('queueDeferredAction called and not handled yet, branch: ' + branch);
	return {
		type: QUEUE_DEFERRED_ACTION,
		payload: { branch, func }
	};
};

/**
 * Jackpot UI Actions
 */

export const clearJackpotUiCurrentCard = () => ({
	type: JACKPOT_UI_CLEAR_CURRENT_CARD,
	payload: {}
});

export const hideJackpotUiDetailView = () => ({
	type: JACKPOT_UI_HIDE_DETAIL_VIEW,
	payload: {}
});

export const setJackpotUiCurrentCard = card => ({
	type: JACKPOT_UI_SET_CURRENT_CARD,
	payload: { card }
});

export const showJackpotUiDetailView = () => ({
	type: JACKPOT_UI_SHOW_DETAIL_VIEW,
	payload: {}
});
