import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useLocations from '../../../hooks/useLocations';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const LocationsView = props => {
	const { data, error } = props;
	const { selectedLocationId, getLocation } = useLocations();

	console.log('LocationsView');
	console.log(selectedLocationId, data, error);

	useEffect(() => {
		if (!data && selectedLocationId) {
			console.log('getLocation!!!!' + selectedLocationId);
			getLocation(selectedLocationId);
		}
	}, [data, selectedLocationId, getLocation]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading location....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Location:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>City: </td>
						<td>{data.city}</td>
					</tr>
					<tr>
						<td>Country: </td>
						<td>{data.country}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default LocationsView;
