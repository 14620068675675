import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getBrands as _getBrands,
	setSelectedBrandId,
	loadBrand,
	addBrand,
	updateBrand,
	deleteBrand as _deleteBrand,
	brandSaveError,
	brandClearSaveResult,
	brandClearDeleteResult
} from '../redux/actions/brands';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';
import { PROFESSIONAL_ENTITY_TYPES } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useBrands = () => {
	const brands = useSelector(state => state.brands?.list, shallowEqual);
	const currentBrand = useSelector(state => state.brands?.currentBrand);
	const selectedBrandId = useSelector(state => state.brands?.selectedBrandId);
	const error = useSelector(state => state.brands?.loadError);
	const saveResult = useSelector(state => state.brands?.saveResult);
	const deleteResult = useSelector(state => state.brands?.deleteResult);
	const isProcessing = useSelector(state => state.brands?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.brand && data.car;
	};

	const getBrands = useCallback(() => {
		dispatch(_getBrands());
	}, [dispatch]);

	const getBrand = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedBrandId(id));
				dispatch(loadBrand({ id }));
			}
		},
		[dispatch]
	);

	const selectBrand = useCallback(
		id => {
			dispatch(setSelectedBrandId(id));
		},
		[dispatch]
	);

	const deleteBrand = useCallback(
		id => {
			dispatch(_deleteBrand(id));
		},
		[dispatch]
	);

	const saveBrand = useCallback(
		(id, data) => {
			if (validateData(data)) {
				data.type = PROFESSIONAL_ENTITY_TYPES.BRAND;
				if (id) {
					return dispatch(updateBrand(id, data));
				}
				return dispatch(addBrand(data));
			}
			dispatch(brandSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(brandClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(brandClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getBrands,
		getBrand,
		saveBrand,
		selectBrand,
		deleteBrand,
		isProcessing,
		currentBrand,
		selectedBrandId,
		brands,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useBrands;
