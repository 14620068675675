import _ from 'lodash';

import {
	CHARITIES_LOADED,
	CHARITIES_LOAD_ERROR,
	CHARITIES_REQUEST_END,
	CHARITIES_REQUEST_START,
	CHARITIES_ADD_LOCAL_RECORD,
	CHARITIES_UPDATE_LOCAL_RECORD,
	CHARITY_DELETED,
	CHARITY_DELETE_ERROR,
	CHARITY_DELETE_RESULT_CLEAR,
	CHARITIES_DROP_LOCAL_RECORD,
	CHARITY_SAVED,
	CHARITY_SAVE_ERROR,
	CHARITY_SAVE_RESULT_CLEAR,
	SET_SELECTED_CHARITY_ID
} from '../actions/actionTypes';

const initialState = {
	list: [],
	selectedCharityId: null,
	currentCharity: null,
	isRequesting: false,
	loadError: null,
	saveResult: {
		status: null,
		message: ''
	},
	deleteResult: {
		status: null,
		message: ''
	}
};

export default function (state = initialState, action) {
	const _state = { ...state };
	const { charities, error = null, id, data } = action.payload || {};
	switch (action.type) {
		case CHARITIES_LOADED:
			// console.warn(CHARITIES_LOADED);
			// console.log(JSON.stringify(action, null, 4));
			// const { charities } = action.payload;
			const currentCharities = [..._state.list];
			if (charities?.length) {
				charities.forEach(T => {
					if (!currentCharities.find(CT => CT._id === T._id)) {
						currentCharities.push(T);
					}
				});
			}
			// console.warn('currentCharities');
			// console.log(currentCharities);
			let currentCharity;
			if (state.selectedCharityId) {
				const selectedCharity = currentCharities.find(
					D => D._id === state.selectedCharityId
				);
				if (selectedCharity) {
					currentCharity = selectedCharity;
				}
			}
			return {
				...state,
				loadError: null,
				currentCharity,
				list: currentCharities
			};

		case CHARITIES_LOAD_ERROR:
			// const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(CHARITIES_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case SET_SELECTED_CHARITY_ID:
			return {
				...state,
				selectedCharityId: action.payload?.id,
				currentCharity:
					state.currentCharity?._id === action.payload?.id ? state.currentCharity : null
			};

		case CHARITIES_UPDATE_LOCAL_RECORD:
			const oldCharity = state.list.find(D => D._id === id) || {};
			const newCharity = _.merge(oldCharity, data);
			return {
				...state,
				currentCharity:
					state.currentCharity?._id === id ? newCharity : state.currentCharity,
				list: state.list.map(D => {
					if (D._id === id) {
						return newCharity;
					}
					return D;
				})
			};

		case CHARITIES_ADD_LOCAL_RECORD:
			return {
				...state,
				list: [...state.list, data]
			};

		case CHARITIES_DROP_LOCAL_RECORD:
			return {
				...state,
				currentCharity: state.currentCharity?._id === id ? null : state.currentCharity,
				list: state.list.filter(D => D._id !== id)
			};

		case CHARITIES_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case CHARITIES_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		case CHARITY_SAVED:
			return {
				...state,
				saveResult: {
					status: true,
					message: action.payload
				}
			};

		case CHARITY_SAVE_ERROR:
			return {
				...state,
				saveResult: {
					status: false,
					message: action.payload
				}
			};

		case CHARITY_SAVE_RESULT_CLEAR:
			return {
				...state,
				saveResult: {
					status: null,
					message: ''
				}
			};

		case CHARITY_DELETED:
			return {
				...state,
				deleteResult: {
					status: true,
					message: action.payload
				}
			};

		case CHARITY_DELETE_ERROR:
			return {
				...state,
				deleteResult: {
					status: false,
					message: action.payload
				}
			};
		case CHARITY_DELETE_RESULT_CLEAR:
			return {
				...state,
				deleteResult: {
					status: null,
					message: ''
				}
			};

		default:
			return state;
	}
}
