import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	ENTRIES_ADD_ERROR,
	ENTRIES_LOADED,
	ENTRIES_LOAD_ERROR,
	ENTRIES_REQUEST_END,
	ENTRIES_REQUEST_START,
	ENTRIES_UPDATE_ERROR,
	ENTRIES_ADD_LOCAL_RECORD,
	ENTRIES_UPDATE_LOCAL_RECORD,
	ENTRIES_DROP_LOCAL_RECORD,
	ENTRY_DELETED,
	ENTRY_DELETE_ERROR,
	ENTRY_DELETE_RESULT_CLEAR,
	ENTRY_SAVED,
	ENTRY_SAVE_ERROR,
	ENTRY_SAVE_RESULT_CLEAR,
	SET_SELECTED_ENTRY_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Entry Actions
 */

export const getEntries = ({ event, ids = [] }, options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().entries;
		if (state.isRequesting) {
			// console.log('getEntries: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getEntries(ids, options);
			};
			dispatch(queueDeferredAction('entries', deferred));
			return;
		}
		dispatch(entriesRequestStart());
		let response;
		let error;
		let url = '/api/list?oType=tournament-entries';
		if (event) {
			url += `&filter=${JSON.stringify({ event })}`;
		}
		try {
			response = await axios.get(url);
		} catch (err) {
			error = err;
		}

		dispatch(entriesRequestEnd());

		if (error) {
			return dispatch(entriesLoadError(error));
		}

		dispatch(entriesLoaded({ entries: response?.data }));
	};
};

export const loadEntry = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadEntry, no id!');
			return dispatch(entriesLoadError({ message: 'no id found to load entry with' }));
		}
		console.log('loadEntry loadEntry loadEntry loadEntry ');
		console.log(id);
		const state = getState().entries;
		if (state.isRequesting) {
			// console.log('getEntries: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadEntry(id);
			};
			dispatch(queueDeferredAction('entries', deferred));
			return;
		}
		dispatch(entriesRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=tournament-entries&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(entriesRequestEnd());

		if (error) {
			return dispatch(entriesLoadError(error));
		}

		console.log('loadEntry loadEntry loadEntry loadEntry ');
		console.log(response);

		dispatch(entriesLoaded({ entries: [response?.data] }));
	};
};

export const setSelectedEntryId = id => ({
	type: SET_SELECTED_ENTRY_ID,
	payload: { id }
});

export const addEntry = data => {
	return async (dispatch, getState) => {
		const state = getState().entries;
		if (state.isRequesting) {
			const deferred = () => {
				addEntry(data);
			};
			dispatch(queueDeferredAction('entries', deferred));
			return;
		}
		dispatch(entriesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=tournament-entries', { data });
		} catch (err) {
			error = err;
		}

		console.log('addEntry, response');
		console.log(response);

		dispatch(entriesRequestEnd());

		if (error) {
			dispatch(entrySaveError(responses.ENTRY_SAVE_ERROR));
			return dispatch(entriesAddError(error));
		}

		dispatch(entrySaved(responses.ENTRY_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addEntryLocalRecord(data));
		}
	};
};

export const updateEntry = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateEntry, no id!');
			return dispatch(
				entriesUpdateError({
					message: 'no id found to update entry with'
				})
			);
		}
		const state = getState().entries;
		if (state.isRequesting) {
			const deferred = () => {
				updateEntry(id, data);
			};
			dispatch(queueDeferredAction('entries', deferred));
			return;
		}
		dispatch(entriesRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=tournament-entries&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(entriesRequestEnd());

		console.log('updateEntry ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(entrySaveError(responses.ENTRY_SAVE_ERROR));
			return dispatch(entriesUpdateError(error));
		}

		dispatch(entrySaved(responses.ENTRY_SAVED));
		dispatch(updateEntryLocalRecord(id, data));
	};
};

export const deleteEntry = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteEntry, no id!');
			return false; // dispatch(entriesUpdateError({ message: 'no id found to update entry with' }));
		}
		const state = getState().entries;
		if (state.isRequesting) {
			const deferred = () => {
				deleteEntry(id);
			};
			dispatch(queueDeferredAction('entries', deferred));
			return;
		}
		dispatch(entriesRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=tournament-entries&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(entriesRequestEnd());

		console.log('deleteEntry ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(entryDeleteError(responses.ENTRY_DELETE_ERROR));
			// return dispatch(entriesUpdateError(error));
		}

		dispatch(entryDeleted(responses.ENTRY_DELETED));
		dispatch(dropEntryLocalRecord(id));
	};
};

export const addEntryLocalRecord = data => ({
	type: ENTRIES_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateEntryLocalRecord = (id, data) => ({
	type: ENTRIES_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const entriesLoaded = ({ entries }) => ({
	type: ENTRIES_LOADED,
	payload: { entries }
});

export const entrySaved = responseMsg => ({
	type: ENTRY_SAVED,
	payload: responseMsg
});

export const entrySaveError = responseMsg => ({
	type: ENTRY_SAVE_ERROR,
	payload: responseMsg
});

export const dropEntryLocalRecord = id => ({
	type: ENTRIES_DROP_LOCAL_RECORD,
	payload: { id }
});

export const entryDeleted = responseMsg => ({
	type: ENTRY_DELETED,
	payload: responseMsg
});

export const entryDeleteError = responseMsg => ({
	type: ENTRY_DELETE_ERROR,
	payload: responseMsg
});

export const entryClearSaveResult = () => ({
	type: ENTRY_SAVE_RESULT_CLEAR
});

export const entryClearDeleteResult = () => ({
	type: ENTRY_DELETE_RESULT_CLEAR
});

export const entriesLoadError = error => ({
	type: ENTRIES_LOAD_ERROR,
	payload: { error }
});

export const entriesAddError = error => ({
	type: ENTRIES_ADD_ERROR,
	payload: { error }
});

export const entriesUpdateError = error => ({
	type: ENTRIES_UPDATE_ERROR,
	payload: { error }
});

export const entriesRequestEnd = () => ({
	type: ENTRIES_REQUEST_END,
	payload: false
});

export const entriesRequestStart = () => ({
	type: ENTRIES_REQUEST_START,
	payload: true
});
