import {
	DONATION_HISTORY_LOADED,
	DONATION_HISTORY_LOAD_ERROR,
	DONATION_HISTORY_REQUEST_END,
	DONATION_HISTORY_REQUEST_START
} from '../actions/actionTypes';

const initialState = {
	list: [],
	isRequesting: false,
	loadError: null
};

export default function (state = initialState, action) {
	const _state = { ...state };
	switch (action.type) {
		case DONATION_HISTORY_LOADED:
			console.log('donation history reducer');
			console.log(action);
			const { donationHistory } = action.payload;
			const currentDonationHistory = [..._state.list];
			if (donationHistory?.length) {
				donationHistory.forEach(T => {
					if (!currentDonationHistory.find(CT => CT._id === T._id)) {
						currentDonationHistory.push(T);
					}
				});
			}
			console.log('currentDonationHistory');
			console.log(currentDonationHistory);
			return {
				...state,
				loadError: null,
				list: currentDonationHistory
			};

		case DONATION_HISTORY_LOAD_ERROR:
			const { error = null } = action.payload;
			if (!error) {
				// FIXME: should log this, error called with no error!
				console.error(DONATION_HISTORY_LOAD_ERROR + ', but no error object found');
			}
			return {
				...state,
				loadError: error
			};

		case DONATION_HISTORY_REQUEST_START:
			return {
				...state,
				isRequesting: true
			};

		case DONATION_HISTORY_REQUEST_END:
			return {
				...state,
				isRequesting: false
			};

		default:
			return state;
	}
}
