import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	LOCATIONS_ADD_ERROR,
	LOCATIONS_LOADED,
	LOCATIONS_LOAD_ERROR,
	LOCATIONS_REQUEST_END,
	LOCATIONS_REQUEST_START,
	LOCATIONS_UPDATE_ERROR,
	LOCATIONS_ADD_LOCAL_RECORD,
	LOCATIONS_UPDATE_LOCAL_RECORD,
	LOCATIONS_DROP_LOCAL_RECORD,
	LOCATION_DELETED,
	LOCATION_DELETE_ERROR,
	LOCATION_DELETE_RESULT_CLEAR,
	LOCATION_SAVED,
	LOCATION_SAVE_ERROR,
	LOCATION_SAVE_RESULT_CLEAR,
	SET_SELECTED_LOCATION_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Location Actions
 */

export const getLocations = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().locations;
		if (state.isRequesting) {
			// console.log('getLocations: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getLocations(ids, options);
			};
			dispatch(queueDeferredAction('locations', deferred));
			return;
		}
		dispatch(locationsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=locations');
		} catch (err) {
			error = err;
		}

		dispatch(locationsRequestEnd());

		if (error) {
			return dispatch(locationsLoadError(error));
		}

		dispatch(locationsLoaded({ locations: response?.data }));
	};
};

export const loadLocation = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadLocation, no id!');
			return dispatch(
				locationsLoadError({
					message: 'no id found to load location with'
				})
			);
		}
		console.log('loadLocation loadLocation loadLocation loadLocation ');
		console.log(id);
		const state = getState().locations;
		if (state.isRequesting) {
			// console.log('getLocations: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadLocation(id);
			};
			dispatch(queueDeferredAction('locations', deferred));
			return;
		}
		dispatch(locationsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=locations&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(locationsRequestEnd());

		if (error) {
			return dispatch(locationsLoadError(error));
		}

		console.log('loadLocation loadLocation loadLocation loadLocation ');
		console.log(response);

		dispatch(locationsLoaded({ locations: [response?.data] }));
	};
};

export const setSelectedLocationId = id => ({
	type: SET_SELECTED_LOCATION_ID,
	payload: { id }
});

export const addLocation = data => {
	return async (dispatch, getState) => {
		const state = getState().locations;
		if (state.isRequesting) {
			const deferred = () => {
				addLocation(data);
			};
			dispatch(queueDeferredAction('locations', deferred));
			return;
		}
		dispatch(locationsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=locations', { data });
		} catch (err) {
			error = err;
		}

		console.log('addLocation, response');
		console.log(response);

		dispatch(locationsRequestEnd());

		if (error) {
			dispatch(locationSaveError(responses.LOCATION_SAVE_ERROR));
			return dispatch(locationsAddError(error));
		}

		dispatch(locationSaved(responses.LOCATION_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addLocationLocalRecord(data));
		}
	};
};

export const updateLocation = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateLocation, no id!');
			return dispatch(
				locationsUpdateError({
					message: 'no id found to update location with'
				})
			);
		}
		const state = getState().locations;
		if (state.isRequesting) {
			const deferred = () => {
				updateLocation(id, data);
			};
			dispatch(queueDeferredAction('locations', deferred));
			return;
		}
		dispatch(locationsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=locations&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(locationsRequestEnd());

		console.log('updateLocation ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(locationSaveError(responses.LOCATION_SAVE_ERROR));
			return dispatch(locationsUpdateError(error));
		}

		dispatch(locationSaved(responses.LOCATION_SAVED));
		dispatch(updateLocationLocalRecord(id, data));
	};
};

export const deleteLocation = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteLocation, no id!');
			return false; // dispatch(locationsUpdateError({ message: 'no id found to update location with' }));
		}
		const state = getState().locations;
		if (state.isRequesting) {
			const deferred = () => {
				deleteLocation(id);
			};
			dispatch(queueDeferredAction('locations', deferred));
			return;
		}
		dispatch(locationsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=locations&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(locationsRequestEnd());

		console.log('deleteLocation ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(locationDeleteError(responses.LOCATION_DELETE_ERROR));
			// return dispatch(locationsUpdateError(error));
		}

		dispatch(locationDeleted(responses.LOCATION_DELETED));
		dispatch(dropLocationLocalRecord(id));
	};
};

export const addLocationLocalRecord = data => ({
	type: LOCATIONS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateLocationLocalRecord = (id, data) => ({
	type: LOCATIONS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const locationsLoaded = ({ locations }) => ({
	type: LOCATIONS_LOADED,
	payload: { locations }
});

export const locationSaved = responseMsg => ({
	type: LOCATION_SAVED,
	payload: responseMsg
});

export const locationSaveError = responseMsg => ({
	type: LOCATION_SAVE_ERROR,
	payload: responseMsg
});

export const dropLocationLocalRecord = id => ({
	type: LOCATIONS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const locationDeleted = responseMsg => ({
	type: LOCATION_DELETED,
	payload: responseMsg
});

export const locationDeleteError = responseMsg => ({
	type: LOCATION_DELETE_ERROR,
	payload: responseMsg
});

export const locationClearSaveResult = () => ({
	type: LOCATION_SAVE_RESULT_CLEAR
});

export const locationClearDeleteResult = () => ({
	type: LOCATION_DELETE_RESULT_CLEAR
});

export const locationsLoadError = error => ({
	type: LOCATIONS_LOAD_ERROR,
	payload: { error }
});

export const locationsAddError = error => ({
	type: LOCATIONS_ADD_ERROR,
	payload: { error }
});

export const locationsUpdateError = error => ({
	type: LOCATIONS_UPDATE_ERROR,
	payload: { error }
});

export const locationsRequestEnd = () => ({
	type: LOCATIONS_REQUEST_END,
	payload: false
});

export const locationsRequestStart = () => ({
	type: LOCATIONS_REQUEST_START,
	payload: true
});
