/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
// import '../Datasource.scss';

// import useCommsHub from '../../../hooks/useCommsHub';
// import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import SendEmail from './SendEmail';

const commsHubMap = {
	'send-email': SendEmail
};

const NoCommsHub = () => {
	return <h1>Choose a communication method</h1>;
};

const UnsupportedCommsHub = () => {
	return <h1>That link isn't ready yet, choose another</h1>;
};

const getCommsHubSource = oTool => {
	if (oTool) {
		if (commsHubMap[oTool]) {
			return commsHubMap[oTool];
		}
		return UnsupportedCommsHub;
	}
	return NoCommsHub;
};

const CommsHub = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oType, oTool, oMethod /* = 'list'*/, _id = null } = params;

	// const { getCommsHub, commsHub, currentCommsHub, selectedCommsHubId, selectCommsHub, error } = useCommsHub();
	// const [hasRequestedCommsHub, setHasRequestedCommsHub] = useState(false);
	// const [myCommsHubId, setMyCommsHubId] = useState(selectedCommsHubId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		// setMethod(cleanDataMethod(oMethod));
		setMethod(oMethod);
	}, [oMethod]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	const DisplayCommsHubComponent = getCommsHubSource(oTool);

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Communication Center {oType}
			</h1>
			<ActionButtons />
			<DisplayCommsHubComponent />
		</DatasourceContainer>
	);
};

export default CommsHub;
