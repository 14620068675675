import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useCars from '../../../hooks/useCars';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const CarsView = props => {
	const { data, error } = props;
	const { selectedCarId, getCar } = useCars();

	/*** this is temporary until useDrivers() is in play */
	const driver = { name: 'John Johnson' };

	console.log('CarsView');
	console.log(selectedCarId, data, error);

	useEffect(() => {
		if (!data && selectedCarId) {
			console.log('getCar!!!!' + selectedCarId);
			getCar(selectedCarId);
		}
	}, [data, selectedCarId, getCar]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Oops, not found!</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading car....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Car:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Car: </td>
						<td>{data.number}</td>
					</tr>
					<tr>
						<td>Driver: </td>
						<td>{driver?.name || 'none found'}</td>
					</tr>
					<tr>
						<td>Sponsors: </td>
						<td>{data.entities?.join(', ') || 'none'}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default CarsView;
