import moment from 'moment';

const DATASOURCE_DEFAULT_METHOD = 'list';

const cleanMethods = ['list', 'view', 'get', 'add', 'edit'];

export const cleanDataMethod = (
	method,
	extraMethods = [],
	defaultMethod = DATASOURCE_DEFAULT_METHOD
) => {
	if (cleanMethods.indexOf(method) > -1) {
		return method;
	}
	if (extraMethods.indexOf(method) > -1) {
		return method;
	}
	return defaultMethod;
};

export const showCityState = item => {
	if (item.city && item.state) {
		return `${item.city}, ${item.state}`;
	}
	if (item.city) {
		return item.city;
	}
	if (item.state) {
		return item.state;
	}
	return '';
};

export const showDate = date => {
	// const myDate = moment(new Date(date));
	const myDate = moment(date);
	if (myDate) {
		return myDate.format('MMMM DD, YYYY');
	}
	return 'invalid date';
};

export const getLocalImageId = (localData, prop = 'imageId') => {
	if (localData?.[prop]) {
		if (!localData?._deleteImage?.[prop]) {
			return localData?.[prop];
		}
	}
	return null;
};

export const getOnFieldUpdate = (setLocalData, localData) => {
	const onFieldUpdate = (e, extra) => {
		const myLocalData = {
			...localData
		};
		switch (e.target.type) {
			case 'checkbox':
				myLocalData[e.target.name] = e.target.checked;
				break;
			default:
				myLocalData[e.target.name] = e.target.value;
				break;
		}
		if (extra) {
			Object.keys(extra).map(K => {
				myLocalData[K] = extra[K];
			});
		}
		setLocalData(myLocalData);
	};
	return onFieldUpdate;
};

export const getUpdateImageData = (setLocalData, localData) => {
	const updateImageData = (imageData, prop = 'imageId', uploadProp = '_newImage') => {
		const myLocalData = {
			...localData
		};
		console.log('updateImageData');
		console.log(imageData);
		if (imageData) {
			myLocalData[uploadProp] = imageData;
			delete myLocalData._deleteImage?.[prop];
		} else {
			if (myLocalData[prop]) {
				myLocalData._deleteImage = myLocalData._deleteImage || {};
				myLocalData._deleteImage[prop] = true;
			}
		}
		setLocalData(myLocalData);
	};
	return updateImageData;
};
