/** @jsx jsx */
import { jsx } from 'theme-ui';
// import React from 'react';
import './Homepage.scss';
// import { useParams } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';
import AppMenu from './segments/AppMenu';
import { useParams } from 'react-router-dom';

import Admins from './datasources/Admins/Admins';
import Articles from './datasources/Articles/Articles';
import Brands from './datasources/Brands/Brands';
import Candidates from './datasources/Candidates/Candidates';
import Cars from './datasources/Cars/Cars';
import Charities from './datasources/Charities/Charities';
import DonationMatrixes from './datasources/DonationMatrixes/DonationMatrixes';
import Drivers from './datasources/Drivers/Drivers';
import JackpotResults from './datasources/JackpotResults/JackpotResults';
import Locations from './datasources/Locations/Locations';
import PrizeDraws from './datasources/PrizeDraws/PrizeDraws';
import RafflePrizes from './datasources/RafflePrizes/RafflePrizes';
import Races from './datasources/Races/Races';
import Sponsors from './datasources/Sponsors/Sponsors';
import VoteContests from './datasources/VoteContests/VoteContests';
import DressALikeEntries from './datasources/DressALikeEntries/DressALikeEntries';
import Users from './datasources/Users/Users';

import CommunicationCenter from './tools/CommunicationCenter/Hub';

// eslint-disable-next-line no-unused-vars
import FA from '../utils/FontAwesomeLoader';

const datasourceMap = {
	admins: Admins,
	articles: Articles,
	brands: Brands,
	candidates: Candidates,
	cars: Cars,
	comms: CommunicationCenter,
	charities: Charities,
	'donation-matrixes': DonationMatrixes,
	drivers: Drivers,
	'jackpot-results': JackpotResults,
	locations: Locations,
	'prize-draws': PrizeDraws,
	'raffle-prizes': RafflePrizes,
	races: Races,
	sponsors: Sponsors,
	'vote-contests': VoteContests,
	'dress-a-like-entries': DressALikeEntries,
	users: Users
};

const NoDatasource = props => {
	return <h1>Choose a datasource</h1>;
};

const UnsupportedDatasource = props => {
	return <h1>That link isn't ready yet, choose another</h1>;
};

const getDatasource = oType => {
	if (oType) {
		if (datasourceMap[oType]) {
			return datasourceMap[oType];
		}
		return UnsupportedDatasource;
	}
	return NoDatasource;
};

const Homepage = props => {
	const { oType } = useParams();
	const DisplayDatasource = getDatasource(oType);

	return (
		<div className='HomepageContent'>
			<Flex>
				<Box sx={{ width: '240px', pl: '0.5em' }}>
					<AppMenu />
				</Box>
				<Box p={2} sx={{ flex: '1', pl: '1em' }}>
					<DisplayDatasource />
				</Box>
			</Flex>
		</div>
	);
};

export default Homepage;
