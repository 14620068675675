import { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { resetStripeTokens } from '../redux/actions/payments';
import { login, logout, refresh } from '../redux/actions/user';

// this is out here for singleton "once per logged in session" behavior
let isRefreshedOnce = false;

const useUser = () => {
	const userAccount = useSelector(state => state.user?.user, shallowEqual);
	const isAuthorized = useSelector(state => state.user?.isAuthorized);
	const isProcessing = useSelector(state => state.user?.isRequesting);
	const isProcessingAction = useSelector(state => state.user?.isActionRequesting);
	const authError = useSelector(state => state.user?.authError);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isRefreshedOnce) {
			// setIsRefreshedOnce(true);
			isRefreshedOnce = true;
			dispatch(refresh());
		}
	}, [dispatch]);

	const loginUser = (email, password) => {
		isRefreshedOnce = false;
		dispatch(login(email, password));
	};

	const logoutUser = () => {
		isRefreshedOnce = false;
		dispatch(logout());
	};

	return {
		isProcessing,
		isProcessingAction,
		isAuthorized,
		login: loginUser,
		logout: logoutUser,
		userAccount,
		authError
	};
};

export default useUser;
